.bg-primary,
.bg-base-4 {
	.heading,
	.block-caption--over {
		color: #55595c !important;
	}
	.section-title-inner {
		color: #eee;
	}
	.section-title {
		.section-title-delimiter {
			&:before {
				background: #eee;
			}
		}
	}
}
#instafeed {
	text-align: center;
	img {
		margin-left: 5px;
		margin-right: 5px;
	}
}

// Hide ckeditor toolbar
.cke_top.cke_reset_all {
	display: none !important;
}

// Checkboxes misalignment
.form-horizontal .checkbox, .form-horizontal .radio {
	min-height: 18px;
}

.fc-day-number {
	font-weight: bold;
	color: #222222;
}

.fc-unthemed td.fc-today {
	background: #87ddae;
}