/*
Theme: Tribus - Multipurpose Template
Website URI: http://preview.webpixels.ro/tribus-v1.0.1/
Author: Webpixels
Author URI: http://www.webpixels.ro
*/
.dropdown .dropdown-menu .caret {
  border: 4px solid transparent;
  border-left-color: #000;
  float: right;
  margin-top: 6px; }

.dropdown-menu {
  min-width: 250px; }

.dropdown-menu > li.open > a {
  background-color: #F5F5F5;
  color: #262626;
  text-decoration: none; }

.dropdown-menu .dropdown-menu {
  left: 100%;
  margin: 0;
  right: auto;
  top: -1px; }

.dropdown-menu-right .dropdown-menu {
  left: auto !important;
  right: 100% !important; }

.dropdown-menu.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s; }

.dropdown-menu.animated:before {
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99; }

.dropdownhover-top {
  margin-bottom: 2px;
  margin-top: 0; }

.navbar-fixed-bottom .dropdown-menu .dropdown-menu,
.dropdownhover-top .dropdown-menu {
  bottom: -1px;
  top: auto; }

.navbar-nav > li > .dropdown-menu {
  margin-bottom: 0; }

.dropdownhover-bottom {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0; }

.dropdownhover-left {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%; }

.dropdownhover-right {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%; }

.dropdownhover-top {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%; }

.global-search {
  display: block;
  height: 90px;
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: -90px;
  z-index: 600;
  -webkit-transition: all 5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  opacity: 0; }

.header-affix.affix .global-search {
  display: none; }

.header-affix.affix #btnGlobalSearch {
  display: none; }

.global-search.in {
  margin-top: 0;
  opacity: 1; }

.global-search .form-global-search {
  height: 90px;
  position: relative;
  z-index: 900;
  background: #f1f1f1;
  border-bottom: 1px solid #efefef; }

.global-search .form-global-search .search-input {
  display: block;
  width: 100%;
  margin: 25px 0;
  height: 40px;
  padding: 0 50px 0 1rem;
  font-size: 22px;
  color: #333;
  border: 0;
  background-color: transparent;
  background-image: none; }

.global-search .form-global-search .search-input:focus {
  border-color: #333;
  outline: 0;
  font-weight: 600; }

.global-search .form-global-search .search-input::-moz-placeholder {
  color: #999;
  opacity: 1; }

.global-search .form-global-search .search-input::-moz-placeholder:focus {
  color: #999; }

.global-search .form-global-search .search-input:-ms-input-placeholder {
  color: #999; }

.global-search .form-global-search .search-input::-webkit-input-placeholder {
  color: #999; }

.global-search .form-global-search .search-input:focus::-webkit-input-placeholder {
  color: transparent; }

.global-search .form-global-search .btn {
  display: block;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  line-height: 40px;
  padding: 0; }

.global-search-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.98); }

.global-search.in .global-search-backdrop {
  display: block; }

.global-search-close-btn {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  right: 15px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #232323;
  background: transparent;
  border: 0;
  z-index: 900; }

.global-search--style-2 {
  height: 300px;
  margin-top: -300px;
  z-index: 800; }

@media (min-width: 992px) {
  .global-search--style-2 {
    height: 300px;
    margin-top: -300px; }

  .global-search--style-2 .form-global-search {
    height: 300px; }

  .global-search--style-2 .form-global-search .search-input {
    margin: 0;
    height: 60px;
    padding: 150px 50px 150px 1rem; } }
@media (max-width: 991px) {
  .global-search {
    height: 120px;
    margin-top: -120px; }

  .header-1--transparent .global-search {
    z-index: 1000; }

  .global-search .form-global-search {
    height: 120px; }

  .global-search .form-global-search .search-input {
    margin: 0;
    height: 120px;
    font-size: 20px;
    line-height: 120px; } }
.global-search-full-page {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  background: rgba(255, 255, 255, 0.95); }

.global-search-full-page.in {
  display: block;
  top: 0;
  left: 0; }

.global-search-full-page .form-global-search {
  width: 80%;
  margin-left: 10%;
  position: absolute;
  top: 50%;
  margin-top: -75px; }

.global-search-full-page .form-global-search .search-input {
  display: block;
  width: 100%;
  margin-top: 25px;
  height: 50px;
  padding: 0 0 3px;
  font-size: 28px;
  line-height: 1.42857143;
  color: #111;
  border: 0;
  border-bottom: 1px solid #111;
  background-color: transparent;
  background-image: none; }

.global-search-full-page .form-global-search .search-input:focus {
  border-color: #111;
  outline: 0; }

.global-search-full-page .form-global-search .search-input::-moz-placeholder {
  color: #111;
  opacity: 1; }

.global-search-full-page .form-global-search .search-input::-moz-placeholder:focus {
  color: #111; }

.global-search-full-page .form-global-search .search-input:-ms-input-placeholder {
  color: #111; }

.global-search-full-page .form-global-search .search-input::-webkit-input-placeholder {
  color: #111; }

.global-search-full-page .form-global-search .search-input:focus::-webkit-input-placeholder {
  color: transparent; }

.global-search-full-page .form-global-search .btn {
  height: 50px;
  margin-top: 25px;
  padding: 14px 36px;
  float: right;
  font-size: 18px;
  line-height: 0 !important; }

.global-search-full-page .close-search {
  padding: 20px;
  background-color: #111;
  float: right;
  cursor: pointer;
  border-bottom-left-radius: 2px; }

.global-search-full-page .close-search:before {
  content: "\f00d";
  font-family: FontAwesome;
  color: #fff; }

/* DROPDOWN CART */
.top-navbar {
  background: white;
  border-bottom: 1px solid #f1f1f1;
  position: relative;
  z-index: 710; }

.header-affix.affix .top-navbar {
  display: none; }

.top-navbar .aux-text {
  padding: 14px 0;
  color: rgba(0, 0, 0, 0.3);
  font-size: 11px;
  float: left; }

.top-navbar .aux-text ul li a:not(.btn),
.top-navbar .aux-text ul li a:not(.btn) span {
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif; }

.top-navbar .top-navbar-menu {
  float: right; }

.top-navbar .top-navbar-menu > ul.top-menu {
  list-style: none;
  margin: 0;
  padding: 0; }

.top-navbar .top-navbar-menu > ul.top-menu > li {
  position: relative;
  float: left;
  display: inline-block;
  border-right: 1px solid #f1f1f1; }

.top-navbar .top-navbar-menu > ul.top-menu > li:last-child {
  border: 0; }

.top-navbar .top-navbar-menu > ul.top-menu > li > a:not(.btn) {
  display: block;
  padding: 14px 15px;
  color: rgba(0, 0, 0, 0.7);
  text-transform: none;
  font-size: 0.875rem; }

.top-navbar .top-navbar-menu > ul.top-menu > li > a:not(.btn):hover {
  color: rgba(0, 0, 0, 0.8); }

.top-navbar .top-navbar-menu > ul.top-menu > li.dropdown > a:not(.btn):after {
  content: "\f107";
  margin-left: 6px;
  font-family: "FontAwesome";
  position: relative;
  float: right; }

.top-navbar .top-navbar-menu ul.top-menu > li > a > i {
  margin-right: 6px; }

.top-navbar .social-media.social-media--style-1-v4 > li > a {
  color: #818a91;
  font-size: 0.75rem; }

.top-navbar .top-navbar-menu > ul.top-menu-sm > li > a:not(.btn) {
  padding: 0.5rem 0.75rem; }

.top-navbar.top-navbar--caps .top-navbar-menu > ul.top-menu > li > a:not(.btn) {
  text-transform: uppercase; }

.top-navbar.top-navbar--caps .top-navbar-menu > ul.top-menu-sm > li > a:not(.btn) {
  font-size: 0.75rem; }

.top-navbar .top-navbar-menu > ul.top-menu--style-2 > li {
  border-right: 0; }

.top-navbar .top-navbar-menu > ul.top-menu--style-2 > li > a:not(.btn) {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.top-navbar .top-navbar-menu ul.top-menu > li ul.sub-menu {
  display: none;
  min-width: 160px;
  position: absolute;
  right: -1px;
  z-index: 1100;
  margin: 0;
  padding: 0;
  list-style: none;
  background: #FFF;
  border: 0;
  opacity: 0;
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  border-radius: 1px; }

.top-navbar .top-navbar-menu ul.top-menu > li:hover ul.sub-menu {
  opacity: 1;
  display: block; }

.top-navbar .top-navbar-menu ul.top-menu > li ul.sub-menu > li:last-child {
  border: 0; }

.top-navbar .top-navbar-menu ul.top-menu > li ul.sub-menu > li > a:not(.btn) {
  display: block;
  padding: 6px 15px;
  color: #333; }

.top-navbar .top-navbar-menu ul.top-menu > li ul.sub-menu > li > a:hover {
  background: transparent;
  color: #007aff; }

.top-navbar .top-navbar-menu ul.top-menu > li ul.sub-menu > li > .language-active {
  display: block;
  padding: 6px 15px;
  background: #007aff;
  color: #FFF;
  cursor: default; }

.top-navbar .top-navbar-menu ul.top-menu > li.dropdown:hover .sub-menu {
  display: block; }

@media (max-width: 991px) {
  .top-navbar .top-navbar-menu {
    float: none; }

  .top-navbar .top-navbar-menu > ul.top-menu {
    display: table;
    width: 100%; }

  .top-navbar .top-navbar-menu > ul.top-menu > li {
    float: none;
    display: table-cell; } }
.top-navbar--inverse {
  border-bottom: 1px solid black;
  background: black; }

.top-navbar--inverse .aux-text {
  color: rgba(255, 255, 255, 0.3); }

.top-navbar--inverse .top-navbar-menu > ul.top-menu > li {
  border-right: 1px solid transparent; }

.top-navbar--inverse .top-navbar-menu > ul.top-menu > li > a {
  color: rgba(255, 255, 255, 0.7); }

.top-navbar--inverse .top-navbar-menu > ul.top-menu > li > a:hover {
  color: #007aff; }

.header {
  position: relative; }

.header-1 .navbar-header {
  padding: 0; }

.header-1 .navbar-main {
  margin: 0;
  background-color: white;
  border: 0;
  border-radius: 0;
  z-index: 700; }

.header-1 .navbar-main > .container {
  position: relative; }

.header-1 .navbar-logo {
  display: inline-block; }

@media (min-width: 992px) {
  .header-1 .navbar-logo {
    margin-right: 30px; } }
.header-1 .navbar-main .navbar-text {
  color: rgba(0, 0, 0, 0.7); }

.header-1 .navbar-main .navbar-nav a {
  outline: 0; }

.header-1 .navbar-main .navbar-nav > li > a {
  font-size: 0.8rem;
  font-family: "Montserrat", sans-serif;
  padding: 34px 16px 34px 16px;
  margin: 0;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0;
  color: rgba(0, 0, 0, 0.7);
  background: transparent;
  border-radius: 0; }

.header-1 .navbar-main .navbar-nav > li:focus > a,
.header-1 .navbar-main .navbar-nav > li:hover > a {
  color: rgba(0, 0, 0, 0.8);
  background: transparent; }

.header-1 .navbar-main .navbar-nav > .active > a,
.header-1 .navbar-main .navbar-nav > .active:focus > a,
.header-1 .navbar-main .navbar-nav > .active:hover > a {
  color: rgba(0, 0, 0, 0.9);
  background: transparent; }

.header-1 .navbar--inverse .navbar-nav > li > a {
  color: rgba(255, 255, 255, 0.7);
  background: transparent; }

.header-1 .navbar--inverse .navbar-nav > li:focus > a,
.header-1 .navbar--inverse .navbar-nav > li:hover > a {
  color: rgba(255, 255, 255, 0.8);
  background: transparent; }

.header-1 .navbar--inverse .navbar-nav > .active > a,
.header-1 .navbar--inverse .navbar-nav > .active:focus > a,
.header-1 .navbar--inverse .navbar-nav > .active:hover > a {
  color: rgba(255, 255, 255, 0.9);
  background: transparent; }

.header-1--style-2 .navbar-main .navbar-nav > li > a {
  padding: 31px 16px 34px 16px;
  font-size: 1rem !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #2b2b2c;
  background: transparent;
  border-radius: 0; }

.header-1--style-2 .navbar-main .navbar-nav > li,
.header-1--style-2 .navbar-main .navbar-nav > li {
  border-top: 3px solid transparent; }

.header-1--style-2 .navbar-main .navbar-nav > li:focus,
.header-1--style-2 .navbar-main .navbar-nav > li:hover {
  border-top-color: #2b2b2c; }

.header-1--style-2 .navbar-main .navbar-nav > li:focus > a,
.header-1--style-2 .navbar-main .navbar-nav > li:hover > a {
  color: #2b2b2c;
  background: transparent; }

.header-1--style-2 .navbar-main .navbar-nav > .active > a,
.header-1--style-2 .navbar-main .navbar-nav > .active:focus > a,
.header-1--style-2 .navbar-main .navbar-nav > .active:hover > a {
  color: #2b2b2c;
  background: transparent; }

.header-1--style-2 .navbar--inverse .navbar-nav > li > a {
  color: #eceeef;
  background: transparent; }

.header-1--style-2 .navbar--inverse .navbar-nav > li:focus > a,
.header-1 .navbar--inverse .navbar-nav > li:hover > a {
  color: #eceeef;
  background: transparent; }

.header-1--style-2 .navbar--inverse .navbar-nav > .active > a,
.header-1--style-2 .navbar--inverse .navbar-nav > .active:focus > a,
.header-1--style-2 .navbar--inverse .navbar-nav > .active:hover > a {
  color: #eceeef;
  background: transparent; }

@media (max-width: 991px) {
  .header-1 .header-inner {
    display: none; }

  .header-inner-wrapper .navbar-main .navbar-logo {
    display: inline-block; } }
@media (min-width: 992px) {
  .header-inner-wrapper .navbar-main .navbar-logo {
    display: none; }

  .header-1 .navbar--sm .navbar-nav > li > a {
    padding-top: 22px;
    padding-bottom: 22px; }

  .header-1 .header-inner.header-inner--style-1 {
    background: white;
    position: relative;
    z-index: 700; }

  .header-1 .header-inner.header-inner--style-2 {
    background: black; }

  .header-1 .header-inner .logo {
    display: inline-block; }

  .header-1 .header-inner .logo-motto {
    clear: both;
    display: inline-block; }

  .header-1 .header-inner .header-col {
    position: relative;
    padding: 20px 35px;
    display: inline-block; }

  .header-1 .header-inner .header-col:last-child {
    padding-right: 0; }

  .header-1 .header-inner .header-col:after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -25px;
    border-right: 1px solid #eceeef;
    height: 50px;
    transform: skew(-15deg);
    transform-origin: 0 100%; }

  .header-1 .header-inner .header-col:last-child:after {
    border-color: transparent; } }
.header-1 .navbar-btn-lg {
  margin: 0; }

.header-1 .navbar-btn-lg .btn {
  margin: 0 !important;
  border-radius: 0 !important;
  background-color: #007aff !important;
  color: #FFF !important;
  padding: 22px 16px !important;
  font-size: 0.8rem;
  letter-spacing: 0;
  border: 0; }

.header-1 .navbar-btn-lg .btn:hover {
  top: 0;
  background-color: #0062cc !important; }

.header-1 .navbar-main .navbar-nav > li > a.dropdown-toggle {
  padding-right: 26px; }

.header-1 .navbar-main .navbar-nav > li > a > span > i {
  margin-right: 5px; }

.header-1 .navbar-main .navbar-nav > li:last-child > a > span > i {
  margin-right: 0; }

.header-1 .navbar-main .navbar-nav > li > a.dropdown-toggle > span:before {
  content: "\f123";
  font-family: "Ionicons";
  position: absolute;
  right: 16px;
  font-size: 8px; }

.header-1 .navbar-main .navbar-nav > li.dropdown-icon:hover,
.header-1 .navbar-main .navbar-nav > li.dropdown-icon:focus {
  border-top-color: transparent !important; }

.header-1 .navbar-main .navbar-nav > li > a > .badge {
  padding: 0;
  top: 2px;
  left: 2px;
  display: block;
  position: absolute;
  height: 18px;
  width: 18px;
  line-height: 19px;
  border-radius: 100%;
  font-weight: 600;
  text-align: center;
  font-style: normal;
  font-size: 11px; }

.header-1 .navbar-main .navbar-nav > li.dropdown-icon > a > .badge {
  right: 4px;
  left: auto;
  top: 20px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  opacity: 1;
  font-size: 10px;
  font-weight: 400; }

.header-1 .navbar-main .navbar-nav > li.dropdown-icon .icon {
  font-size: 18px; }

.header-1 .navbar-main .navbar-nav > li.dropdown-icon--style-2 > a {
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  padding: 0;
  margin: 20px 16px;
  background: transparent;
  color: #333;
  border-radius: 100%;
  border: 1px solid #f3f3f3; }

.header-1 .navbar-main .navbar-nav > li.dropdown-icon--style-2 > a:hover {
  background: transparent;
  color: #007aff; }

.header-1 .navbar--inverse .navbar-nav > li.dropdown-icon--style-2 > a {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.7); }

.header-1 .navbar--inverse .navbar-nav > li.dropdown-icon--style-2 > a:hover {
  background: transparent;
  color: #007aff; }

.header-1.affix .navbar-main .navbar-nav > li.dropdown-icon--style-2 > a {
  width: 38px;
  height: 38px;
  line-height: 38px;
  margin-top: 15px;
  margin-bottom: 15px; }

@media (min-width: 992px) {
  .navbar-nav.navbar-center {
    float: none;
    margin: 0 auto;
    display: table;
    table-layout: fixed; } }
.header-1 .navbar--inverse {
  background-color: black; }

.header-1 .navbar--shadow {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); }

.header-1 .navbar--bb-1px {
  border-bottom: 1px solid #f1f1f1 !important; }

.header-1 .navbar--bb-2px {
  border-bottom: 2px solid #f1f1f1 !important; }

.header-1 .navbar--bb-3px {
  border-bottom: 3px solid #f1f1f1 !important; }

.header-1 .navbar--inverse.navbar--bb-1px {
  border-bottom: 1px solid black !important; }

.header-1 .navbar--inverse.navbar--bb-2px {
  border-bottom: 2px solid black !important; }

.header-1 .navbar--inverse.navbar--bb-3px {
  border-bottom: 3px solid black !important; }

.header-1 .navbar--bt-1px {
  border-top: 1px solid #f1f1f1 !important; }

.header-1 .navbar--bt-2px {
  border-top: 2px solid #f1f1f1 !important; }

.header-1 .navbar--bt-3px {
  border-top: 3px solid #f1f1f1 !important; }

.header-1 .navbar--inverse.navbar--bt-1px {
  border-top: 1px solid black !important; }

.header-1 .navbar--inverse.navbar--bt-2px {
  border-top: 2px solid black !important; }

.header-1 .navbar--inverse.navbar--bt-3px {
  border-top: 3px solid black !important; }

.header-1 .navbar--caps .navbar-nav > li > a {
  text-transform: uppercase; }

.header-1 .navbar--bold .navbar-nav > li > a {
  font-weight: bolder !important; }

.header-1 .navbar-main .dropdown-menu {
  min-width: 220px;
  padding: 0;
  margin: 0;
  text-align: left;
  background-color: #FFF;
  border: 0;
  border-top: 0;
  border-radius: 1px; }

.header-1 .navbar-main .dropdown-menu > li {
  border-bottom: 1px solid #f1f1f1; }

.header-1 .navbar-main .dropdown-menu > li:last-child {
  border: 0; }

.header-1 .navbar-main .dropdown-menu > li.dropdown-header {
  padding: 10px 20px;
  font-weight: 600;
  font-size: 0.75rem;
  color: #818a91;
  font-family: "Montserrat", sans-serif; }

.header-1 .navbar-main .dropdown-menu > li > a {
  padding: 14px 20px;
  font-size: 0.8rem;
  font-weight: 400;
  text-transform: capitalize;
  color: #333; }

.header-1 .navbar--caps .dropdown-menu > li > a {
  font-size: 0.7rem;
  text-transform: uppercase !important; }

.header-1 .navbar--strong .navbar-nav > li > a {
  font-weight: 600 !important; }

.header-1 .navbar-main .dropdown-menu > li > a:focus,
.header-1 .navbar-main .dropdown-menu > li > a:hover {
  color: #007aff;
  background-color: transparent; }

.header-1 .navbar-main .dropdown-menu .divider {
  background-color: rgba(0, 0, 0, 0.5); }

.header-1 .navbar-main .dropdown-menu li.open > a {
  color: #007aff;
  background-color: transparent; }

.header-1 .navbar-dropdown--inverse .dropdown-menu {
  background-color: #111111;
  border-top: 0; }

.header-1 .navbar-dropdown--inverse .dropdown-menu:after {
  border-bottom-color: #111111 !important; }

.header-1 .navbar-dropdown--inverse .dropdown-menu > li {
  border-bottom-color: rgba(30, 30, 30, 0.7); }

.header-1 .navbar-main.navbar-dropdown--inverse .dropdown-menu > li > a {
  color: rgba(255, 255, 255, 0.7); }

.header-1 .navbar-dropdown--inverse .dropdown-menu > li > a:focus,
.header-1 .navbar-dropdown--inverse .dropdown-menu > li > a:hover {
  color: #007aff;
  background-color: transparent; }

.header-1 .navbar-dropdown--inverse .dropdown-menu li.open > a {
  color: #007aff;
  background-color: transparent; }

@media (max-width: 991px) {
  .header-1 .navbar-main .dropdown-menu {
    box-shadow: none; }

  .header-1 .navbar-main .dropdown-menu:after {
    border: 0 !important;
    margin-left: 0; }

  .header-1 .navbar-main .dropdown-menu:before {
    border: 0 !important;
    margin-left: 0; }

  .header-1 .navbar-main .navbar-nav .open .dropdown-menu > li {
    border-bottom: 1px solid #f1f1f1; }

  .header-1 .navbar-main .navbar-nav .open .dropdown-menu > li:last-child {
    border-bottom: 0; }

  .header-1 .navbar-main .navbar-nav .open .dropdown-menu > li > a {
    display: block;
    width: 100%;
    padding: 12px 30px;
    margin: 0; }

  .header-1 .navbar-main .navbar-nav .open .dropdown-menu > li > a:focus,
  .header-1 .navbar-main .navbar-nav .open .dropdown-menu > li > a:hover {
    background-color: transparent; }

  .header-1 .navbar-dropdown--inverse .navbar-nav .open .dropdown-menu > li {
    border-bottom: 1px solid rgba(30, 30, 30, 0.7); } }
.dropdown-submenu {
  position: relative; }

.dropdown-submenu > .dropdown-menu {
  top: 0 !important;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-submenu > a:before {
  content: "\f105";
  position: absolute;
  right: 15px;
  font-family: 'FontAwesome';
  font-size: 12px; }

.dropdown-submenu.open > .dropdown-menu {
  display: block;
  padding: 0; }

.dropdown-submenu.pull-left {
  float: none; }

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px; }

@media (max-width: 991px) {
  .header-1 .navbar-main .navbar-nav .open .dropdown-submenu .dropdown-menu {
    border-top: 1px solid #f1f1f1; }

  .header-1 .navbar-nav .open .dropdown-submenu .dropdown-menu > li > a {
    padding-left: 50px;
    padding-right: 15px; }

  .header-1 .navbar-nav .open .dropdown-submenu .dropdown-menu > li > a:before {
    content: "\f178";
    font-family: "FontAwesome";
    position: relative;
    left: -6px; }

  .header-1 .navbar-nav .open .dropdown-submenu > a {
    background: white; }

  .header-1 .navbar-nav .open .dropdown-submenu > a:focus,
  .header-1 .navbar-nav .open .dropdown-submenu > a:hover {
    background: transparent !important; }

  .dropdown-submenu > a:focus,
  .dropdown-submenu > a:hover {
    color: #007aff;
    background-color: transparent; }

  .dropdown-submenu > a:before {
    right: 20px;
    font-size: 12px;
    line-height: 1.8; }

  .dropdown-submenu.open > a:before {
    content: "\f107"; }

  .dropdown-submenu.open > a:after {
    border-left: 0 !important; }

  .header-1 .navbar-dropdown--inverse .navbar-nav .open .dropdown-submenu > a {
    background: #131313; }

  .header-1 .navbar-dropdown--inverse .navbar-nav .open .dropdown-submenu .dropdown-menu {
    border-top: 1px solid rgba(30, 30, 30, 0.7); } }
@media (min-width: 992px) {
  .header-1 .dropdown-submenu .dropdown-menu {
    border-left: 2px solid transparent; }

  .header-1 .dropdown-submenu .dropdown-menu.dropdownhover-left {
    border-left: 0;
    border-right: 2px solid transparent; } }
@media (min-width: 992px) {
  .header-1 .navbar-main .nav > li > .dropdown-menu {
    border-top: 2px solid transparent; }

  .header-1 .navbar-dropdown--arrow .nav > li > .dropdown-menu {
    border-top: 10px solid transparent; } }
.header-1 .navbar-dropdown--arrow .nav > li > .dropdown-menu:after {
  bottom: 100%;
  left: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(221, 221, 221, 0);
  border-bottom-color: #FFF;
  border-width: 8px;
  margin-left: -8px; }

.header-1 .navbar-dropdown--arrow .nav > li > .dropdown-menu.navbar-right:after,
.header-1 .navbar-dropdown--arrow .nav.navbar-right > li > .dropdown-menu:after {
  right: 10px;
  left: auto; }

.header-1 .navbar-dropdown--arrow .nav > li > .dropdown-menu.mega-dropdown-menu:after {
  border: 0; }

.dropdown-menu-search {
  padding: 20px !important;
  min-width: 420px !important;
  position: absolute; }

.dropdown-form {
  padding: 0;
  min-width: 340px !important; }

.dropdown-form-inner {
  padding: 1.5rem !important; }

.header-1 .mega-dropdown {
  position: static !important; }

.header-1 .mega-dropdown-menu {
  padding: 2rem 1rem !important;
  width: 100%; }

.header-1 .navbar-main .mega-dropdown-menu > li {
  border: 0; }

.header-1 .mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0; }

.header-1 .mega-dropdown-menu > li > ul > li {
  list-style: none; }

.header-1 .mega-dropdown-menu > li > ul > li > a {
  display: block;
  padding: 6px 0;
  clear: both;
  line-height: 1.42857143;
  color: #333;
  font-size: 0.8rem;
  text-transform: capitalize;
  font-weight: 400; }

.header-1 .navbar--caps .mega-dropdown-menu > li > ul > li > a {
  font-size: 0.7rem;
  text-transform: uppercase; }

.header-1 .mega-dropdown-menu > li > ul > li > a:hover {
  color: #007aff; }

.header-1 .mega-dropdown-menu .dropdown-header {
  padding: 14px 0; }

.header-1 .mega-dropdown-menu .meganav-section-title {
  margin: 0;
  padding: 0;
  color: #2b2b2c;
  font-size: 0.875rem;
  font-weight: bolder;
  text-transform: none; }

.header-1 .mega-dropdown-menu .meganav-section-title.text-uppercase {
  font-size: 0.75rem; }

.header-1 .mega-dropdown-menu .meganav-section-title > a {
  color: #333; }

.header-1 .mega-dropdown-menu .meganav-section-title > a:hover {
  color: #007aff; }

.header-1 .navbar-dropdown--inverse .mega-dropdown-menu > li > ul > li > a {
  color: rgba(255, 255, 255, 0.7); }

.header-1 .navbar-dropdown--inverse .mega-dropdown-menu > li > ul > li > a:hover {
  color: #007aff; }

.header-1 .navbar-dropdown--inverse .mega-dropdown-menu .meganav-section-title {
  color: rgba(255, 255, 255, 0.7) !important; }

.header-1 .navbar-dropdown--inverse .mega-dropdown-menu .meganav-section-title > a {
  color: rgba(255, 255, 255, 0.7); }

.header-1 .navbar-dropdown--inverse .mega-dropdown-menu .meganav-section-title > a:hover {
  color: #007aff; }

@media (min-width: 992px) {
  .header-1 .navbar-main .mega-dropdown-menu > .mega-dropdown-col {
    border-right: 1px solid #f1f1f1;
    padding: 0; }

  .header-1 .navbar-main .mega-dropdown-menu > li > ul {
    padding-left: 2rem;
    padding-right: 2rem; }

  .header-1 .mega-dropdown-menu .mega-dropdown-col-icon > a {
    position: relative;
    display: block;
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(30, 30, 30, 0.7); }

  .header-1 .mega-dropdown-menu .mega-dropdown-col-icon > a:hover {
    color: #007aff; }

  .header-1 .mega-dropdown-menu .mega-dropdown-col-icon > a > i {
    font-size: 90px; }

  .header-1 .navbar-dropdown--inverse .mega-dropdown-menu .mega-dropdown-col {
    border-right: 1px solid rgba(30, 30, 30, 0.7); }

  .header-1 .mega-dropdown-menu .mega-dropdown-col:last-child > ul {
    border-right: 0; } }
@media (max-width: 991px) {
  .header-1 .mega-dropdown-menu {
    padding: 0 !important; }

  .header-1 .mega-dropdown-menu .dropdown-header {
    padding: 14px 30px !important; }

  .header-1 .mega-dropdown-menu .mega-dropdown-col-icon {
    display: none; }

  .header-1 .mega-dropdown-menu > li {
    padding: 0; }

  .header-1 .mega-dropdown-menu > li > ul > li {
    border-bottom: 1px solid #f1f1f1; }

  .header-1 .mega-dropdown-menu > li > ul > li > a {
    padding: 14px 30px;
    padding-left: 50px !important; }

  .header-1 .mega-dropdown-menu > li > ul > li > a:before {
    content: "\f178";
    font-family: "FontAwesome";
    position: relative;
    left: -6px; }

  .header-1 .navbar-dropdown--inverse .mega-dropdown-menu > li > ul > li {
    border-bottom: 1px solid rgba(30, 30, 30, 0.7); } }
.header-1--opaque {
  position: absolute;
  top: 0;
  width: 100%; }

.header-1--opaque .navbar-main {
  background-color: rgba(255, 255, 255, 0.5);
  border: 0; }

.header-1--opaque .navbar--inverse {
  background-color: rgba(0, 0, 0, 0.5); }

.header-1--opaque.affix .navbar-main {
  background-color: rgba(255, 255, 255, 0.7); }

.header-1--opaque.affix .navbar--inverse {
  background-color: rgba(0, 0, 0, 0.7); }

.header-1--opaque .navbar-nav > li > a {
  color: rgba(0, 0, 0, 0.7);
  background: transparent; }

.header-1--opaque .navbar-nav > .active > a:before,
.header-1--opaque .navbar-nav > li > a:focus:before,
.header-1--opaque .navbar-nav > li > a:hover:before,
.header-1--opaque .navbar-nav > li.open > a:before,
.header-1--opaque .navbar-nav > li.open > a:before {
  background-color: transparent; }

.header-1--opaque .navbar-main .navbar-nav > li.dropdown-icon.style-2 > a {
  background: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #333 !important;
  border-radius: 100% !important; }

.header-1--opaque .navbar--inverse .navbar-nav > li.dropdown-icon.style-2 > a {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 100% !important; }

.header-1--opaque .navbar-main .navbar-nav > li.dropdown-icon.style-2 > a:hover,
.header-1--opaque .navbar-main .navbar-nav > li.dropdown-icon.style-2.open > a {
  border-color: #007aff;
  color: #007aff !important; }

.header-1--opaque .navbar--inverse .navbar-nav > li.dropdown-icon.style-2 > a:hover,
.header-1--opaque .navbar--inverse .navbar-nav > li.dropdown-icon.style-2.open > a {
  border-color: #007aff;
  color: #007aff !important; }

@media (min-width: 992px) {
  .header-1--transparent {
    position: absolute;
    top: 0;
    width: 100%; }

  .header-1--transparent .navbar--inverse,
  .header-1--transparent .navbar-main {
    background: transparent;
    border: 0;
    box-shadow: none;
    z-index: 500; }

  .header-1--transparent .navbar-main .navbar-nav > li > a {
    color: rgba(0, 0, 0, 0.9); }

  .header-1--transparent.header-1--transparent-bb .navbar-main {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

  .header-1--transparent.header-1--transparent-bb .navbar--inverse {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25); }

  .header-1--transparent.affix .navbar-main {
    background-color: rgba(255, 255, 255, 0.7);
    border: 0; }

  .header-1--transparent.affix .navbar--inverse {
    background-color: rgba(0, 0, 0, 0.7); }

  .header-1--transparent .navbar--inverse .navbar-nav > li > a {
    color: rgba(255, 255, 255, 0.9); }

  .header-1--transparent .navbar--inverse .navbar-nav > li > a:focus,
  .header-1--transparent .navbar--inverse .navbar-nav > li > a:hover {
    color: #007aff; }

  .header-1--transparent .navbar--inverse .navbar-nav > .active > a,
  .header-1--transparent .navbar--inverse .navbar-nav > .active > a:focus,
  .header-1--transparent .navbar--inverse .navbar-nav > .active > a:hover {
    color: #007aff; } }
.header-1.affix {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; }

.header-1.affix .navbar-nav > li > a {
  margin: 10px 0;
  padding-top: 14px;
  padding-bottom: 14px; }

.header-1.affix .navbar-logo img {
  height: 68px; }

@media (max-width: 991px) {
  .header-1.navbar-main .navbar-logo {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0; } }
.header-1.affix .navbar-search {
  width: 60px;
  height: 60px; }

.header-1.affix .navbar-search a.navbar-search-icon:before {
  line-height: 60px; }

@media (max-width: 991px) {
  .header-1 .navbar-header {
    float: none; }

  .header-1 .navbar-nav {
    margin: 0 -15px; }

  .header-1 .navbar-toggle {
    display: block; }

  .header-1 .navbar-main .navbar-nav > li > a {
    padding: 14px 20px !important;
    margin-right: 0; }

  .header-1 .navbar-main .navbar-nav > li > a:focus,
  .header-1 .navbar-main .navbar-nav > li > a:hover {
    border-radius: 0 !important; }

  .header-1 .navbar-main .navbar-nav > .active > a,
  .header-1 .navbar-main .navbar-nav > .active > a:focus,
  .header-1 .navbar-main .navbar-nav > .active > a:hover {
    border-radius: 0 !important; }

  .header-1 .navbar-main .navbar-nav > li.dropdown > a {
    border-bottom: 0; }

  .header-1 .navbar-header {
    padding: 0 15px; }

  .header-1 .navbar-main .navbar-toggle {
    position: relative;
    float: left;
    padding: 16px;
    margin: 20px 15px 20px 0;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0; }

  .header-1 .navbar-main .navbar-toggle:focus,
  .header-1 .navbar-main .navbar-toggle:hover {
    background-color: transparent;
    border-color: transparent; }

  .header-1 .navbar-main .navbar-toggle .icon-bar {
    background-color: rgba(0, 0, 0, 0.5); }

  .header-1 .navbar-main .navbar-toggle:focus .icon-bar,
  .header-1 .navbar-main .navbar-toggle:hover .icon-bar {
    background-color: rgba(0, 0, 0, 0.7); }

  .navbar-icon-btn {
    position: relative;
    float: right;
    padding: 16px 12px;
    margin: 15px 0;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0;
    outline: 0; }

  .navbar-icon-btn i {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px; }

  .navbar-icon-btn:hover i {
    color: rgba(0, 0, 0, 0.7); }

  .header-1 .navbar--inverse .navbar-toggle {
    background-color: transparent; }

  .header-1 .navbar--inverse .navbar-toggle:focus,
  .header-1 .navbar--inverse .navbar-toggle:hover {
    background-color: transparent;
    border-color: transparent; }

  .header-1 .navbar--inverse .navbar-toggle .icon-bar {
    background-color: rgba(255, 255, 255, 0.5); }

  .header-1 .navbar--inverse .navbar-toggle:focus .icon-bar,
  .header-1 .navbar--inverse .navbar-toggle:hover .icon-bar {
    background-color: rgba(255, 255, 255, 0.7); }

  .header-1 .navbar--inverse .navbar-icon-btn {
    background-color: transparent;
    border: 1px solid transparent; }

  .header-1 .navbar--inverse .navbar-icon-btn i {
    color: rgba(255, 255, 255, 0.5); }

  .header-1 .navbar--inverse .navbar-icon-btn:hover i {
    color: rgba(255, 255, 255, 0.7); } }
@media (min-width: 992px) {
  .navbar-icon-btn {
    display: none; } }
/* FIXES */
.body-boxed .header-1 .navbar-main .navbar-right {
  margin-right: 60px; }

/* SLIDEBAR BUTTONS */
@media (min-width: 992px) and (max-width: 1320px) {
  .btn-st-trigger {
    margin-right: 45px !important; } }
html {
  box-sizing: border-box; }

@-ms-viewport {
  width: device-width; }
html {
  font-size: 16px;
  -ms-overflow-style: scrollbar; }

body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #2b2b2c;
  background-color: #fff; }

iframe {
  border: 0; }

.slice {
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative; }

.slice-xs {
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative; }

.slice-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative; }

.slice-lg {
  padding-top: 6rem;
  padding-bottom: 6rem;
  position: relative; }

.slice-xl {
  padding-top: 8rem;
  padding-bottom: 8rem;
  position: relative; }

.slice.slice--arrow:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 30px;
  margin-left: -30px;
  z-index: 600; }

.slice.slice--arrow.slice--arrow-white:before {
  border-top-color: #fff; }

.slice.slice--arrow.slice--arrow.bg-base-1:before {
  border-top-color: #007aff; }

.slice.slice--arrow.slice--arrow.bg-base-2:before {
  border-top-color: #007aff; }

.slice.slice--arrow.slice--arrow.bg-base-3:before {
  border-top-color: #818a91; }

.slice.slice--arrow.slice--arrow.bg-base-4:before {
  border-top-color: #1d2125; }

.slice.slice--arrow.slice--arrow.bg-base-5:before {
  border-top-color: #FFF; }

@media (min-width: 768px) {
  .container-sm {
    width: 750px; } }
@media (min-width: 992px) {
  .container-sm {
    width: 870px; } }
@media (min-width: 1200px) {
  .container-sm {
    width: 900px; } }
.row-no-padding {
  margin: 0; }

.row-no-padding > [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

/* NO SPACE RESPONSIVE COLUMNS */
.no-space-container:after,
.no-space-container:before {
  display: table;
  content: ""; }

.no-space-container:after {
  clear: both; }

.no-space-container .item {
  display: inline-block;
  float: left;
  position: relative; }

.no-space-container .item > .item-body {
  padding: 40px; }

.no-space-container .item .block {
  width: 100%;
  margin-bottom: 0; }

.no-space-container--colored-1 .item {
  background: #eceeef; }

.no-space-container--colored-1 .item:nth-child(even) {
  background: #dee2e3; }

.no-space-container--colored-2 .item {
  background: #007aff;
  color: #FFF; }

.no-space-container--colored-2 .item:nth-child(even) {
  background: #1a87ff; }

.no-space-container.col-2 .item {
  width: 50%; }

.no-space-container.col-3 .item {
  width: 33.33%; }

.no-space-container.col-4 .item {
  width: 25%; }

.no-space-container.col-5 .item {
  width: 20%; }

@media (max-width: 991px) {
  /* Make item height auto for small resolutions (when this is set manually directly on item - ex: about-me-2.html) */
  .no-space-container .item {
    height: auto !important; } }
@media (max-width: 767px) {
  .no-space-container .item {
    display: block;
    float: none;
    position: relative;
    border: 0 !important; }

  .no-space-container.col-2 .item,
  .no-space-container.col-3 .item,
  .no-space-container.col-4 .item,
  .no-space-container.col-5 .item {
    width: 100%; } }
@media (min-width: 768px) and (max-width: 991px) {
  .no-space-container.col-2 .item,
  .no-space-container.col-3 .item,
  .no-space-container.col-4 .item,
  .no-space-container.col-5 .item {
    width: 100%; } }
@media (max-width: 991px) {
  .no-space-container .item.block-bordered-grid {
    border-bottom: 1px solid #f1f1f1 !important; }

  .no-space-container .item.block-bordered-grid:last-child {
    border-bottom: 0 !important;
    border-top: 0 !important; } }
@media (min-width: 992px) {
  .container-over-top {
    min-height: 200px;
    max-width: 1200px;
    margin: -140px auto 0;
    z-index: 100;
    position: relative;
    padding-top: 0; }

  .container-over-top .container-inner {
    border-radius: 0.25rem 0.25rem 0 0; }

  .container-over-top--style-2 {
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    border-bottom: medium double #ccc; } }
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.col {
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.flex-wrap-item {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex-grow: 1; }

.flex-items-xs-top {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start; }

.flex-items-xs-middle {
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.flex-items-xs-bottom {
  -webkit-box-align: end;
  -moz-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end; }

.flex-items-xs-center {
  justify-content: center; }

.flex-items-xs-left {
  justify-content: flex-start; }

.flex-items-xs-right {
  justify-content: flex-end; }

.col.col-xs-12 {
  max-width: 100%; }

.col.col-xs-11 {
  max-width: 91.66666667%; }

.col.col-xs-10 {
  max-width: 83.33333333%; }

.col.col-xs-9 {
  max-width: 75%; }

.col.col-xs-8 {
  max-width: 66.66666667%; }

.col.col-xs-7 {
  max-width: 58.33333333%; }

.col.col-xs-6 {
  max-width: 50%; }

.col.col-xs-5 {
  max-width: 41.66666667%; }

.col.col-xs-4 {
  max-width: 33.33333333%; }

.col.col-xs-3 {
  max-width: 25%; }

.col.col-xs-2 {
  max-width: 16.66666667%; }

.col.col-xs-1 {
  max-width: 8.33333333%; }

@media (max-width: 767px) {
  .col.col-xs-12 {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; } }
@media (min-width: 768px) {
  .col.col-sm-12 {
    max-width: 100%; }

  .col.col-sm-11 {
    max-width: 91.66666667%; }

  .col.col-sm-10 {
    max-width: 83.33333333%; }

  .col.col-sm-9 {
    max-width: 75%; }

  .col.col-sm-8 {
    max-width: 66.66666667%; }

  .col.col-sm-7 {
    max-width: 58.33333333%; }

  .col.col-sm-6 {
    max-width: 50%; }

  .col.col-sm-5 {
    max-width: 41.66666667%; }

  .col.col-sm-4 {
    max-width: 33.33333333%; }

  .col.col-sm-3 {
    max-width: 25%; }

  .col.col-sm-2 {
    max-width: 16.66666667%; }

  .col.col-sm-1 {
    max-width: 8.33333333%; } }
@media (min-width: 992px) {
  .col.col-md-12 {
    max-width: 100%; }

  .col.col-md-11 {
    max-width: 91.66666667%; }

  .col.col-md-10 {
    max-width: 83.33333333%; }

  .col.col-md-9 {
    max-width: 75%; }

  .col.col-md-8 {
    max-width: 66.66666667%; }

  .col.col-md-7 {
    max-width: 58.33333333%; }

  .col.col-md-6 {
    max-width: 50%; }

  .col.col-md-5 {
    max-width: 41.66666667%; }

  .col.col-md-4 {
    max-width: 33.33333333%; }

  .col.col-md-3 {
    max-width: 25%; }

  .col.col-md-2 {
    max-width: 16.66666667%; }

  .col.col-md-1 {
    max-width: 8.33333333%; } }
@media (min-width: 768px) and (orientation: portrait) {
  .col.col-sm-12 {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    max-width: 100%; } }
@media (min-width: 992px) and (orientation: portrait) {
  .col.col-md-12 {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    max-width: 100%; } }
.cbp-filters {
  text-align: left;
  color: #2b2b2c;
  padding: 0; }

.cbp-filters .cbp-filter-item {
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  padding: 0 12px;
  transition: all .3s ease-in-out;
  position: relative;
  overflow: visible;
  margin: 0;
  display: inline-block; }

.cbp-filters-left > .cbp-filter-item:first-child {
  padding-left: 0; }

.cbp-filters--style-1 .cbp-filter-item {
  color: #2b2b2c; }

.cbp-filters--style-1 .cbp-filter-item:hover {
  color: #818a91; }

.cbp-filters--style-1 .cbp-filter-item-active {
  color: #007aff; }

.cbp-filters--style-2 .cbp-filter-item {
  color: #eceeef; }

.cbp-filters--style-2 .cbp-filter-item:hover {
  color: #818a91; }

.cbp-filters--style-2 .cbp-filter-item-active {
  color: #007aff; }

@media (max-width: 991px) {
  .cbp-filters--sm-style-1 .cbp-filter-item {
    color: #2b2b2c; }

  .cbp-filters--sm-style-1 .cbp-filter-item:hover {
    color: #818a91; }

  .cbp-filters--sm-style-1 .cbp-filter-item-active {
    color: #007aff; } }
.cbp-caption-activeWrap {
  background-color: rgba(0, 0, 0, 0.85); }

.cbp-l-grid-work .cbp-item-wrapper {
  border-radius: 0.25rem; }

.cbp-l-grid-projects-title {
  font: 600 0.875rem "Montserrat", sans-serif !important;
  text-transform: uppercase;
  color: #2b2b2c !important; }

.cbp-l-grid-projects-desc {
  font: 400 12px/18px "Roboto", sans-serif !important;
  color: #818a91 !important; }

.cbp-l-grid-work-title {
  font: 600 0.875rem "Montserrat", sans-serif !important;
  text-transform: uppercase;
  color: #2b2b2c !important;
  display: block; }

/* ## LIGHT GALLERY ## */
.lightbox-item {
  cursor: pointer; }

.pace .pace-progress {
  background: #007aff !important; }

@media (min-width: 992px) {
  .masonry-item,
  .masonry-sizer {
    width: 48%; }

  .masonry-item {
    margin-bottom: 4%; }

  .masonry-gutter-size {
    width: 4%; }

  .masonry-3-cols .masonry-item,
  .masonry-3-cols .masonry-sizer {
    width: 30%; }

  .masonry-3-cols .masonry-gutter-size {
    width: 5%; }

  .masonry-3-cols .masonry-item {
    margin-bottom: 5%; } }
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0; }

.noUi-handle {
  position: relative;
  z-index: 1; }

.noUi-stacking .noUi-handle {
  z-index: 10; }

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Painting and performance. Browsers can paint handles in their own layer*/
.noUi-base {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* Slider size and handle placement */
.noUi-horizontal {
  height: 4px; }

.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  left: -7px;
  top: -6px; }

.noUi-vertical {
  width: 5px; }

.noUi-vertical .noUi-handle {
  width: 14px;
  height: 14px;
  left: -6px;
  top: -7px; }

/* Styling */
.noUi-background {
  background: #FAFAFA; }

.noUi-connect {
  background: #007aff;
  -webkit-transition: background 450ms;
  transition: background 450ms; }

.noUi-origin {
  border-radius: 2px; }

.noUi-target {
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  box-shadow: none; }

.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #BBB; }

/* Handles and cursors */
.noUi-dragable {
  cursor: w-resize; }

.noUi-vertical .noUi-dragable {
  cursor: n-resize; }

.noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 100%;
  background: #fff;
  cursor: pointer; }

.noUi-active {
  background: #007aff; }

/* Disabled state */
[disabled] .noUi-connect,
[disabled].noUi-connect {
  background: #b2b2b2; }

[disabled] .noUi-handle,
[disabled].noUi-origin {
  cursor: not-allowed; }

/* Range slider value labels */
.range-slider-value {
  font-size: 0.875rem;
  font-weight: 500; }

.range-slider-wrapper .upper-info {
  font-weight: 400;
  margin-bottom: 5px; }

.input-slider-value-output {
  background: #333;
  color: #fff;
  padding: 4px 8px;
  position: relative;
  top: 12px;
  font-size: 11px;
  border-radius: 2px; }

.input-slider-value-output:after {
  bottom: 100%;
  left: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #333;
  border-width: 4px;
  margin-left: -4px; }

.input-slider-value-output.left:after {
  left: 10px;
  right: auto; }

.input-slider-value-output.right:after {
  right: 10px;
  left: auto; }

.swiper-pagination-bullet-active {
  background: #007aff; }

.swiper-container {
  height: 100%; }

.swiper-wrapper .swiper-slide,
.swiper-wrapper .swiper-slide *:not(.btn),
.swiper-wrapper .swiper-slide *:not(.link) {
  cursor: grab !important;
  cursor: -webkit-grab !important;
  cursor: -moz-grab !important; }

.swiper-wrapper .swiper-slide:active,
.swiper-wrapper .swiper-slide:active * {
  cursor: grabbing !important;
  cursor: -webkit-grabbing !important;
  cursor: -moz-grabbing !important; }

.swiper-container-centered {
  margin: 20px auto; }

.swiper-container-centered .swiper-slide {
  width: 50%;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.swiper-container-vertical .swiper-slide {
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.swiper-container-vertical .swiper-slide-inner-right {
  padding-left: 50px; }

.swiper-container-vertical .swiper-pagination {
  right: auto;
  left: 10px; }

.swiper-container-vertical .swiper-pagination .swiper-pagination-bullet {
  margin: 8px 0;
  width: 6px;
  height: 6px; }

.swiper-container-vertical .swiper-pagination .swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  margin-left: -2px; }

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  margin-top: -22px;
  border-radius: 100%;
  z-index: 10;
  cursor: pointer;
  background: none;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: rgba(0, 0, 0, 0.5); }

.milestone-counter {
  margin: 30px 0; }

.milestone-counter .milestone-count {
  font-size: 72px;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase; }

.milestone-counter .milestone-count.milestone-count-sm {
  font-size: 52px !important; }

.milestone-counter .milestone-count.milestone-count-xs {
  font-size: 32px !important; }

.milestone-counter .milestone-info {
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase; }

.milestone-counter .milestone-icon {
  display: block;
  line-height: 1; }

.milestone-counter .milestone-icon .fa,
.milestone-counter .milestone-icon .icon {
  font-size: 60px;
  line-height: 1;
  margin-bottom: 30px; }

.milestone-counter .milestone-icon .icon:before {
  line-height: 0; }

.milestone-counter .milestone-delimiter {
  display: block;
  width: 60px;
  margin-bottom: 14px;
  border-bottom: 3px solid #007aff;
  border-bottom: 3px solid #007aff; }

.milestone-counter .milestone-delimiter:after,
.milestone-counter .milestone-delimiter:before {
  display: table;
  content: "";
  width: 100%; }

.milestone-counter .milestone-delimiter:after {
  clear: both; }

@media (max-width: 767px) {
  .milestone-counter {
    margin-bottom: 60px; } }
.morphext > .animated {
  display: inline-block; }

.circle-wrapper .circles-text {
  font-size: 22px !important;
  font-weight: 400 !important;
  color: #2b2b2c !important;
  font-family: "Montserrat", sans-serif; }

.circle-wrapper .circles-title {
  margin: 8px 0;
  padding: 0;
  font-weight: 400;
  font-size: 1rem;
  color: #2b2b2c;
  text-transform: none; }

.rev-slider-outer-wrapper {
  position: relative;
  overflow: hidden;
  background: #FFF; }

.rev_slider_wrapper {
  margin: 0 auto;
  padding: 0;
  background-color: transparent; }

.rev_slider_wrapper .title--style-1,
.rev_slider_wrapper .tp-caption.title--style-1 {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 70px;
  padding: 10px 0;
  text-decoration: none; }

.rev_slider_wrapper .subtitle--style-1,
.rev_slider_wrapper .tp-caption.subtitle--style-1 {
  font-size: 1rem; }

.rev_slider_wrapper .content--style-1,
.rev_slider_wrapper .tp-caption.content--style-1 {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: #999999;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 0;
  text-decoration: none; }

.title--style-2,
.tp-caption.title--style-2 {
  color: white;
  font-size: 65px;
  line-height: 70px;
  font-weight: 700;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  padding: 21px 30px 16px;
  text-decoration: none;
  text-align: left;
  background-color: rgba(17, 17, 17, 0.9);
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0; }

.category-title--style-2,
.tp-caption.category-title--style-2 {
  color: #111111;
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;
  font-style: normal;
  font-family: "Montserrat", sans-serif;
  padding: 21px 30px 16px;
  text-decoration: none;
  text-align: left;
  background-color: rgba(255, 255, 255, 0.9);
  border-color: transparent;
  border-style: none;
  border-width: 0;
  border-radius: 0 0 0 0;
  letter-spacing: 3px; }

.non-generic-icon,
.tp-caption.non-generic-icon {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0);
  border-radius: 0 0 0 0;
  border-style: solid;
  border-width: 0;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 30px;
  padding: 0;
  text-align: left;
  text-decoration: none; }

.rev_slider_wrapper .handwritten-title,
.rev_slider_wrapper .handwritten-title {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: Pacifico;
  font-size: 70px;
  font-style: normal;
  font-weight: 800;
  line-height: 70px;
  padding: 10px 0;
  text-decoration: none; }

.rev_slider_wrapper .handwritten-subtitle,
.rev_slider_wrapper .tp-caption.handwritten-subtitle {
  background-color: transparent;
  border-color: transparent;
  border-radius: 0 0 0 0;
  border-style: none;
  border-width: 0;
  color: white;
  font-family: Pacifico;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  padding: 10px 0;
  text-decoration: none; }

@media (min-width: 768px) {
  .rev_slider_wrapper .rev-slider-search-wrapper {
    width: 650px; } }
@media (max-width: 767px) {
  .rev_slider_wrapper .rev-slider-search-wrapper {
    width: 450px; } }
@media (max-width: 440px) {
  .rev_slider_wrapper .rev-slider-search-wrapper {
    width: 300px; } }
.tp-tab-title {
  font-family: "Montserrat", sans-serif !important; }

/* COUNTDOWN TIMER */
.countdown .countdown-item {
  display: inline-block;
  margin-right: 10px; }

.countdown .countdown-item:last-child {
  margin-right: 0; }

.countdown .countdown-digit {
  display: block;
  width: 60px;
  height: 60px;
  background: #333;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  line-height: 60px;
  font-family: "Montserrat", sans-serif; }

.countdown--style-2 .countdown-digit {
  background: rgba(0, 0, 0, 0.5);
  color: #fff; }

.countdown .countdown-label {
  display: block;
  margin-top: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase; }

.back-to-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 40px;
  right: 10px;
  text-align: center;
  line-height: 40px;
  background: #007aff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s; }

.back-to-top:before {
  font-family: "Ionicons";
  content: "\f3d8";
  display: block;
  font-size: 1rem;
  color: #FFF; }

.back-to-top.back-to-top-is-visible,
.back-to-top.back-to-top-fade-out,
.no-touch .back-to-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s; }

.back-to-top.back-to-top-is-visible {
  visibility: visible;
  opacity: 1; }

.back-to-top.back-to-top-fade-out {
  opacity: 0.8; }

.no-touch .back-to-top:hover {
  background-color: #e86256;
  opacity: 1; }

@media only screen and (min-width: 768px) {
  .back-to-top {
    right: 20px;
    bottom: 20px; } }
@media only screen and (min-width: 1024px) {
  .back-to-top {
    height: 40px;
    width: 40px;
    right: 30px;
    bottom: 30px;
    line-height: 40px; } }
/* ==========  Montserrat  ========== */
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/Montserrat-Light.eot");
  src: url("../fonts/montserrat/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/Montserrat-Light.woff") format("woff"), url("../fonts/montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/Montserrat-Regular.eot");
  src: url("../fonts/montserrat/Montserrat-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/Montserrat-Regular.woff") format("woff"), url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/Montserrat-Medium.eot");
  src: url("../fonts/montserrat/Montserrat-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/Montserrat-Medium.woff") format("woff"), url("../fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/Montserrat-SemiBold.eot");
  src: url("../fonts/montserrat/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/Montserrat-SemiBold.woff") format("woff"), url("../fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/Montserrat-Bold.eot");
  src: url("../fonts/montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/Montserrat-Bold.woff") format("woff"), url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/Montserrat-ExtraBold.eot");
  src: url("../fonts/montserrat/Montserrat-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/Montserrat-ExtraBold.woff") format("woff"), url("../fonts/montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }
@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat/Montserrat-Black.eot");
  src: url("../fonts/montserrat/Montserrat-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat/Montserrat-Black.woff") format("woff"), url("../fonts/montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }
/* ==========  Blzee  ========== */
@font-face {
  font-family: 'Blzee';
  src: url("../fonts/blzee/blzee.eot");
  src: url("../fonts/blzee/blzee.eot?#iefix") format("embedded-opentype"), url("../fonts/blzee/blzee.woff") format("woff"), url("../fonts/blzee/blzee.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: 'Sue Ellen Francisco';
  src: url("../fonts/other/sue-ellen-francisco.ttf");
  src: url("../fonts/other/sue-ellen-francisco.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'Pacifico';
  src: url("../fonts/other/pacifico.ttf");
  src: url("../fonts/other/pacifico.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: 'YellowTail';
  src: url("../fonts/other/yellowtail-regular.ttf");
  src: url("../fonts/other/yellowtail-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
.footer {
  background: #111;
  color: rgba(255, 255, 255, 0.7); }

.footer-top {
  padding: 4rem 0; }

.footer-bottom {
  padding: 1.5rem 0;
  background: #131313; }

.footer-bottom .copyright {
  font-size: 0.875rem; }

.footer .heading {
  color: rgba(255, 255, 255, 0.7); }

.footer ul.footer-links {
  margin: 0;
  padding: 0;
  list-style: none; }

.footer .footer-links > li > a {
  display: inline-block;
  padding: 0.25rem 0;
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7); }

.footer .footer-links > li > a:hover {
  color: rgba(255, 255, 255, 0.5); }

.footer .footer-links > li > .footer-link-date {
  display: block;
  margin-bottom: 0.5rem; }

.footer p {
  font-size: 0.875rem; }

.footer .social-media > li > a > i {
  color: rgba(255, 255, 255, 0.7); }

.footer .social-media > li > a:hover > i {
  color: rgba(255, 255, 255, 0.5); }

.footer .footer-menu {
  padding: 0;
  margin: 0;
  list-style: none; }

.footer .footer-menu > li {
  display: inline-block;
  margin-right: 1rem; }

.footer .footer-menu > li > a {
  display: inline-block;
  padding: 0.25rem 0.4rem;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase; }

.footer .footer-menu > li:last-child > a {
  margin-right: 0; }

.footer .footer-menu > li > a:hover {
  color: rgba(255, 255, 255, 0.5); }

.footer .footer-menu > li.logo-delimiter {
  padding-left: 2rem;
  padding-right: 2rem; }

.footer-inverse {
  background: #eeeeee;
  color: rgba(0, 0, 0, 0.7); }

.footer-inverse .footer-bottom {
  background: #ececec; }

.footer-inverse .heading {
  color: rgba(0, 0, 0, 0.7); }

.footer-inverse .footer-links > li > a {
  color: rgba(0, 0, 0, 0.7); }

.footer-inverse .footer-links > li > a:hover {
  color: rgba(0, 0, 0, 0.5); }

.footer-inverse .social-media > li > a > i {
  color: rgba(0, 0, 0, 0.7); }

.footer-inverse .social-media > li > a:hover > i {
  color: rgba(0, 0, 0, 0.5); }

.footer-inverse .footer-menu > li > a {
  color: rgba(0, 0, 0, 0.7); }

.footer-inverse .footer-menu > li > a:hover {
  color: rgba(0, 0, 0, 0.5); }

.footer--style-1 {
  background: #007aff;
  color: #FFF; }

.footer--style-1 .footer-links > li > a,
.footer--style-1 .heading {
  color: #FFF; }

.footer--style-1 .social-media > li > a > i {
  color: #FFF; }

label {
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: none; }

textarea.no-resize {
  resize: none !important; }

.form-control,
.form-group .form-control {
  padding-left: 20px;
  padding-right: 20px;
  height: 46px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #555;
  background-color: #FFF;
  background-image: none;
  outline: 0;
  border-width: 1px;
  border-color: #e6e6e6;
  border-radius: 1px;
  -webkit-box-shadow: none;
  box-shadow: none; }

textarea.form-control,
.form-group textarea.form-control {
  height: auto; }

.form-control:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.form-control-icon {
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  pointer-events: none;
  font-size: 14px; }

.form-control:focus .form-control-icon {
  color: #333; }

.form-inverse .form-control-icon {
  color: #818a91; }

.form-group-lg.has-icon .form-control {
  padding-left: 36px; }

.form-group-lg .form-control-feedback,
.form-group-lg.has-icon .form-control-icon,
.input-lg + .form-control-feedback,
.input-lg + .form-control-icon {
  width: 46px;
  height: 46px;
  line-height: 46px; }

.form-group-sm .form-control-feedback,
.form-group-sm.has-icon .form-control-icon,
.input-sm + .form-control-feedback,
.input-sm + .form-control-icon {
  width: 30px;
  height: 30px;
  line-height: 30px; }

.has-icon {
  position: relative; }

.has-feedback label ~ .form-control-feedback,
.has-icon label ~ .form-control-icon {
  top: 26px; }

.has-icon label.sr-only ~ .form-control-icon {
  top: 0; }

.has-icon .form-control {
  padding-left: 30px; }

.has-icon .form-control.input-lg {
  padding-left: 40px; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  font-size: 0.875rem; }

.form-default .form-control {
  background: #FFF;
  border-color: #e6e6e6;
  color: #555; }

.form-default .form-control:focus {
  background: #fafafa;
  border-color: #007aff; }

.form-default .form-control::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4); }

.form-default .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.4); }

.form-default .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.4); }

.form-default .form-control:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.4); }

.form-inverse .form-control {
  background: #21252a;
  border-color: #333a41;
  color: #818a91; }

.form-inverse .form-control:focus {
  background: #21252a;
  border-color: white; }

.form-inverse .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4); }

.form-inverse .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.4); }

.form-inverse .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.4); }

.form-inverse .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.4); }

.form-control.input-lg,
.form-group-lg .form-control,
.input-group-lg > .form-control {
  border-radius: 1px; }

.input-group-lg > .input-group-btn > .btn {
  border-radius: 1px;
  border-width: 1px; }

.input-group-btn:first-child > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0; }

.input-group-btn:last-child > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

/* Bootstrap select: plugin (assets/bootstrap-select) */
.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
  width: 100% !important; }

.form-control.bootstrap-select {
  background: transparent; }

.bootstrap-select .dropdown-menu {
  padding-top: 10px;
  padding-bottom: 10px; }

.bootstrap-select .dropdown-menu.open {
  border-color: #fff;
  top: -4px;
  border-radius: 1px; }

.bootstrap-select .dropdown-menu > li > a {
  outline: 0;
  padding: 8px 20px; }

.form-inverse .bootstrap-select .dropdown-menu > li > a:hover,
.form-inverse .bootstrap-select .dropdown-menu > li.selected > a {
  background: #0d0d0d; }

.bootstrap-select.form-control {
  font-size: 1.9rem; }

.bootstrap-select .btn-default {
  padding: 12px 20px 11px;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  border-radius: 1px; }

.form-group-lg.has-icon .btn-default {
  padding-left: 36px; }

.form-default .bootstrap-select .btn-default {
  background: #FFF;
  border-color: #e6e6e6;
  color: rgba(0, 0, 0, 0.4); }

.form-default .btn-default.active.focus,
.form-default .btn-default.active:focus,
.form-default .btn-default.active:hover,
.form-default .btn-default:active:focus,
.form-default .btn-default:active:hover,
.form-default .form-inverse .btn-default:active.focus,
.form-default .open > .dropdown-toggle.btn-default,
.form-default .open > .dropdown-toggle.btn-default.focus,
.form-default .open > .dropdown-toggle.btn-default:focus,
.form-default .open > .dropdown-toggle.btn-default:hover {
  background: #fafafa;
  color: #333;
  border-color: transparent; }

.form-inverse .bootstrap-select .btn-default {
  background: #21252a;
  border-color: #333a41;
  color: #818a91; }

.form-inverse .btn-default.active.focus,
.form-inverse .btn-default.active:focus,
.form-inverse .btn-default.active:hover,
.form-inverse .btn-default:active:focus,
.form-inverse .btn-default:active:hover,
.form-inverse .form-inverse .btn-default:active.focus,
.form-inverse .open > .dropdown-toggle.btn-default,
.form-inverse .open > .dropdown-toggle.btn-default.focus,
.form-inverse .open > .dropdown-toggle.btn-default:focus,
.form-inverse .open > .dropdown-toggle.btn-default:hover {
  background: #21252a;
  border-color: inherit; }

.form-inverse .bootstrap-select .dropdown-menu {
  background: black;
  border-color: black; }

.form-material .bootstrap-select .btn-default,
.form-material .form-control {
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  border-width: 2px;
  padding-left: 1px;
  padding-right: 1px; }

.form-default.form-material .form-control:focus {
  background: transparent;
  border-color: #e6e6e6; }

.form-inverse.form-material .form-control:focus {
  background: transparent;
  border-color: #191919; }

.form-material .bar {
  position: relative;
  display: block; }

.form-material .bar:after,
.form-material .bar:before {
  content: '';
  height: 2px;
  width: 0;
  bottom: 0;
  position: absolute;
  background: #007aff;
  transition: 0.2s ease all; }

.form-material .bar:before {
  left: 50%; }

.form-material .bar:after {
  right: 50%; }

.form-material .form-control:focus ~ .bar:after,
.form-material .form-control:focus ~ .bar:before {
  width: 50%; }

/* CUSTOM CHECKBOXES AND RADIOS */
/*
.checkbox {
  padding-left: 20px;
  outline: none; }

.checkbox-inline {
  display: inline-block;
  margin-right: 10px; }

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 8px;
  font-size: 0.875rem;
  line-height: 18px; }

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 2px solid #e6e6e6;
  border-radius: 1px;
  background-color: #FFF;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 0;
  font-size: 12px;
  color: transparent; }

.checkbox input[type="checkbox"],
.checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1; }

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
  color: #333;
  font-family: "FontAwesome";
  content: "\f00c"; }

.checkbox input[type="checkbox"]:disabled + label,
.checkbox input[type="radio"]:disabled + label {
  opacity: 0.65; }

.checkbox input[type="checkbox"]:disabled + label::before,
.checkbox input[type="radio"]:disabled + label::before {
  background-color: #ddd;
  cursor: not-allowed; }

.checkbox.checkbox-circle label::before {
  border-radius: 50%; }

.checkbox.checkbox-inline {
  margin-top: 0; }
*/

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #5cb85c;
  border-color: #5cb85c; }

.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.has-success input[type="checkbox"] + label::before,
.has-success input[type="radio"] + label::before {
  border-color: #5cb85c; }

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #007aff;
  border-color: #007aff; }

.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #FFF; }

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #ec971f;
  border-color: #ec971f; }

.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.has-warning input[type="checkbox"] + label::before,
.has-warning input[type="radio"] + label::before {
  border-color: #ec971f; }

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #c9302c;
  border-color: #c9302c; }

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.has-error input[type="checkbox"] + label::before,
.has-error input[type="radio"] + label::before {
  border-color: #c9302c; }

/* Radio inputs */
.radio {
  padding-left: 20px;
  outline: none; }

.radio-inline {
  display: inline-block;
  margin-right: 10px; }

.radio label {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 18px;
  position: relative;
  padding-left: 8px; }

.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 2px solid #e6e6e6;
  border-radius: 50%;
  background-color: #FFF;
  -webkit-transition: border 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out; }

.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 11px;
  height: 11px;
  left: 3px;
  top: 3px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #333;
  -webkit-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }

.radio input[type="radio"] {
  opacity: 0;
  z-index: 1; }

.radio input[type="radio"]:focus + label::before {
  outline: none; }

.radio input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1); }

.radio input[type="radio"]:disabled + label {
  opacity: 0.65; }

.radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed; }

.radio.radio-inline {
  margin-top: 0; }

.radio-primary input[type="radio"] + label::after {
  background-color: #007aff; }

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #007aff; }

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #007aff; }

.radio-danger input[type="radio"] + label::after {
  background-color: #d9534f; }

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #d9534f; }

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #d9534f; }

.radio-info input[type="radio"] + label::after {
  background-color: #5bc0de; }

.radio-info input[type="radio"]:checked + label::before {
  border-color: #5bc0de; }

.radio-info input[type="radio"]:checked + label::after {
  background-color: #5bc0de; }

.radio-warning input[type="radio"] + label::after {
  background-color: #f0ad4e; }

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #f0ad4e; }

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #f0ad4e; }

.radio-success input[type="radio"] + label::after {
  background-color: #5cb85c; }

.radio-success input[type="radio"]:checked + label::before {
  border-color: #5cb85c; }

.radio-success input[type="radio"]:checked + label::after {
  background-color: #5cb85c; }

.product-size input {
  left: -9999px;
  position: absolute; }

.product-size label {
  width: 36px;
  height: 36px;
  float: right;
  padding: 6px 0;
  display: block;
  color: #818a91;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background: transparent;
  text-transform: uppercase;
  border: 1px solid #e6e6e6; }

.product-size label {
  -ms-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -webkit-transition: color 0.3s; }

.product-size label > img {
  max-width: 100%; }

.product-size label:hover {
  color: #007aff;
  cursor: pointer;
  border-color: #007aff; }

.product-size input:checked ~ label {
  color: #007aff;
  border-color: #007aff;
  font-weight: 600; }

.product-size--style-1 label {
  width: auto;
  padding-left: 10px;
  padding-right: 10px; }

/* CUSTOM PRODUCT COLOR INPUT */
.product-color input {
  left: -9999px;
  position: absolute; }

.product-color label {
  width: 36px;
  height: 36px;
  float: right;
  padding: 5px;
  display: block;
  font-size: 14px;
  text-align: center;
  opacity: 0.7; }

.product-color label {
  -ms-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -webkit-transition: color 0.3s; }

.product-color label:hover {
  cursor: pointer;
  opacity: 1; }

.product-color input:checked ~ label {
  height: 40px;
  opacity: 1; }

.product-color-circle label {
  border-radius: 100%; }

.product-color-circle input:checked ~ label {
  height: 36px; }

.form-card--style-1 {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.form-card--style-1 .form-header {
  padding: 2rem;
  background-color: #f1f1f1; }

.form-card--style-1 .form-header .heading {
  margin: 0;
  padding: 0;
  color: #2b2b2c; }

.form-card--style-1 .form-body {
  padding: 2rem; }

.form-card--style-1 .form-footer {
  padding: 1rem 2rem;
  background-color: #f1f1f1; }

.form-card--style-2 .form-header {
  position: relative;
  padding: 2rem 0;
  background-color: #007aff; }

.form-card--style-2 .form-header .form-header-title {
  margin: 0;
  padding: 0;
  color: #FFF; }

.form-card--style-2 .form-header:after {
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 14px;
  top: 100%;
  left: 50%;
  border-top-color: #007aff;
  margin-left: -14px; }

.form-card--style-2 .form-header .form-header-icon i {
  font-size: 3rem;
  color: #FFF; }

.form-card--style-2 .form-body {
  padding: 2rem;
  background-color: #fff; }

.form-card--style-2 .form-footer {
  padding: 1rem 0;
  background-color: #fff; }

.form-user-footer-links a {
  font-size: 0.75rem;
  color: #818a91; }

.help-block.with-errors > ul > li {
  font-size: 0.875rem;
  color: #ff3b30; }

.has-error .form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #ff3b30; }

.has-success .form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #4cd964; }

.has-feedback .glyphicon {
  font-family: "Ionicons" !important;
  font-size: 12px; }

.has-feedback .glyphicon-remove::before {
  content: "\f129";
  color: #ff3b30; }

.has-feedback .glyphicon-ok::before {
  content: "\f121";
  color: #4cd964; }

ul.icons {
  margin: 0;
  padding: 0; }

ul.icons li {
  list-style: none;
  margin: 5px 0 0;
  vertical-align: top;
  font-size: 0.875rem; }

ul.icons li > a:not(.icon) {
  color: #818a91; }

ul.icons li > a:hover {
  color: #007aff; }

ul.icons li .icon {
  margin-right: 12px;
  position: relative; }

ul.inline-links {
  margin: 0;
  padding: 0;
  list-style: none; }

ul.inline-links > li {
  display: inline-block;
  padding: 0 17px 0 8px;
  position: relative;
  font-size: 0.875rem; }

ul.inline-links > li:first-child {
  padding-left: 0; }

ul.inline-links > li:last-child {
  padding-right: 0; }

ul.inline-links > li,
ul.inline-links > li > a {
  color: #818a91; }

ul.inline-links > li > a.active {
  color: #2b2b2c;
  font-weight: 500; }

ul.inline-links > li > a:hover {
  color: #007aff; }

ul.inline-links > li > a > i,
ul.inline-links > li > i {
  margin-right: 4px; }

ul.inline-links.inline-links--style-2 > li,
ul.inline-links.inline-links--style-2 > li > a {
  font-size: 13px; }

ul.inline-links.inline-links--style-2 > li:before {
  position: absolute;
  right: 0;
  content: "/";
  color: #818a91; }

ul.inline-links.inline-links--style-2 > li:last-child:before {
  content: ""; }

ul.categories {
  padding: 0;
  margin: 0;
  list-style: none; }

ul.categories > li {
  border-bottom: 1px solid #f1f1f1; }

ul.categories > li:last-child {
  border: 0; }

ul.categories > li > a {
  display: block;
  padding: 12px 0;
  color: #818a91;
  font-size: 0.875rem;
  font-family: "Montserrat", sans-serif; }

ul.categories > li > a:after,
ul.categories > li > a:before {
  content: "";
  display: table; }

ul.categories > li > a:after {
  clear: both; }

ul.categories > li:hover > a {
  color: #007aff; }

ul.categories--style-1 > li > a {
  padding-left: 20px;
  position: relative;
  display: flex;
  align-items: center; }

ul.categories--style-1 > li > a > .category-name {
  color: #2b2b2c;
  flex-grow: 1; }

ul.categories--style-1 > li > a:hover > .category-name {
  color: #007aff; }

ul.categories--style-1 > li > a > .category-count {
  color: #818a91;
  font-size: 0.75rem; }

ul.categories--style-1 > li > a:before {
  content: "\f125";
  font-family: "Ionicons";
  position: absolute;
  height: 16px;
  line-height: 16px;
  left: 0;
  top: 50%;
  margin-top: -8px;
  font-size: 10px;
  color: #818a91; }

ul.meta-info-cells {
  margin: 0;
  padding: 0; }

ul.meta-info-cells-v2 li,
ul.meta-info-cells-v3 li {
  display: inline-block;
  float: left;
  padding: 0 10px;
  vertical-align: middle;
  margin-right: 1rem; }

ul.meta-info-cells-v2 li a,
ul.meta-info-cells-v3 li a {
  color: #818a91; }

ul.meta-info-cells-v2 li {
  padding: 0;
  color: #2b2b2c;
  font-size: 13px; }

ul.meta-info-cells-v2 li i {
  color: #2b2b2c;
  font-size: 13px;
  margin-right: 5px; }

ul.meta-info-cells-v3 li i {
  width: 31px;
  height: 31px;
  margin-right: 5px;
  text-align: center;
  line-height: 31px;
  background: transparent;
  border: 1px solid #f1f1f1;
  color: #2b2b2c;
  font-size: 13px;
  border-radius: 100%; }

ul.meta-info-cells-v3 li:hover i {
  background: #007aff;
  color: #FFF;
  border-color: #007aff; }

ul.meta-info-cells-v3 li:first-child {
  padding-left: 0; }

ul.meta-info-cells-v3 li:last-child {
  border: 0;
  padding-right: 0; }

ul.meta-info-cells-v3 li .btn-link {
  color: #007aff;
  font-weight: 600;
  font-size: 0.875rem; }

ul.meta-info-cells-v4 > li,
ul.meta-info-cells-v5 > li {
  display: inline-block;
  margin-right: 18px;
  font-size: 0.875rem;
  color: #818a91;
  font-weight: 500;
  font-family: "Montserrat", sans-serif; }

ul.meta-info-cells-v5 > li {
  margin: 0; }

ul.meta-info-cells-v5 > li > span {
  display: block;
  padding-right: 10px;
  margin-right: 7px;
  border-right: 1px solid #f1f1f1; }

ul.meta-info-cells-v5 > li:last-child > span {
  border: 0; }

ul.meta-info-cells-v4 > li > a > i,
ul.meta-info-cells-v5 > li > a > i {
  margin-right: 5px;
  color: #818a91; }

ul.meta-info-cells-v4 > li > a,
ul.meta-info-cells-v5 > li > a {
  color: #818a91; }

ul.meta-info-cells-v4 > li > a:hover,
ul.meta-info-cells-v4 > li > a:hover > i,
ul.meta-info-cells-v5 > li > a:hover,
ul.meta-info-cells-v5 > li > a:hover > i {
  color: #007aff; }

ul.meta-info-cells-v4 > li:first-child,
ul.meta-info-cells-v5 > li:first-child {
  padding-left: 0; }

ul.meta-info-cells-v4 > li:last-child,
ul.meta-info-cells-v5 > li:last-child {
  border: 0;
  padding-right: 0; }

ul.meta-info-cells-v4 li .btn-link {
  color: #007aff;
  font-weight: 600;
  font-size: 0.875rem; }

@media (max-width: 771px) {
  ul.meta-info-cells-v4 li,
  ul.meta-info-cells-v5 li {
    margin-bottom: 5px; } }
.sidebar .list-unstyled {
  font-size: 0.875rem; }

.sidebar .list-unstyled > li > a {
  color: #2b2b2c;
  padding: 0.2rem 0;
  display: block; }

.sidebar .list-unstyled > li:hover > a {
  color: #007aff; }

.vertical-info {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative; }

.vertical-info > li {
  padding: 0.25rem 0; }

.vertical-info > li > span {
  display: inline-block;
  font-size: 0.875rem; }

.vertical-info > li > .vi-title {
  font-weight: 600;
  margin-right: 0.5rem; }

.vertical-info > li > .vi-content {
  color: #818a91; }

ul.list-bullet {
  list-style: none;
  margin: 0;
  padding: 0;
  background: transparent; }

ul.list-bullet li {
  clear: left;
  padding: 10px 0;
  display: block;
  width: 100%; }

ul.list-bullet li > figure {
  margin: 0;
  padding: 0;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 1rem;
  color: #eceeef;
  font-weight: 600;
  display: inline-block;
  float: left; }

ul.list-bullet li img {
  width: 60px; }

ul.list-bullet li h3 {
  font-size: 16px;
  font-weight: 600;
  margin-left: 15px;
  display: inline-block; }

ul.list-bullet li p {
  margin: 0 0 0 50px;
  padding: 0; }

ul.list-bullet li span {
  margin-left: 6px; }

ul.list-bullet li a {
  font-weight: 500; }

ul.list-bullet li a:hover {
  text-decoration: none;
  color: #464646; }

ul.list-bullet li span {
  font-size: 12px; }

section.ss-slice {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem; }

section.ss-slice::after {
  position: absolute;
  content: '';
  pointer-events: none; }

.ss-style-triangles::after {
  left: 50%;
  width: 100px;
  height: 100px;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg); }

.ss-style-triangles::after {
  bottom: -50px;
  z-index: 10;
  background: inherit; }

.ss-slice.ss-style-doublediagonal {
  z-index: 1;
  padding-bottom: 10em; }

.ss-style-doublediagonal::after,
.ss-style-doublediagonal::before {
  top: 0;
  left: -25%;
  z-index: -1;
  width: 150%;
  height: 75%;
  background: inherit;
  -webkit-transform: rotate(-4deg);
  transform: rotate(-4deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0; }

.ss-style-halfcircle::after,
.ss-style-halfcircle::before {
  left: 50%;
  z-index: 10;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: inherit;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%); }

.ss-style-halfcircle::before {
  top: -50px; }

.ss-style-halfcircle::after {
  bottom: -50px; }

.ss-style-multitriangles::after,
.ss-style-multitriangles::before {
  left: 50%;
  width: 50px;
  height: 50px;
  -webkit-transform: translateX(-50%) rotate(45deg);
  transform: translateX(-50%) rotate(45deg); }

.ss-style-multitriangles::before {
  top: -25px;
  background: inherit;
  box-shadow: -50px 50px 0 #3498db, 50px -50px 0 #3498db; }

.ss-style-multitriangles::after {
  bottom: -25px;
  z-index: 10;
  background: inherit;
  box-shadow: -50px 50px 0 #3498db, 50px -50px 0 #3498db; }

.ss-style-roundedsplit {
  padding-top: 7em;
  border-radius: 0 0 80px 80px; }

.ss-style-roundedsplit::before {
  left: 0;
  z-index: 10;
  width: 50%;
  background: inherit; }

.ss-style-roundedsplit::before {
  border-radius: 0 80px 0 0; }

.ss-style-invertedrounded {
  padding: 13em 10% 10em;
  border-radius: 0 0 0 90px; }

.ss-style-invertedrounded::after,
.ss-style-invertedrounded::before {
  left: 0;
  z-index: -1;
  height: 90px;
  background: #0062cc; }

.ss-style-invertedrounded::before {
  top: 100%;
  width: 100%;
  border-radius: 0 90px 0 0; }

.ss-style-invertedrounded::after {
  bottom: 0;
  z-index: -1;
  width: 50%; }

.ss-style-zigzag::after,
.ss-style-zigzag::before {
  right: 0;
  left: 0;
  z-index: 10;
  display: block;
  height: 90px;
  background-size: 50px 100%; }

.ss-style-zigzag::after {
  top: 100%;
  background-position: 50%; }

.bg-base-1.ss-style-zigzag::after {
  background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #007aff), color-stop(0.25, #007aff));
  background-image: linear-gradient(135deg, #007aff 25%, transparent 25%), linear-gradient(225deg, #007aff 25%, transparent 25%); }

.sct-color-1.ss-style-zigzag::after {
  background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #FFF), color-stop(0.25, #FFF));
  background-image: linear-gradient(135deg, #FFF 25%, transparent 25%), linear-gradient(225deg, #FFF 25%, transparent 25%); }

.sct-color-2.ss-style-zigzag::after {
  background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #fafafa), color-stop(0.25, #fafafa));
  background-image: linear-gradient(135deg, #fafafa 25%, transparent 25%), linear-gradient(225deg, #fafafa 25%, transparent 25%); }

.sct-color-3.ss-style-zigzag::after {
  background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #282d33), color-stop(0.25, #282d33));
  background-image: linear-gradient(135deg, #282d33 25%, transparent 25%), linear-gradient(225deg, #282d33 25%, transparent 25%); }

.sct-color-4.ss-style-zigzag::after {
  background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #121416), color-stop(0.25, #121416));
  background-image: linear-gradient(135deg, #121416 25%, transparent 25%), linear-gradient(225deg, #121416 25%, transparent 25%); }

.st-container,
.st-content,
.st-pusher {
  height: 100%; }

.st-content {
  background: transparent; }

.st-content,
.st-content-inner {
  position: relative; }

.st-container {
  position: relative;
  overflow: hidden; }

.st-pusher {
  position: relative;
  right: 0;
  z-index: 99;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s; }

.st-pusher::after {
  z-index: 1100;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  background: rgba(0, 0, 0, 0.2);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s; }

.st-menu-open .st-pusher::after {
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }

.st-menu {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  visibility: hidden;
  width: 300px;
  height: 100%;
  overflow-y: scroll;
  background: white;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-left: 1px solid transparent; }

.st-menu::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  content: '';
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s; }

.st-menu-open .st-menu::after {
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s; }

.st-menu-open {
  overflow: hidden; }

.st-menu::-webkit-scrollbar {
  display: none !important;
  width: 0 !important; }

.st-menu-title {
  font-size: 1rem;
  color: #2b2b2c;
  margin: 10px 0 0;
  padding: 15px 20px 10px; }

.st-profile {
  background-image: url("../images/patterns/pattern-4.png");
  background-repeat: repeat;
  padding: 2rem; }

.st-profile-user-wrapper {
  display: table;
  width: 100%;
  vertical-align: middle; }

.profile-user-image {
  display: table-cell;
  padding-right: 1rem;
  width: 72px; }

.profile-user-image > img {
  max-width: 100%; }

.profile-user-info {
  display: inline-block;
  vertical-align: middle; }

.st-profile .profile-user-name {
  color: #007aff;
  font-size: 1rem;
  font-weight: 600; }

.st-profile .profile-user-email {
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.5); }

.st-menu-title {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5); }

.st-menu-list > ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.st-menu-list > ul > li {
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500; }

.st-menu-list > ul > li > a {
  display: block;
  padding: 0.3125rem 2rem;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  -webkit-transition: background 0.3s, box-shadow 0.3s;
  transition: background 0.3s, box-shadow 0.3s; }

.st-menu-list > ul > li > a:hover {
  color: rgba(0, 0, 0, 0.7); }

.st-menu-list > ul > li > a > i {
  margin-right: 1rem;
  display: inline-block;
  font-size: 20px;
  width: 20px; }

.st-effect-1.st-menu-open .st-pusher {
  -webkit-transform: translate3d(-300px, 0, 0);
  transform: translate3d(-300px, 0, 0); }

.st-effect-1.st-menu-open .st-pusher > .st-content {
  overflow: hidden; }

.st-effect-1.st-menu {
  z-index: 1; }

.st-effect-1.st-menu-open .st-effect-1.st-menu {
  visibility: visible;
  -webkit-transition: -webkit-transform 0.5s;
  transition: transform 0.5s; }

.st-effect-1.st-menu::after {
  display: none; }

.timeline-group:after {
  content: "";
  display: table;
  clear: both; }

.timeline-item {
  position: relative; }

.timeline-item:before {
  content: "";
  position: absolute;
  width: 3px;
  background: #f1f1f1;
  top: 0;
  left: 17px;
  bottom: 0; }

.timeline-item:last-child:before {
  background: transparent; }

.timeline-item.first:before {
  top: 30px; }

.timeline-item .timeline-info {
  min-height: 100%;
  padding: 0 20px 30px 40px; }

.timeline-item.first .timeline-info {
  padding-top: 20px; }

@media screen and (min-width: 760px) {
  .timeline-item .timeline-info {
    float: left;
    width: 100%; } }
.timeline-item .timeline-info .date {
  position: relative;
  font-size: 0.875rem;
  color: #818a91;
  margin-bottom: 10px; }

.timeline-item .timeline-info .date:before {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 2px solid #f1f1f1;
  background: #fff;
  left: -28px;
  top: 0; }

.timeline-item .timeline-info .timeline-title {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: #2b2b2c;
  font-weight: 500; }

.timeline-item .timeline-info .timeline-subtitle {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 300;
  color: #818a91; }

.timeline-item .timeline-info .meta {
  color: black;
  margin-top: 10px; }

.timeline-item .timeline-info .meta p {
  margin: 0; }

.content .timeline-item .portfolio-image {
  padding: 20px;
  background: white;
  border-left: 1px solid #dedede;
  text-align: center; }

@media screen and (min-width: 760px) {
  .content .timeline-item .portfolio-image {
    float: left;
    width: 70%; } }
.content .timeline-item .portfolio-image img {
  width: 100%;
  max-width: 610px;
  height: auto; }

.content .timeline-item .portfolio-image div.loading img {
  width: auto;
  height: auto; }

.content div.loading {
  color: #c4c4c4;
  position: absolute;
  width: 100px;
  bottom: 15px;
  left: 50%;
  margin-left: -50px; }

.content div.loading img {
  vertical-align: middle; }

.content div.loading img.rotate {
  -webkit-animation-name: rotate;
  -ms-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  animation-timing-function: linear; }

body {
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  color: #55595c; }

p {
  font-size: 0.875rem;
  line-height: 1.7rem; }

a {
  color: #007aff;
  text-decoration: none; }

a:hover,
a:active {
  color: #0055b3;
  text-decoration: none; }

a.link {
  display: inline-block;
  font-family: "Montserrat", sans-serif; }

a.link-lg {
  font-size: 1.25rem; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  line-height: 1.46; }

.heading {
  margin: 0 0 6px;
  padding: 0;
  text-transform: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #2b2b2c;
  line-height: 1.46; }

.heading.heading-inverse {
  color: white; }

.heading > a {
  color: #2b2b2c; }

.heading:hover > a {
  color: #007aff !important; }

.heading > .icon,
.heading > a > .icon {
  display: inline-block;
  margin-right: 4px; }

.heading-1 {
  font-size: 2.5rem !important; }

.heading-2 {
  font-size: 2rem !important; }

.heading-3 {
  font-size: 1.75rem !important; }

.heading-4 {
  font-size: 1.5rem !important; }

.heading-5 {
  font-size: 1.25rem !important; }

.heading-6 {
  font-size: 1rem !important; }

.heading-xs {
  font-size: 0.75rem !important; }

.heading-sm {
  font-size: 0.875rem !important; }

.heading-lg {
  font-size: 1.25rem !important; }

.heading-xl {
  font-size: 3.75rem !important; }

.fluid-paragraph {
  font-size: 1rem;
  width: 680px;
  margin: auto;
  padding: 0 20px; }

.fluid-paragraph h1:not(.heading),
.fluid-paragraph h2:not(.heading),
.fluid-paragraph h3:not(.heading),
.fluid-paragraph h4:not(.heading),
.fluid-paragraph h5:not(.heading),
.fluid-paragraph h6:not(.heading) {
  text-transform: none !important; }

.fluid-paragraph.fluid-paragraph-sm {
  width: 580px; }

.fluid-paragraph.fluid-paragraph-md {
  width: 780px; }

.fluid-paragraph.fluid-paragraph-lg {
  width: 880px; }

.fluid-paragraph.paragraph-sm,
.fluid-paragraph.paragraph-sm > p {
  font-size: 0.875rem; }

.fluid-paragraph.paragraph-lg,
.fluid-paragraph.paragraph-lg > p {
  font-size: 1.25rem; }

@media (max-width: 767px) {
  .fluid-paragraph {
    width: 100% !important; } }
.quote-block-lg {
  line-height: 2.4rem; }

.quote-icon-lg {
  font-size: 2rem;
  font-weight: 600;
  display: inline-block;
  margin: 0 5px; }

small,
.small {
  font-size: 70%; }

.alert strong {
  color: inherit !important; }

.block {
  margin: 0;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
  cursor: default;
  border-radius: 0.25rem 0.25rem 0.25rem;
  -moz-border-radius: 0.25rem 0.25rem 0.25rem; }

.block:after,
.block:before {
  display: table;
  content: ""; }

.block:after {
  clear: both; }

@media (max-width: 991px) {
  .block {
    margin-bottom: 2rem !important; } }
a > .block {
  cursor: pointer; }

.block-stack-wrapper {
  border-radius: 0.25rem; }

.block-stack-wrapper .row {
  background: #fff; }

@media (max-width: 991px) {
  .block-stack-wrapper .row {
    margin-bottom: 20px; } }
.block .block-body {
  padding: 1.5rem 1.5rem; }

.block .block-image {
  position: relative; }

.block .block-image > img {
  max-width: 100%; }

.block .block-image .image-title {
  position: absolute;
  left: 0;
  bottom: 20px;
  padding: 6px 8px;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif; }

.block .block-image .image-title.image-title--top {
  top: 15px;
  bottom: auto; }

.block .block-image .image-title.image-title--bottom {
  top: auto;
  bottom: 15px; }

.block .block-image-animate--style-1 img {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease; }

.block:hover .block-image-animate--style-1 img {
  -webkit-transform: rotate(10deg) scale(1.2);
  /* Chrome 4+, Op 15+, Saf 3.1, iOS Saf 3.2+ */
  -moz-transform: rotate(10deg) scale(1.2);
  /* Fx 3.5-15 */
  -ms-transform: rotate(10deg) scale(1.2);
  /* IE 9 */
  -o-transform: rotate(10deg) scale(1.2);
  /* Op 10.5-12 */
  transform: rotate(10deg) scale(1.2);
  /* Fx 16+, IE 10+ */ }

.block-author {
  display: table; }

.block-author:after,
.block-author:before {
  content: "";
  display: table; }

.block-author:after {
  clear: both; }

.block-author .author-image {
  width: 60px;
  display: table-cell; }

.block-author .author-image-sm {
  width: 40px; }

.block-author .author-image img {
  width: 100%;
  border-radius: 100%; }

.block-author .author-info {
  display: table-cell;
  padding-left: 10px;
  vertical-align: middle; }

.block-author .author-info .author-name {
  display: inline-block;
  font-weight: 600; }

.block-author .author-info .author-name > a {
  color: #2b2b2c; }

.block-author .author-info .author-name > a:hover {
  color: #007aff; }

.block-author .author-info .author-desc {
  font-weight: 400;
  font-size: 0.875rem; }

.block-date-over {
  position: absolute;
  top: 14px;
  right: 14px;
  padding: 10px 15px;
  background: rgba(255, 255, 255, 0.8);
  color: #2b2b2c;
  border-radius: 0.1rem;
  font-size: 0.75rem;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase; }

.block-price-over {
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -75px;
  width: 150px;
  height: 40px;
  border-radius: 20px;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  line-height: 40px; }

.block-ribbon {
  position: absolute;
  top: -18px;
  z-index: 100;
  width: 64px;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 600; }

.block-ribbon-left {
  left: 15px; }

.block-ribbon-right {
  right: 15px; }

.block-caption-half-over {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  padding: 30px 40px; }

.block-caption-half-over--left {
  left: 0; }

.block-caption-half-over--right {
  right: 0; }

.block-image-holder > .block-image img {
  max-width: 100%;
  border-radius: 0.25rem; }

.block-image-holder .block-info.block-info-over {
  overflow: hidden;
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  padding: 15px 0;
  background: #FFF;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0 0 0.25rem 0.25rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.block-image-holder .block-info.block-info-over > .block-info-inner {
  padding: 20px;
  width: 100%;
  align-self: center; }

.block-image-holder:hover .block-info.block-info-over--animated {
  height: 80px; }

.block-image-holder .block-info.block-info-over--animated:hover {
  height: 100%;
  border-radius: 0.25rem; }

.block-image-holder .block-info.block-info-over > .block-info-inner > .block-info-hidden-content {
  display: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.block-image-holder .block-info.block-info-over--animated:hover > .block-info-inner > .block-info-hidden-content {
  display: block;
  opacity: 1; }

.block-image-holder .block-info-table {
  width: 100%;
  margin: 20px 0 0;
  color: #2b2b2c;
  font-size: 0.7em; }

.block-image-holder .block-info-over.block-info-over .heading {
  margin: 0;
  color: #2b2b2c; }

.block-image-holder .block-info-over.block-info-over .heading > a {
  color: #2b2b2c; }

.block-image-holder .block-info-over.block-info-over:hover .info-title > a {
  color: #2b2b2c; }

.block-image-holder .block-info-over.block-info-over--style-2 {
  height: 60px;
  width: calc(100% - 1.6rem);
  margin: 0.8rem;
  background: #FFF;
  border-radius: 0.25rem; }

.block-image-holder .block-info-over--style-2.block-info-over--animated:hover {
  height: calc(100% - 1.6rem); }

.block-image-holder .block-info-over.block-info-over--style-3 {
  height: 80px;
  width: 100%;
  background: #FFF;
  background: rgba(255, 255, 255, 0.8); }

.block-image-holder .block-info-over.block-info-over--dark {
  background: rgba(0, 0, 0, 0.8); }

.block-image-holder .block-info-over.block-info-over--dark .heading,
.block-image-holder .block-info-over.block-info-over--dark .heading > a {
  color: #eceeef; }

.block-image-holder .block-info-over.block-info-over--dark:hover .heading,
.block-image-holder .block-info-over.block-info-over--dark:hover .heading > a {
  color: white; }

.block-image-holder .block-info-over--dark .block-info-table tr {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.1); }

.block-image-holder .block-info-over--dark .block-info-table td {
  color: #eceeef;
  padding: 8px 0;
  border: 0; }

.block-image-holder .block-info {
  padding: 1rem 0 !important;
  width: 100%;
  position: absolute;
  bottom: 30px;
  left: 0;
  z-index: 3;
  background: transparent; }

.block-image-holder .image-mask {
  background-color: #282B30;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0.45;
  transition: opacity 0.3s ease 0s; }

.block-image-holder:hover .image-mask {
  opacity: 0; }

.block-image-holder:hover .mask {
  opacity: 0; }

.block.block--style-1 .block-subtitle {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 15px;
  padding: 0;
  text-transform: none;
  color: #818a91; }

.block.block--style-1 img {
  max-width: 100%; }

.block.block--style-1 .meta-tags a {
  display: inline-block;
  color: #007aff; }

.block.block--style-1.grid .block-image {
  position: relative; }

.block.block--style-1.grid .block-body {
  padding: 1.5rem 0; }

.block.block--style-1.grid .image-title {
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 6px 8px;
  font-weight: 500; }

.block.block--style-1.grid .block-category {
  font-size: 11px;
  color: #007aff;
  text-transform: uppercase; }

.block.block--style-1.list {
  display: flex; }

.block.block--style-1.list .block-image {
  max-width: 40%; }

.block.block--style-1.list .block-image-sm {
  max-width: 25%; }

.block.block--style-1.list .block-header {
  padding: 10px 15px;
  font-weight: 500;
  text-transform: uppercase; }

.block.block--style-1.list .block-content {
  display: table-cell;
  vertical-align: top;
  position: relative; }

.block.block--style-1.list .block-body {
  padding: 0 0 0 2rem; }

.block.block--style-1.list .block-content .block-body {
  padding-bottom: 15px; }

.block.block--style-1.list .block-label {
  display: inline-block;
  padding: 6px 8px;
  font-size: 0.875rem;
  font-weight: 500; }

.block.block--style-1.list .info {
  display: block;
  margin-bottom: 4px;
  font-size: 11px;
  text-transform: uppercase;
  color: #818a91; }

.block.block--style-1.list .block-footer {
  padding: 0.5rem 2rem;
  display: table;
  width: 100%; }

.block.block--style-1.list .block-footer-fixed-bottom {
  position: absolute;
  bottom: 0; }

.block.block--style-1.list .block-footer .meta-info span {
  float: left;
  margin-right: 8px;
  font-size: 11px; }

.block.block--style-1.list .block-footer .meta-info span i {
  margin-right: 4px;
  font-size: 13px; }

.block.block--style-1.list .image-title {
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 10px;
  font-size: 1rem;
  font-weight: 500; }

@media (max-width: 767px) {
  .block.block--style-1.list {
    display: block; }

  .block.block--style-1.list .block-image {
    display: block;
    max-width: 100%;
    position: relative;
    margin-bottom: 1rem; }

  .block.block--style-1.list .block-body {
    display: block;
    padding: 1rem 2rem; }

  .block.block--style-1.list .block-footer-fixed-bottom {
    position: relative; } }
.block--style-2 {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px; }

.block--style-2 .heading {
  margin-bottom: 8px; }

.block--style-2 .block-image {
  max-width: 45%;
  position: relative;
  padding: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  overflow: hidden; }

.block--style-2 .block-image-lg {
  width: 48%; }

.block--style-2 .block-image img {
  width: 100%; }

.block--style-2 .block-body {
  display: table-cell;
  vertical-align: top;
  padding: 1.5rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.block--style-2:hover .mask {
  opacity: 0; }

.block--style-2.v1 .block-image {
  width: 50%; }

.block--style-2 .block-image-over {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%); }

.block--style-2.grid .block-image {
  display: block;
  max-width: 100%;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.block--style-2.grid {
  display: block; }

.block--style-2.grid .block-body {
  display: block;
  border-radius: 0.25rem; }

.block--style-2.grid .block-body-over {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 1rem; }

.row-no-padding .block--style-2 {
  border-radius: 0; }

.row-no-padding .block--style-2 .block-image {
  border-radius: 0; }

.row-no-padding .block--style-2 .block-body {
  border-radius: 0; }

@media (max-width: 767px) {
  .block--style-2 {
    display: block; }

  .block--style-2 .block-image {
    max-width: 100%; }

  .block--style-2 .block-body {
    display: block; } }
.block--style-3 {
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.125);
  background: #fff; }

.block--style-3 img {
  max-width: 100%;
  border-radius: 0.25rem 0.25rem 0 0; }

.block--style-3 .heading {
  margin-bottom: 10px; }

.block--style-3 .block-footer {
  padding: 1.5rem 1.5rem; }

.block--style-3 .block-footer h3 {
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: capitalize; }

.block--style-3.block-fixed-width {
  width: 500px; }

.text-xs-center .block--style-3.block-fixed-width {
  margin: auto; }

@media (max-width: 767px) {
  .block--style-3.block-fixed-width {
    width: 90%; } }
.block--style-3 .block-quote-bullet {
  position: absolute;
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  left: 50%;
  top: -23px;
  margin-left: -23px;
  background: #fff;
  font-size: 20px;
  border-radius: 100%; }

.block--style-3 .block-price-text {
  margin-left: 5px;
  color: #818a91;
  font-size: 0.7em; }

.block--style-3 .block-price {
  font-size: 22px;
  color: #007aff;
  font-weight: 600; }

.block--style-3 .aux-info-wrapper {
  position: relative; }

.block--style-3 ul.aux-info {
  margin: 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center; }

.block--style-3 ul.aux-info li {
  flex-grow: 1;
  margin: 0;
  padding: 14px 15px;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 0.75rem;
  position: relative;
  font-family: "Montserrat", sans-serif; }

.block--style-3 ul.aux-info li:last-child {
  border: 0; }

.block--style-3 ul.aux-info li i {
  font-size: 14px;
  margin-right: 8px; }

.block--style-3 ul.aux-info--style-2 li i {
  font-size: 18px;
  margin-right: 0; }

.block--style-3 ul.aux-info--over {
  background: #fff;
  display: table;
  position: absolute;
  bottom: 10px;
  width: 96%;
  left: 2%;
  border-radius: 2px; }

.block--style-3-v2 {
  border: 0;
  background: transparent; }

.block--style-3-v2 img {
  border-radius: 0.25rem; }

.block--style-3-v2 .block-body {
  padding: 1rem 0; }

.block--style-3-v2 .block-body > p {
  margin: 0; }

.block--style-3-v2 .block-footer {
  padding-left: 0;
  padding-right: 0; }

.block--style-3 .block-author {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: -25px;
  right: 30px;
  border-radius: 100%;
  border: 2px solid #fff; }

.block--style-3 .block-author img {
  border-radius: 100%; }

.block--style-3.list .block-image {
  display: table-cell;
  vertical-align: middle;
  width: 250px;
  position: relative;
  padding: 15px; }

.block--style-3.list .block-image-sm {
  width: 180px; }

.block--style-3.list .block-image .btn {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0; }

.block--style-3.list .block-title-wrapper {
  display: table-cell;
  vertical-align: top;
  padding: 20px 20px 20px 25px;
  border-left: 1px solid rgba(0, 0, 0, 0.125); }

.block--style-3.list .block-body {
  display: table-cell;
  vertical-align: top;
  padding: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

@media (max-width: 767px) {
  .block--style-3.list .block-image {
    display: block;
    width: 100%; }

  .block--style-3.list .block-title-wrapper {
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.125); }

  .block--style-3.list .block-body {
    display: block;
    padding: 20px; } }
.block--style-3.list .block-footer {
  padding: 12px 20px; }

.block--style-4 .info-author:after,
.block--style-4 .info-author:before {
  content: "";
  display: table; }

.block--style-4 .info-author:after {
  clear: both; }

.block--style-4 .block-body {
  padding: 2rem; }

.block--style-4 .info-author .author-img {
  width: 75px;
  height: 75px;
  display: table-cell;
  margin: 0;
  padding: 0; }

.block--style-4 .info-author .author-info {
  height: 75px;
  display: table-cell;
  padding-left: 15px;
  vertical-align: middle;
  text-align: left;
  border-bottom-left-radius: 0.25rem; }

.block--style-4 .info-author .author-info .author-name {
  display: block;
  font-style: normal;
  font-weight: 500;
  color: #2b2b2c; }

.block--style-4 .info-author .author-info .author-pos {
  display: block;
  font-size: 85%; }

.block--style-4-v1 {
  position: relative;
  margin-bottom: 34px; }

.block--style-4-v1 .block-body {
  padding: 2rem; }

.block--style-4-v1 .block-body p {
  padding: 0 20px; }

.block--style-4-v1 .info-author {
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: -32px;
  margin-left: -32px;
  border-radius: 100%; }

.block--style-4-v1 .info-author .author-img img {
  width: 100%;
  border-radius: 100%; }

.block--style-4-v1 .block-body span.quote-icon {
  display: block;
  text-align: center;
  margin: 20px 0; }

.block--style-4-v1 .block-body span.quote-icon > i {
  font-size: 26px; }

.block--style-4-v2 .block-body {
  position: relative;
  border-radius: 0.25rem; }

.block--style-4-v2 .block-body:after {
  top: 100%;
  left: 32px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: 10px;
  margin-left: -10px; }

.block--style-4-v2 .block-body > p {
  margin-bottom: 0; }

.block--style-4-v2 .block-body .quote {
  display: inline-block;
  margin-right: 8px;
  font-size: 24px; }

.block--style-4-v2 .block-body.bg-base-1:after {
  border-top-color: #007aff; }

.block--style-4-v2 .block-body.bg-white:after {
  border-top-color: white; }

.block--style-4-v2 .info-author {
  margin-top: 1.25rem; }

.block--style-4-v2 .info-author .author-img img {
  width: 64px; }

.block--style-4-v2 .style-8-author {
  width: 100%;
  margin-top: 15px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.block--style-5 .block-image img {
  max-width: 100%; }

.block--style-5 .block-title-upper {
  margin: 4px 0;
  padding: 0;
  font-size: 0.75rem;
  font-weight: 400; }

.block--style-5 .block-info-text {
  margin-top: 15px;
  margin-bottom: 0; }

.block--style-5 .block-caption--over {
  position: absolute;
  width: 100%;
  height: 80px;
  bottom: 0;
  left: 0;
  padding: 15px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.9);
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out; }

.block--style-5:hover .block-caption--over {
  height: 130px; }

.block--style-5 .block-mask-caption--over {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: transparent;
  color: #eceeef;
  bottom: 0;
  left: 0;
  padding: 15px;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out; }

.block--style-5:hover .block-mask-caption--over {
  opacity: 1;
  background: rgba(43, 43, 44, 0.7); }

.block--style-5 .block-mask-caption--over .heading,
.block--style-5 .block-mask-caption--over .heading a {
  color: #eceeef; }

.block--style-5 .block-mask-caption--over .heading:hover a {
  color: #eceeef !important; }

.block--style-5 .block-footer {
  padding: 15px 20px; }

.block-rainbow > .block-body {
  padding: 2rem 3rem; }

.block-rainbow > .block-icon {
  padding: 0 3rem; }

.block-rainbow > .block-icon > i {
  font-size: 3.5rem; }

.block-colored {
  border-radius: 0;
  padding: 60px 40px; }

.swiper-slide-next .block-colored {
  z-index: 500;
  padding-bottom: 90px;
  -webkit-box-shadow: -1px 7px 20px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -1px 7px 20px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -1px 7px 20px 0 rgba(0, 0, 0, 0.1); }

.block-on-bg {
  position: relative;
  height: 100%;
  padding: 8rem 3rem;
  margin: 0;
  border-radius: 0; }

.block-on-bg:hover {
  background: rgba(0, 122, 255, 0.5); }

@media (max-width: 767px) {
  .block-on-bg {
    padding: 3rem 3rem; } }
.block-arrow-left:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 16px;
  margin-top: -16px;
  z-index: 100; }

.block-arrow-right:after {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 16px;
  margin-top: -16px;
  z-index: 100; }

.articles-wrapper {
  padding: 15px; }

.block.article {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem; }

.block.article > p {
  margin: 0; }

.block.article .heading {
  margin: 0;
  padding: 0; }

.block.article img {
  max-width: 100%;
  border-radius: 0.25rem; }

.block.article .meta-tags a {
  display: inline-block;
  color: #007aff; }

.block.article .article-meta {
  margin-bottom: 10px; }

.block.article .article-meta .article-labels {
  float: left; }

.block.article .article-meta .article-date {
  float: right;
  font-size: 11px;
  padding: 0.2em 0 0.3em;
  text-transform: uppercase;
  text-align: right;
  color: #818a91; }

.block.article .video-hover-play {
  font-size: 100%; }

.block.article .video-hover-play a {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 122, 255, 0); }

.block.article .video-hover-play a:hover {
  background: rgba(0, 122, 255, 0); }

.block.article .video-hover-play a:before {
  content: "\f144";
  font-family: FontAwesome;
  font-size: 30px;
  color: #FFF;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -23px;
  margin-left: -30px;
  width: 60px;
  height: 46px;
  line-height: 46px;
  background: #007aff;
  border-radius: 0.25rem;
  text-align: center; }

.block.article .video-hover-play a:hover:before {
  color: #FFF;
  background: #007aff; }

.block.article .video-hover-play-sm a:before {
  font-size: 16px;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -15px;
  width: 30px;
  height: 24px;
  line-height: 24px; }

.block.article.grid .block-image {
  position: relative;
  margin-bottom: 1rem; }

.block.article.grid .image-title {
  bottom: 10px; }

.block.article.grid .article-category {
  font-size: 11px;
  color: #007aff;
  text-transform: uppercase; }

.block.article.list {
  display: table;
  width: 100%; }

.block.article.list .block-image {
  display: table-cell;
  position: relative;
  vertical-align: middle; }

.block.article--style-1.list .block-image {
  width: 50%; }

.block.article--style-2.list .block-image {
  width: 30%; }

.block.article.list .block-body {
  display: table-cell;
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0; }

.block.article--style-1.list .block-body.left {
  padding: 0 20px 0 0; }

.block.article--style-1.list .block-body.right {
  padding: 0 0 0 20px; }

.block.article--style-2.list .block-body.left {
  padding: 0 10px 0 0; }

.block.article--style-2.list .block-body.right {
  padding: 0 0 0 10px; }

.block.article.list .block-body p {
  margin-top: 15px; }

.block.article.list .article-label {
  display: inline-block;
  padding: 6px 8px;
  font-weight: 500; }

.block.article.list .title {
  margin-bottom: 10px; }

.block.article.list .article-info {
  display: block;
  margin-bottom: 4px;
  font-size: 11px;
  text-transform: uppercase;
  color: #818a91; }

.block.article--style-1.list .article-text {
  position: relative;
  padding-bottom: 25px; }

.block.article.list .article-text:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 1px;
  background: #f1f1f1; }

.block.article.list .article-title-text {
  margin: 0 !important;
  line-height: 18px; }

.block.article.list .block-footer {
  display: table;
  width: 100%; }

.block.article.list .block-footer .meta-info span {
  float: left;
  margin-right: 8px;
  font-size: 11px; }

.block.article.list .block-footer .meta-info span i {
  margin-right: 4px;
  font-size: 13px; }

.block.article.list .image-title {
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 10px;
  font-weight: 500; }

.block.article.list .article-category {
  font-size: 11px;
  color: #007aff;
  text-transform: uppercase; }

@media (max-width: 767px) {
  .block.article.list .block-image {
    display: block;
    width: 100% !important; }

  .block.article.list .article-text {
    padding-bottom: 0; }

  .block.article.list .block-body {
    display: block;
    padding: 0 !important; }

  .block.article.list .block-body.left {
    margin-bottom: 20px; }

  .block.article.list .block-body.right {
    margin-top: 20px; } }
.block.product {
  background: #fff;
  padding: 1px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  overflow: hidden; }

.block.product .figure {
  padding: 1px; }

.block.product .figure img {
  width: 100% !important; }

.block.product .product-title {
  margin: 10px 0;
  padding: 0;
  border-bottom: 0;
  line-height: 0.9; }

.block.product .product-title.heading {
  line-height: 1; }

.block.product .product-title a {
  font-size: 1rem;
  color: #2b2b2c; }

.block.product .product-title a:hover {
  color: #007aff; }

.block.product figure {
  padding-bottom: 1px;
  border-bottom: 1px solid #f1f1f1; }

.block.product .block-content {
  padding: 10px; }

.block.product p {
  margin: 5px 0; }

.block.product .price-wrapper {
  margin-top: 4px; }

.block.product .price {
  padding: 4px 0;
  font-size: 1.5rem;
  font-weight: 600; }

.block.product .price.discount {
  padding: 7px 0 0;
  margin-right: 4px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #ff3b30 !important;
  text-decoration: line-through; }

.block.product .block-footer {
  border-top: 1px solid #f1f1f1;
  padding-top: 8px;
  margin-top: 10px; }

.block.product .block-footer:after {
  display: table;
  content: "";
  clear: both; }

.block-post .block-post-body {
  padding: 0; }

.block-post .block-post-body > .heading {
  margin-bottom: 0 !important;
  margin-top: 3rem !important; }

.block-post > .block-post-body > p {
  margin-top: 2rem !important;
  color: #818a91; }

.block-post > .block-post-body > .tagcloud {
  margin-top: 2rem; }

.block-post-share {
  margin-top: 2rem;
  padding: 2rem 0;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1; }

.block-post-comment-action {
  padding: 2rem 0;
  border-bottom: 1px solid #f1f1f1; }

.block-post-comment-action .heading {
  margin-bottom: 0; }

.block-post-comments {
  margin-top: 2rem; }

.block-comment {
  display: table;
  width: 100%;
  margin-bottom: 2rem !important; }

@media (min-width: 768px) {
  .block-comment-reply {
    padding-left: 72px; } }
.block-comment .block-image {
  display: table-cell;
  width: 72px;
  height: 72px; }

.block-comment .block-body {
  display: table-cell;
  vertical-align: top;
  position: relative;
  padding: 0 0 0 2rem; }

.block-comment .block-body > .block-body-inner {
  padding-bottom: 2rem;
  border-bottom: 1px solid #f1f1f1; }

.block-comment:last-child .block-body > .block-body-inner {
  border: 0; }

.block-comment:last-child {
  border-bottom: 1px solid #f1f1f1; }

.block-comment .block-body .comment-date {
  font-size: 0.875rem;
  color: #818a91; }

.block-comment .block-body .comment-text {
  margin-top: 1rem; }

.block-comment .block-body .comment-options > a {
  font-size: 0.875rem;
  color: #818a91;
  margin-right: 0.75rem; }

.block-comment .block-body .comment-options > a:hover {
  color: #007aff; }

.post-prev-next {
  margin: 2rem 0;
  padding: 2rem 0;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1; }

.post-prev-next h5 {
  padding: 0;
  margin: 0;
  color: #818a91; }

.post-prev-next h3 {
  padding: 0;
  margin: 5px 0 0; }

.post-prev-next .post-next,
.post-prev-next .post-prev {
  position: relative; }

.post-prev-next .post-prev {
  padding: 0 20px 0 40px; }

.post-prev-next .post-next {
  text-align: right;
  padding-right: 40px; }

.post-prev-next .post-next:before,
.post-prev-next .post-prev:before {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  font-family: "Ionicons";
  font-size: 20px;
  color: #2b2b2c; }

.post-prev-next .post-prev:before {
  content: "\f108";
  left: 0; }

.post-prev-next .post-next:before {
  content: "\f10b";
  right: 0; }

@media (max-width: 767px) {
  .post-prev-next .post-next {
    margin-top: 20px; } }
.btn,
.btn-link {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  padding: 13px 31px;
  font-style: normal;
  text-align: center;
  vertical-align: middle;
  margin: 0;
  cursor: pointer;
  background-image: none;
  border: 1px solid;
  border-radius: 2px;
  outline: none;
  -webkit-text-stroke: 0;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none; }

@media (max-width: 569px) {
  .btn-link:not(input),
  .btn:not(input) {
    -webkit-transform-origin: left;
    -moz-transform-origin: left;
    -o-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left; } }
.btn-icon {
  padding: 0.875rem; }

.btn-xl {
  font-size: 1.125rem !important;
  padding: 1rem 3rem !important; }

.btn-lg {
  font-size: 0.9rem !important;
  padding: 1rem 2.5rem !important; }

.btn-sm {
  font-size: 0.625rem !important;
  padding: 0.625rem 1.5rem !important; }

.btn-xs {
  font-size: 0.5rem !important;
  padding: 0.5rem 1.2rem !important; }

@media (max-width: 767px) {
  .btn-xs-block {
    width: 100%; } }
.btn .fa,
.btn .icon,
.btn-link .fa,
.btn-link .icon {
  position: relative;
  vertical-align: middle;
  line-height: inherit; }

.btn-link.btn-border-animated,
.btn.btn-border-animated {
  position: relative; }

.btn-link.btn-border-animated:after,
.btn.btn-border-animated:after {
  content: '';
  position: absolute;
  border: 1px solid;
  border-radius: inherit;
  border-color: inherit;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px; }

.btn-icon-left .fa,
.btn-icon-left .icon {
  margin-right: 0.625rem; }

.btn-icon-right .fa,
.btn-icon-right .icon {
  margin-left: 0.625rem; }

.btn-outline {
  border-width: 1px !important; }

.btn-icon-only {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  letter-spacing: 0;
  outline: 0; }

.btn-icon-only:active,
.btn-icon-only:hover {
  outline: 0; }

.btn-icon-only.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px; }

.btn-icon-only.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px; }

.btn-square {
  border-radius: 0px !important; }

.btn-round {
  border-radius: 8px !important; }

.btn-circle {
  border-radius: 10rem !important; }

.btn-shadow {
  -webkit-box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.8) !important;
  -moz-box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.8) !important;
  box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.8) !important; }

.btn-italic {
  font-style: italic !important; }

.btn-disable-hover {
  pointer-events: none !important; }

@media (max-width: 767px) {
  .btn-container {
    margin-top: 2rem; } }
@media (max-width: 991px) {
  .btn-container > .btn {
    margin-bottom: 1rem; } }
@media (min-width: 992px) {
  .btn-container > .btn {
    margin-right: 2rem; }

  .btn-container > .btn.btn-border-animated {
    margin-right: 3rem; }

  .btn-container > .btn:last-child {
    margin-right: 0; } }
@-webkit-keyframes btn-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0; } }
@keyframes btn-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0; } }
.btn-ripple-out:after {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s; }

.btn-ripple-out:active:after,
.btn-ripple-out:focus:after,
.btn-ripple-out:hover:after {
  -webkit-animation-name: btn-ripple-out;
  animation-name: btn-ripple-out; }

@-webkit-keyframes btn-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1; } }
@keyframes btn-ripple-in {
  100% {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    opacity: 1; } }
.btn-ripple-in:hover:after {
  top: -12px;
  right: -12px;
  bottom: -12px;
  left: -12px;
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s; }

.btn-ripple-in:active:after,
.btn-ripple-in:focus:after,
.btn-ripple-in:hover:after {
  -webkit-animation-name: btn-ripple-in;
  animation-name: btn-ripple-in; }

.btn-transparent {
  color: #ffffff !important;
  background-color: transparent !important;
  border-color: transparent !important; }

.btn-transparent:active,
.btn-transparent:not(.btn-hover-nobg):focus,
.btn-transparent:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: transparent !important;
  color: transparent !important; }

.btn-base-1 {
  color: #FFF;
  background-color: #007aff;
  border: 1px solid #007aff; }

.btn-base-1:active,
.btn-base-1:not(.btn-hover-nobg).active,
.btn-base-1:not(.btn-hover-nobg):focus,
.btn-base-1:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #007aff !important;
  color: #007aff !important; }

.btn-base-1.btn-outline {
  background-color: transparent !important;
  border-color: #007aff !important;
  color: #007aff !important; }

.btn-base-1.btn-outline:active,
.btn-base-1.btn-outline:not(.btn-hover-nobg):focus,
.btn-base-1.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #007aff !important;
  border-color: #007aff !important;
  color: #FFF !important; }

.btn-base-2 {
  color: #FFF;
  background-color: #007aff;
  border: 1px solid #007aff; }

.btn-base-2:active,
.btn-base-2:not(.btn-hover-nobg).active,
.btn-base-2:not(.btn-hover-nobg):focus,
.btn-base-2:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #007aff !important;
  color: #007aff !important; }

.btn-base-2.btn-outline {
  background-color: transparent !important;
  border-color: #007aff !important;
  color: #007aff !important; }

.btn-base-2.btn-outline:active,
.btn-base-2.btn-outline:not(.btn-hover-nobg):focus,
.btn-base-2.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #007aff !important;
  border-color: #007aff !important;
  color: #FFF !important; }

.btn-base-3 {
  color: rgba(255, 255, 255, 0.9);
  background-color: #818a91;
  border: 1px solid #818a91; }

.btn-base-3:active,
.btn-base-3:not(.btn-hover-nobg).active,
.btn-base-3:not(.btn-hover-nobg):focus,
.btn-base-3:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #818a91 !important;
  color: #818a91 !important; }

.btn-base-3.btn-outline {
  background-color: transparent !important;
  border-color: #818a91 !important;
  color: #818a91 !important; }

.btn-base-3.btn-outline:active,
.btn-base-3.btn-outline:not(.btn-hover-nobg):focus,
.btn-base-3.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #818a91 !important;
  border-color: #818a91 !important;
  color: rgba(255, 255, 255, 0.9) !important; }

.btn-base-4 {
  color: #FFF;
  background-color: #1d2125;
  border: 1px solid #1d2125; }

.btn-base-4:active,
.btn-base-4:not(.btn-hover-nobg).active,
.btn-base-4:not(.btn-hover-nobg):focus,
.btn-base-4:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #1d2125 !important;
  color: #1d2125 !important; }

.btn-base-4.btn-outline {
  background-color: transparent !important;
  border-color: #1d2125 !important;
  color: #1d2125 !important; }

.btn-base-4.btn-outline:active,
.btn-base-4.btn-outline:not(.btn-hover-nobg):focus,
.btn-base-4.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #1d2125 !important;
  border-color: #1d2125 !important;
  color: #FFF !important; }

.btn-base-5 {
  color: #0A0814;
  background-color: #FFF;
  border: 1px solid #FFF; }

.btn-base-5:active,
.btn-base-5:not(.btn-hover-nobg).active,
.btn-base-5:not(.btn-hover-nobg):focus,
.btn-base-5:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #FFF !important;
  color: #FFF !important; }

.btn-base-5.btn-outline {
  background-color: transparent !important;
  border-color: #FFF !important;
  color: #FFF !important; }

.btn-base-5.btn-outline:active,
.btn-base-5.btn-outline:not(.btn-hover-nobg):focus,
.btn-base-5.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #FFF !important;
  border-color: #FFF !important;
  color: #0A0814 !important; }

.btn-white {
  color: #333 !important;
  background-color: white !important;
  border-color: white !important; }

.btn-white:active,
.btn-white:not(.btn-hover-nobg):focus,
.btn-white:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: white !important;
  color: white !important; }

.btn-white.btn-outline {
  background-color: transparent !important;
  border-color: white !important;
  color: white !important; }

.btn-white.btn-outline:active,
.btn-white.btn-outline:not(.btn-hover-nobg):focus,
.btn-white.btn-outline:not(.btn-hover-nobg):hover {
  background-color: white !important;
  border-color: white !important;
  color: #333 !important; }

.btn-blue {
  color: #FFF !important;
  background-color: #007aff !important;
  border-color: #007aff !important; }

.btn-blue:active,
.btn-blue:not(.btn-hover-nobg):focus,
.btn-blue:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #007aff !important;
  color: #007aff !important; }

.btn-blue.btn-outline {
  background-color: transparent !important;
  border-color: #007aff !important;
  color: #007aff !important; }

.btn-blue.btn-outline:active,
.btn-blue.btn-outline:not(.btn-hover-nobg):focus,
.btn-blue.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #007aff !important;
  border-color: #007aff !important;
  color: #007aff !important; }

.btn-green {
  color: #FFF !important;
  background-color: #4cd964 !important;
  border-color: #4cd964 !important; }

.btn-green:active,
.btn-green:not(.btn-hover-nobg):focus,
.btn-green:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #4cd964 !important;
  color: #4cd964 !important; }

.btn-green.btn-outline {
  background-color: transparent !important;
  border-color: #4cd964 !important;
  color: #4cd964 !important; }

.btn-green.btn-outline:active,
.btn-green.btn-outline:not(.btn-hover-nobg):focus,
.btn-green.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #4cd964 !important;
  border-color: #4cd964 !important;
  color: #4cd964 !important; }

.btn-red {
  color: #FFF !important;
  background-color: #ff3b30 !important;
  border-color: #ff3b30 !important; }

.btn-red:active,
.btn-red:not(.btn-hover-nobg):focus,
.btn-red:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #ff3b30 !important;
  color: #ff3b30 !important; }

.btn-red.btn-outline {
  background-color: transparent !important;
  border-color: #ff3b30 !important;
  color: #ff3b30 !important; }

.btn-red.btn-outline:active,
.btn-red.btn-outline:not(.btn-hover-nobg):focus,
.btn-red.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #ff3b30 !important;
  border-color: #ff3b30 !important;
  color: #ff3b30 !important; }

.btn-pink {
  color: #FFF !important;
  background-color: #ff2d55 !important;
  border-color: #ff2d55 !important; }

.btn-pink:active,
.btn-pink:not(.btn-hover-nobg):focus,
.btn-pink:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #ff2d55 !important;
  color: #ff2d55 !important; }

.btn-pink.btn-outline {
  background-color: transparent !important;
  border-color: #ff2d55 !important;
  color: #ff2d55 !important; }

.btn-pink.btn-outline:active,
.btn-pink.btn-outline:not(.btn-hover-nobg):focus,
.btn-pink.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #ff2d55 !important;
  border-color: #ff2d55 !important;
  color: #ff2d55 !important; }

.btn-orange {
  color: #FFF !important;
  background-color: #ff9500 !important;
  border-color: #ff9500 !important; }

.btn-orange:active,
.btn-orange:not(.btn-hover-nobg):focus,
.btn-orange:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #ff9500 !important;
  color: #ff9500 !important; }

.btn-orange.btn-outline {
  background-color: transparent !important;
  border-color: #ff9500 !important;
  color: #ff9500 !important; }

.btn-orange.btn-outline:active,
.btn-orange.btn-outline:not(.btn-hover-nobg):focus,
.btn-orange.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #ff9500 !important;
  border-color: #ff9500 !important;
  color: #ff9500 !important; }

.btn-yellow {
  color: #FFF !important;
  background-color: #ffcc00 !important;
  border-color: #ffcc00 !important; }

.btn-yellow:active,
.btn-yellow:not(.btn-hover-nobg):focus,
.btn-yellow:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #ffcc00 !important;
  color: #ffcc00 !important; }

.btn-yellow.btn-outline {
  background-color: transparent !important;
  border-color: #ffcc00 !important;
  color: #ffcc00 !important; }

.btn-yellow.btn-outline:active,
.btn-yellow.btn-outline:not(.btn-hover-nobg):focus,
.btn-yellow.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #ffcc00 !important;
  border-color: #ffcc00 !important;
  color: #ffcc00 !important; }

.btn-purple {
  color: #FFF !important;
  background-color: #5856d6 !important;
  border-color: #5856d6 !important; }

.btn-purple:active,
.btn-purple:not(.btn-hover-nobg):focus,
.btn-purple:not(.btn-hover-nobg):hover {
  background-color: transparent !important;
  border-color: #5856d6 !important;
  color: #5856d6 !important; }

.btn-purple.btn-outline {
  background-color: transparent !important;
  border-color: #5856d6 !important;
  color: #5856d6 !important; }

.btn-purple.btn-outline:active,
.btn-purple.btn-outline:not(.btn-hover-nobg):focus,
.btn-purple.btn-outline:not(.btn-hover-nobg):hover {
  background-color: #5856d6 !important;
  border-color: #5856d6 !important;
  color: #5856d6 !important; }

.btn-color-470604 {
  color: #ffffff !important;
  background: linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -o-linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -ms-linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -moz-linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -webkit-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -o-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -ms-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -moz-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -webkit-linear-gradient(left, #e9168c 2%, #f75254 99%); }

.btn-color-470604:active,
.btn-color-470604:not(.btn-hover-nobg):focus,
.btn-color-470604:not(.btn-hover-nobg):hover {
  background: linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -o-linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -ms-linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -moz-linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -webkit-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -o-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -ms-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -moz-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -webkit-linear-gradient(left, #e9168c 2%, #f75254 99%); }

.icon-container {
  display: inline-block;
  width: 100%;
  margin: 18px 0 0; }

.icon-btn {
  cursor: pointer; }

.icon-stack {
  text-align: center;
  border-width: 1px !important; }

.icon-stack.fa-squared {
  border-radius: 0px !important; }

.icon-stack.fa-rounded-btn {
  border-radius: 2px !important; }

.btn-link {
  border: 0 !important;
  background: transparent !important;
  padding: 0; }

/* CAROUSEL - STYLE 1 */
.carousel--style-1 .carousel-control {
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-top: -25px;
  font-size: 20px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  filter: alpha(opacity=80);
  opacity: 0.8;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear; }

.carousel--style-1 .carousel-control.right {
  right: 0;
  left: auto;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.carousel--style-1 .carousel-control.left {
  left: 0;
  right: auto;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.carousel--style-1 .carousel-control:hover {
  width: 70px;
  background: #007aff;
  color: #FFF;
  filter: alpha(opacity=100);
  opacity: 1; }

.carousel--style-1 .carousel-inner .item img {
  width: 100%; }

.carousel--style-1-v1 .carousel-control {
  opacity: 0; }

.carousel--style-1-v1:hover .carousel-control {
  opacity: 1; }

/* COLLAPSE */
.panel {
  -webkit-box-shadow: none;
  box-shadow: none; }

.panel-group {
  margin-bottom: 30px; }

/* COLLAPSE STACKED */
.panel-group.panel-stacked .panel {
  border-radius: 0; }

.panel-group.panel-stacked .panel:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.panel-group.panel-stacked .panel:last-child {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.panel-group.panel-stacked .panel + .panel {
  margin-top: 0;
  border-top: 0; }

/* COLLAPSE SPACED */
.panel-group.panel-spaced .panel {
  border-radius: 0.25rem; }

.panel-group.panel-spaced .panel + .panel {
  margin-top: 15px;
  border-top: 1px solid #f1f1f1; }

/* STYLE 2 & STYLE 3 */
.panel-group--style-1 .panel,
.panel-group--style-2 .panel {
  border: 0;
  background: transparent; }

.panel-group--style-1 .panel-body,
.panel-group--style-2 .panel-body {
  border: 0; }

.panel-group--style-1 .panel-heading,
.panel-group--style-2 .panel-heading {
  color: #333;
  border: none;
  padding: 0 !important; }

.panel-group--style-1 .panel-title,
.panel-group--style-2 .panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
  background: #fff; }

.panel-group--style-2 .panel-title {
  background: #000; }

.panel-group--style-1 .panel-title a,
.panel-group--style-2 .panel-title a {
  padding: 18px 35px 18px 22px;
  display: table;
  width: 100%;
  border: 1px solid #f1f1f1;
  color: #000;
  font-size: 0.875rem;
  font-weight: 500;
  position: relative; }

.panel-group--style-2 .panel-title a {
  border: none;
  color: #fff; }

.panel-group--style-1 .panel-title a span,
.panel-group--style-2 .panel-title a span {
  position: absolute;
  top: 22px;
  right: 17px;
  color: #ccc; }

/* STYLE 4 */
.panel-group--style-3 .panel-default > .panel-heading,
.panel-group--style-4 .panel-default > .panel-heading {
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0; }

.panel-group--style-4 .panel-default > .panel-heading {
  border: 1px solid #f1f1f1;
  padding: 0 20px 0 0; }

.panel-group--style-3.panel-group .panel,
.panel-group--style-4.panel-group .panel {
  margin-bottom: 1px;
  border-radius: 0;
  background-color: transparent; }

.panel-group--style-3 .panel:first-child {
  border-top: 1px solid #f1f1f1; }

.panel-group--style-3.panel-group .panel {
  border-bottom: 1px solid #f1f1f1; }

.panel-group--style-3 .panel-title,
.panel-group--style-4 .panel-title {
  overflow: hidden; }

.panel-group--style-3 .panel-title a,
.panel-group--style-4 .panel-title a {
  padding: 1rem 0;
  font-weight: 500;
  font-size: 0.875rem;
  display: table;
  width: 100%;
  position: relative;
  overflow: hidden; }

.panel-group--style-4 .panel-title a {
  padding-left: 70px; }

.panel-group--style-4 .panel-title a > .collapse-heading-icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 54px;
  height: 100%;
  text-align: center;
  line-height: 54px;
  border-right: 1px solid #f1f1f1; }

.panel-group--style-4 .panel-title a > .collapse-heading-icon > i {
  font-size: 28px;
  line-height: 54px;
  color: #818a91; }

.panel-group--style-3 .panel-default,
.panel-group--style-4 .panel-default {
  border: none; }

.panel-group--style-3 .panel-default > .panel-heading + .panel-collapse > .panel-body,
.panel-group--style-4 .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border: none;
  padding: 20px 0; }

.panel-group--style-3 .panel-default > .panel-heading + .panel-collapse > .panel-body {
  padding-left: 0; }

.panel-group--style-3 .panel-default > .panel-heading > .panel-title > a[aria-expanded="true"] {
  border-bottom: 1px solid #f1f1f1; }

.panel-group--style-4 .panel-default > .panel-heading > .panel-title > a[aria-expanded="true"] {
  border-bottom: 0; }

.panel-group--style-3 .panel-default > .panel-heading > .panel-title > a[aria-expanded="true"],
.panel-group--style-4 .panel-default > .panel-heading > .panel-title > a[aria-expanded="true"] {
  color: #007aff !important;
  text-decoration: none; }

.delimiter {
  display: block;
  margin-top: 20px; }

.delimiter.delimiter--fixed-width {
  width: 130px !important;
  margin: auto; }

.delimiter--style-1 {
  border-bottom: 1px solid #f1f1f1;
  margin: 30px 0;
  position: relative; }

.delimiter--style-1 .delimiter-title {
  width: 200px;
  position: relative;
  top: 25px;
  background: #fff;
  border: 2px solid #f1f1f1;
  color: #818a91;
  margin: auto;
  height: 50px;
  text-align: center;
  z-index: 3;
  cursor: default; }

.delimiter--style-1 .delimiter-title h3 {
  margin: 0;
  padding: 0;
  line-height: 50px;
  font-size: 1rem;
  font-weight: 600; }

.delimiter--style-1 .divider-title-wrapper::before {
  content: "";
  width: 280px;
  height: 50px;
  position: absolute;
  margin-left: -140px;
  left: 50%;
  top: 50%;
  background: #fff;
  z-index: 2; }

.delimiter--style-2 {
  border-bottom: 2px solid #252525;
  position: relative; }

.delimiter--style-2:before {
  content: "";
  width: 160%;
  position: absolute;
  left: -30%;
  top: -4px;
  border-top: 2px solid #f1f1f1; }

.delimiter--style-2 .delimiter-icon {
  position: absolute;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  left: 50%;
  top: -18px;
  margin-left: -18px;
  background: white;
  font-size: 20px; }

.delimiter-left-thick--style-1 {
  border-left: 4px solid #007aff; }

.short-delimiter--style-1 {
  padding: 14px 0;
  display: block;
  position: relative; }

.short-delimiter--style-1:before {
  content: "";
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 40px;
  height: 1px;
  background: #007aff; }

.short-delimiter-light:before {
  background: #818a91; }

.short-delimiter-dark:before {
  background: #2b2b2c; }

.short-delimiter-base-1:before {
  background: #007aff; }

.short-delimiter-thick {
  height: 3px; }

.short-delimiter-sm:before {
  width: 40px; }

.short-delimiter-md:before {
  width: 60px; }

.short-delimiter-lg:before {
  width: 90px; }

.short-delimiter-center.short-delimiter-sm:before {
  left: 50%;
  margin-left: -20px; }

.short-delimiter-center.short-delimiter-md:before {
  left: 50%;
  margin-left: -30px; }

.short-delimiter-center.short-delimiter-lg:before {
  left: 50%;
  margin-left: -45px; }

.dropdown-menu.dropdown-custom {
  z-index: 1000;
  min-width: 180px;
  padding: 5px 0;
  font-size: 0.875rem;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0.2rem; }

.dropdown .dropdown-menu.dropdown-custom {
  margin: 2px 0 0; }

.dropdown.open .dropdown-menu.dropdown-custom {
  opacity: 1; }

.dropdown-menu.dropdown-menu-sm {
  min-width: 100px;
  border: 0.1rem; }

.dropdown-menu.dropdown-menu-lg {
  min-width: 260px;
  border-radius: 0.25rem; }

.dropdown-menu > li > a {
  padding: 0.5rem 1rem; }

.dropdown-custom > a {
  -webkit-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in; }

.dropdown-custom.animated {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease; }

.dropdown-custom.animated.fadeIn {
 /*
* Example of further customisation for the "fadeIn" effect
* Can be used for all effects ie. .dropdown-menu.animated.EFFECTNAME
*/
  -webkit-animation-duration: 0.45s;
  animation-duration: 0.45s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out; }

.icon-block {
  position: relative; }

@media (max-width: 991px) {
  .icon-block {
    margin-bottom: 2rem; } }
.block-icon.circle {
  border-radius: 100%; }

.block-icon.rounded {
  border-radius: 0.25rem; }

.block-bordered-grid {
  border-right: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  padding: 40px;
  border-radius: 0; }

.block-bordered-grid--style-2 {
  border-top-style: dashed;
  border-right-style: dashed; }

@media (max-width: 991px) {
  .block-bordered-grid--style-2 {
    margin-bottom: 0 !important; } }
@media (min-width: 768px) and (max-width: 991px) {
  .block-bordered-grid.col-sm-6:nth-child(2n+2) {
    border-right: 0; }

  .block-bordered-grid.col-sm-4:nth-child(3n+3) {
    border-right: 0; }

  .block-bordered-grid.col-sm-3:nth-child(4n+4) {
    border-right: 0; } }
@media (min-width: 992px) {
  .block-bordered-grid[class*="col-"]:last-child {
    border-right: 0; }

  .block-bordered-grid.col-md-6:nth-child(2n+2) {
    border-right: 0; }

  .block-bordered-grid.col-md-4:nth-child(3n+3) {
    border-right: 0; }

  .block-bordered-grid.col-md-3:nth-child(4n+4) {
    border-right: 0; } }
.icon-block--style-1 {
  position: relative; }

[class^="icon-block--style-1"] {
  position: relative; }

.icon-block--style-1-v1 .block-icon {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 3rem;
  line-height: 1; }

.icon-block--style-1-v1 .block-icon > .icon-number,
.icon-block--style-1-v1 .block-icon i {
  color: #007aff; }

.icon-block--style-1-v1 .block-icon .icon,
.icon-block--style-1-v1 .block-icon .icon-number {
  font-size: 54px;
  line-height: 1; }

.icon-block--style-1-v1 .block-icon.c-white i {
  color: white; }

.icon-block--style-1-v1 .block-content {
  padding-left: 70px; }

.icon-block--style-1-v1.block-icon-sm .block-icon {
  width: 50px;
  height: 50px; }

.icon-block--style-1-v1.block-icon-sm i {
  font-size: 30px; }

.icon-block--style-1-v1.block-icon-sm .block-content {
  padding-left: 70px; }

.icon-block--style-1-v1.block-icon-right .block-icon {
  left: auto;
  right: 0; }

.icon-block--style-1-v1.block-icon-right .block-content {
  padding-right: 70px;
  padding-left: 0;
  text-align: right; }

.icon-block--style-1-v2 .heading {
  margin-bottom: 10px; }

.icon-block--style-1-v2 .block-icon,
.icon-block--style-1-v3 .block-icon {
  width: 70px;
  height: 70px;
  position: absolute;
  left: 0;
  text-align: right; }

.icon-block--style-1-v2 .block-icon i,
.icon-block--style-1-v3 .block-icon i {
  font-size: 50px;
  line-height: 1; }

.icon-block--style-1-v2 .block-icon i {
  color: #2b2b2c; }

.icon-block--style-1-v2 .block-icon .icon-number {
  font-size: 50px;
  line-height: 1;
  display: block;
  text-align: right; }

.icon-block--style-1-v2 .block-icon .icon-number {
  color: #2b2b2c; }

.icon-block--style-1-v2 .block-content {
  padding-left: 90px; }

.icon-block--style-1-v2.block-icon-sm .block-icon {
  width: 50px;
  height: 50px; }

.icon-block--style-1-v2.block-icon-sm i {
  font-size: 30px; }

.icon-block--style-1-v2.block-icon-sm .block-content {
  padding-left: 70px; }

.icon-block--style-1-v2.block-icon-right .block-icon {
  left: auto;
  right: 0;
  text-align: left; }

.icon-block--style-1-v2.block-icon-right .block-content {
  padding-left: 0;
  padding-right: 90px;
  text-align: right; }

.icon-block--style-1-v2.block-icon-right.block-icon-sm .block-content {
  padding-left: 0;
  padding-right: 70px; }

.icon-block--style-1-v3 .block-icon,
.icon-block--style-1-v4 .block-icon {
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 5px; }

.icon-block--style-1-v3 .block-icon {
  border: 1px solid #007aff; }

.icon-block--style-1-v4 .block-icon {
  border: 1px solid #2b2b2c; }

.icon-block--style-1-v3-dashed .block-icon,
.icon-block--style-1-v4-dashed .block-icon {
  border-style: dashed; }

.icon-block--style-1-v3 .block-icon i,
.icon-block--style-1-v4 .block-icon i {
  font-size: 28px;
  line-height: 60px;
  font-weight: normal; }

.icon-block--style-1-v3 .block-icon i {
  color: #007aff; }

.icon-block--style-1-v4 .block-icon i {
  color: #2b2b2c; }

.icon-block--style-1-v3 .block-content,
.icon-block--style-1-v4 .block-content {
  padding-left: 85px; }

.icon-block--style-1.v3 .block-icon i {
  font-size: 15px;
  color: #007aff; }

.icon-block--style-1.v4 .block-icon i {
  font-size: 15px;
  color: #2b2b2c; }

.icon-block--style-1.v3 .block-content,
.icon-block--style-1.v4 .block-content {
  padding-left: 30px; }

.icon-block--style-1-v3.block-icon-right .block-icon,
.icon-block--style-1-v4.block-icon-right .block-icon {
  left: auto;
  right: 0; }

.icon-block--style-1-v3.block-icon-right .block-content,
.icon-block--style-1-v4.block-icon-right .block-content {
  padding-left: 0;
  padding-right: 85px;
  text-align: right; }

.icon-block--style-1-v5 {
  position: relative; }

.icon-block--style-1-v5 .block-icon {
  display: block;
  margin-bottom: 1rem; }

.icon-block--style-1-v5 .block-icon > i {
  font-size: 40px; }

.icon-block--style-1-v5 .block-icon-lg > i {
  font-size: 60px; }

.icon-block--style-1-v5 .block-icon {
  color: #007aff; }

.icon-block--style-1-v5 .block-content p {
  margin-top: 10px; }

.icon-block--style-1-v5.block-bordered-grid-animated {
  padding: 3rem 2rem; }

.icon-block--style-1-v5.block-bordered-grid-animated .block-inner {
  display: block;
  color: #818a91; }

.icon-block--style-1-v5.block-bordered-grid-animated .block-inner::after {
  content: "";
  display: block;
  position: absolute;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background: transparent;
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  transform: scale(0.1); }

.icon-block--style-1-v5.block-bordered-grid-animated:hover .block-inner::after {
  border: 2px solid #007aff;
  transform: scale(1); }

.block.style-5 .block-icon.block-icon-lg i {
  font-size: 60px; }

.icon-block--style-1-v3.icon-block-inverse .block-icon {
  border-color: white; }

.icon-block--style-1-v1.icon-block-inverse .block-icon i,
.icon-block--style-1-v2.icon-block-inverse .block-icon i,
.icon-block--style-1-v3.icon-block-inverse .block-icon i,
.icon-block--style-1-v4.icon-block-inverse .block-icon i,
.icon-block--style-1-v5.icon-block-inverse .block-icon i {
  color: white; }

.icon-block--style-2-v1:hover .block-icon,
.icon-block--style-2-v2:hover .block-icon {
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out; }

.icon-block--style-2-v1 .heading,
.icon-block--style-2-v2 .heading {
  margin-bottom: 0.5rem !important; }

.icon-block--style-2-v1 .block-icon {
  margin: auto;
  width: 64px;
  height: 64px;
  line-height: 64px;
  text-align: center;
  font-size: 32px;
  margin-bottom: 2rem; }

.icon-block--style-2-v2 .block-icon {
  margin-bottom: 2rem; }

.icon-block--style-2-v2 .block-icon {
  margin: auto auto 2rem;
  width: 76px;
  height: 76px;
  line-height: 76px;
  text-align: center;
  font-size: 32px;
  border: 2px solid #818a91;
  color: #55595c; }

.icon-block--style-2-v2.active .block-icon,
.icon-block--style-2-v2:hover .block-icon {
  border-color: #007aff;
  background: #007aff;
  color: #FFF; }

@media (max-width: 767px) {
  .icon-block--style-2-v1,
  .icon-block--style-2-v2 {
    margin-bottom: 5rem; } }
.icon-block--style-3 {
  position: relative; }

.icon-block--style-3 > i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  border-radius: 100%;
  font-size: 20px; }

.icon-block--style-3 > .icon-number {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 10px;
  display: inline-block;
  border-radius: 100%;
  font-size: 17px; }

.icon-block--style-3 .icon-label {
  display: block;
  position: absolute;
  width: 40px;
  left: 0;
  top: 45px;
  font-size: 13px;
  text-align: center; }

.icon-block--style-3 .icon-block-text {
  line-height: 40px;
  display: inline-block;
  font-size: 20px;
  font-family: "Montserrat", sans-serif; }

.icon-block--style-3 .icon-block-subtext {
  position: absolute;
  top: 34px;
  left: 54px; }

.icon-block--style-3-v1 > i {
  border: 1px solid #eceeef;
  background: transparent;
  color: #007aff; }

.icon-block--style-3-v2 > .icon-number,
.icon-block--style-3-v2 > i {
  position: absolute; }

.icon-block--style-3-v2 .icon-block-content {
  padding-left: 55px; }

.icon-block--style-4 {
  overflow: visible;
  position: relative;
  margin-top: 42px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: 0;
  border-radius: 0.25rem; }

.icon-block--style-4::before,
.icon-block--style-4::after {
  content: "";
  width: calc(50% - 52px);
  position: absolute;
  top: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.icon-block--style-4::before {
  left: 0;
  border-top-left-radius: 0.25rem; }

.icon-block--style-4::after {
  right: 0;
  border-top-right-radius: 0.25rem; }

.icon-block--style-4 .block-icon {
  width: 84px;
  height: 84px;
  text-align: center;
  line-height: 84px;
  position: absolute;
  top: -42px;
  left: 50%;
  margin-left: -42px;
  border-radius: 100%;
  background: #007aff;
  color: #FFF; }

.icon-block--style-4:hover .block-icon {
  -webkit-animation-name: rotate-icon;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out; }

.icon-block--style-4 .block-icon::after {
  content: "";
  width: 84px;
  height: 84px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -42px;
  border-radius: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }

.icon-block--style-4:hover .block-icon::after {
  width: 98px;
  height: 98px;
  top: -7px;
  left: 50%;
  margin-left: -49px;
  border: 3px solid #007aff; }

.icon-block--style-4 .block-icon i {
  font-size: 40px; }

.icon-block--style-4 .block-body {
  padding-top: 80px;
  padding-left: 2rem;
  padding-right: 2rem; }

@media (max-width: 991px) {
  .icon-block--style-4 {
    margin-top: 80px; } }
@-webkit-keyframes rotate-icon {
  0% {
    -webkit-transform: scale(1) rotate(0deg); }
  10% {
    -webkit-transform: scale(1) rotate(0deg); }
  100% {
    -webkit-transform: scale(1) rotate(360deg); } }
.label {
  display: inline-block;
  font-weight: 500;
  padding: 0.5em 0.7em 0.5em;
  font-size: 0.625rem;
  line-height: 1.46; }

.label-md {
  padding: 0.6em;
  border-radius: 2px; }

.label a {
  color: #FFF;
  font-weight: 500; }

.badge {
  padding: 4px 7px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 10px; }

.badge > a {
  color: #fff !important; }

.badge-corner:empty {
  display: inline-block; }

.badge-corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 66px solid #888;
  border-top-color: rgba(0, 0, 0, 0.3);
  border-left: 66px solid transparent;
  padding: 0;
  background-color: transparent;
  border-radius: 0; }

.badge-corner span {
  position: absolute;
  top: -52px;
  left: -28px;
  font-size: 16px;
  color: #fff; }

.badge-corner-base-1 {
  border-top-color: #007aff; }

.badge-corner-blue {
  border-top-color: #007aff; }

.badge-corner-green {
  border-top-color: #4cd964; }

.badge-corner-red {
  border-top-color: #ff3b30; }

.badge-corner-orange {
  border-top-color: #ff9500; }

.badge-corner-pink {
  border-top-color: #ff2d55; }

.badge-corner-yellow {
  border-top-color: #ffcc00; }

.badge-corner-purple {
  border-top-color: #5856d6; }

.badge-corner-black {
  border-top-color: #000000; }

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.mask-base-1--style-1 {
  background: rgba(0, 122, 255, 0.9); }

.mask-base-1--style-2 {
  background: rgba(0, 122, 255, 0.5); }

.mask-base-1--style-3 {
  background: rgba(0, 122, 255, 0.7); }

.mask-gradient-1--style-1 {
  opacity: 0.9; }

.mask-gradient-1--style-2 {
  opacity: 0.5; }

.mask-light--style-1 {
  background: rgba(255, 255, 255, 0.9); }

.mask-light--style-2 {
  background: rgba(255, 255, 255, 0.4); }

.mask-dark--style-1 {
  background: rgba(0, 0, 0, 0.9); }

.mask-dark--style-2 {
  background: rgba(0, 0, 0, 0.4); }

.mask-pattern-1--style-1 {
  opacity: 0.5; }

/* NAV PILLS */
.nav-pills {
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.nav-pills > li > a {
  border-radius: 0;
  border-right: 1px solid #ddd;
  background-color: #eee; }

.nav-pills > li:first-child > a {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.nav-pills > li:last-child > a {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-right: 0; }

.nav-pills > li > a:focus,
.nav-pills > li > a:hover {
  background: #007aff;
  color: #2b2b2c; }

.nav-pills > li + li {
  margin-left: 2px; }

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: #2b2b2c;
  background: #007aff; }

.nav-pills--style-2 {
  margin-bottom: 15px;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.nav-pills--style-2 > li > a {
  padding: 26px;
  border-radius: 0;
  border-right: 0;
  background-color: #eee;
  text-transform: uppercase; }

.nav-pills--style-2 > li:first-child > a {
  border-top-left-radius: 0.25rem; }

.nav-pills--style-2 > li:last-child > a {
  border-top-right-radius: 0.25rem;
  border-right: 0; }

.nav-pills--style-2 > li > a:focus,
.nav-pills--style-2 > li > a:hover {
  background: #f5f5f5; }

.nav-pills--style-2 > li + li {
  margin-left: 2px; }

.nav-pills--style-2 > li.active > a,
.nav-pills--style-2 > li.active > a:focus,
.nav-pills--style-2 > li.active > a:hover {
  background: transparent;
  color: inherit; }

.nav-pills--style-3 {
  border: 0; }

.nav-pills--style-3 > li > a:after,
.nav-pills--style-3 > li > a:before {
  display: table;
  content: ""; }

.nav-pills--style-3 > li > a:after {
  clear: both; }

.nav-pills--style-3 > li > a {
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: 20px;
  background: #fff;
  color: #818a91;
  text-align: left;
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  border-radius: 0.25rem; }

.nav-pills--style-3 > li > a:focus,
.nav-pills--style-3 > li > a:hover {
  background: #fff; }

.nav-pills--style-3 > li:last-child > a {
  margin: 0; }

.nav-pills--style-3 > li.active > a,
.nav-pills--style-3 > li.active > a:focus,
.nav-pills--style-3 > li.active > a:hover {
  background: #fff; }

.nav-pills--style-3 .nav-pill-text {
  display: table-cell;
  padding: 1rem;
  vertical-align: middle; }

.nav-pills--style-3 .nav-pill-text p {
  margin: 0; }

.nav-pills--style-3 .nav-pill-text .heading {
  display: block;
  margin: 0;
  padding: 0; }

.nav-pills--style-3 .nav-pill-text .nav-pill-subtitle {
  display: block;
  margin-top: 5px;
  color: #818a91;
  font-size: 0.875rem; }

.nav-pills--style-3 .nav-pill-text .nav-pill-aux-text {
  display: block;
  margin-top: 20px;
  color: #818a91;
  font-size: 0.875rem; }

.nav-pills--style-3 .nav-pill-text .nav-pill-aux-text strong {
  color: #2b2b2c; }

.nav-pills--style-3 .nav-pill-icon {
  width: 60px;
  display: table-cell;
  vertical-align: middle; }

.nav-pills--style-3 .nav-pill-icon i {
  font-size: 50px;
  color: #2b2b2c; }

.nav-pills--style-3 > li.active > a .nav-pill-icon i {
  color: #2b2b2c; }

@media (max-width: 767px) {
  .nav-pills--style-3 > li > a {
    margin-right: 0; } }
.nav-pills--style-3.nav-pills-inverse > li > a {
  border: 1px solid rgba(0, 0, 0, 0.9);
  background: #1b1e23;
  color: #818a91; }

.nav-pills--style-3.nav-pills-inverse > li > a:focus,
.nav-pills--style-3.nav-pills-inverse > li > a:hover {
  background: #1b1e23; }

.nav-pills--style-3.nav-pills-inverse > li.active > a,
.nav-pills--style-3.nav-pills-inverse > li.active > a:focus,
.nav-pills--style-3.nav-pills-inverse > li.active > a:hover {
  background: #1b1e23; }

.pagination-wrapper {
  margin: 1rem 0;
  font-size: 0; }

.pagination {
  margin: 0;
  border-radius: 0; }

.pagination > li > a,
.pagination > li > span {
  padding: 8px 14px;
  margin: 0 3px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  color: #818a91;
  background-color: transparent;
  border: 1px solid #eceeef;
  border-radius: 0.1rem;
  text-align: center !important; }

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #2b2b2c;
  background-color: #eceeef;
  border-color: #eceeef; }

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  color: #FFF;
  background-color: #007aff;
  border-color: #007aff; }

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #818a91;
  background-color: #eceeef;
  border-color: #eceeef; }

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 12px 18px;
  margin: 0 3px;
  font-family: "Montserrat", sans-serif;
  font-size: 0.7rem;
  color: #818a91;
  background-color: transparent;
  border: 1px solid #eceeef; }

.pagination--style-2 > li > a,
.pagination--style-2 > li > span {
  background: #fff;
  color: #818a91; }

.pagination-circle > li > a,
.pagination-circle > li > span {
  border-radius: 50% !important;
  margin: 0 5px;
  display: block;
  width: 36px;
  height: 36px;
  padding: 5px;
  line-height: 2.2; }

.pagination-square > li > a,
.pagination-square > li > span {
  border-radius: 0 !important;
  margin: 0 5px;
  display: block;
  width: 36px;
  height: 36px;
  padding: 5px;
  line-height: 2.2; }

.pager li > a,
.pager li > span {
  background-color: transparent;
  border: 1px solid #eceeef;
  border-radius: 0.2rem; }

.pager li > a:focus,
.pager li > a:hover {
  background-color: #eceeef;
  color: #2b2b2c; }

.pager--style-1 li.pager-count span {
  background: transparent;
  font-style: italic; }

.pricing-plans:after,
.pricing-plans:before,
.pricing-table:after,
.pricing-table:before {
  display: table;
  content: " "; }

.pricing-plans:before,
.pricing-table:before {
  clear: both; }

.pricing-plans .plan-title {
  text-align: center;
  margin: 0;
  padding: 15px 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #2b2b2c; }

.pricing-plans .price-tag {
  margin: 0;
  height: 90px;
  line-height: 90px;
  font-size: 50px;
  font-weight: 700;
  text-align: center; }

.pricing-plans .price-tag span {
  font-size: 28px;
  font-weight: 600; }

.pricing-plans .price-tag span.price-type {
  font-size: 20px;
  font-weight: 500; }

.pricing-plans ul {
  margin: 0;
  padding: 0 15px;
  list-style: none; }

.pricing-plans ul li {
  padding: 10px 0;
  border-bottom: 1px solid #f1f1f1; }

.pricing-plans ul li i {
  margin-right: 8px; }

.pricing-plans .plan-info {
  margin: 0;
  padding: 15px;
  text-align: center; }

.pricing-plans .plan-select {
  padding: 15px;
  border-top: 1px solid #f1f1f1; }

.pricing-plans--style-1 .plan-title {
  text-align: center;
  margin: 0;
  padding-top: 36px;
  font-size: 16px;
  font-weight: 500;
  background: transparent;
  color: #2b2b2c;
  text-transform: normal; }

.pricing-plans--style-1 .price-tag {
  margin: 0;
  padding: 0;
  font-size: 38px;
  height: auto;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  color: #007aff; }

.pricing-plans--style-1 .price-tag span {
  font-size: 28px;
  font-weight: 600; }

.pricing-plans--style-1 .price-tag span.price-type {
  font-size: 20px;
  font-weight: 500; }

.pricing-plans--style-1 .price-tag-subtitle {
  margin-top: 8px;
  font-size: 14px;
  color: #818a91;
  display: block;
  text-align: center; }

.pricing-plans--style-1 ul {
  margin-top: 40px;
  margin-bottom: 20px; }

.pricing-plans--style-1 ul li {
  padding: 10px 0;
  border-bottom: 0;
  text-align: center;
  color: #818a91; }

.pricing-plans--style-1 ul li.active {
  color: #2b2b2c; }

.pricing-plans--style-2 .plan-title {
  text-align: center;
  margin: 0;
  padding: 36px 0;
  font-size: 20px;
  font-weight: 500;
  background: #007aff;
  color: #FFF;
  text-transform: normal; }

.pricing-plans--style-2 .price-tag {
  margin: 0;
  padding: 30px 0 0;
  font-size: 30px;
  height: auto;
  line-height: 1;
  font-weight: 400;
  text-align: center;
  color: #2b2b2c; }

.pricing-plans--style-2 .price-tag span {
  font-size: 28px;
  font-weight: 600; }

.pricing-plans--style-2 .price-tag span.price-type {
  font-size: 20px;
  font-weight: 500; }

.pricing-plans--style-2 .price-tag-subtitle {
  margin-top: 8px;
  font-size: 16px;
  color: #818a91;
  display: block;
  text-align: center; }

.pricing-plans--style-2 ul {
  margin-top: 40px;
  margin-bottom: 20px; }

.pricing-plans--style-2 ul li {
  padding: 10px 0;
  border-bottom: 0;
  text-align: center;
  color: #818a91;
  font-size: 0.875rem; }

.pricing-plans--style-2 ul li.active {
  color: #2b2b2c;
  font-weight: 500; }

.pricing-plans .plan-select {
  padding: 15px;
  background: #f1f1f1;
  border-top: 1px solid #f1f1f1; }

.pricing-plans .block {
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125); }

@media (max-width: 767px) {
  .pricing-plans {
    margin-bottom: 30px; } }
.pricing-plans .block.popular {
  margin-top: -10px; }

.progress-wrapper {
  position: relative;
  padding-top: 34px; }

.progress {
  height: 20px;
  margin-bottom: 15px;
  overflow: hidden;
  border-radius: 0.2rem;
  background-color: #f1f1f1;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }

.progress.progress-lg {
  height: 26px; }

.progress.progress-md {
  height: 16px; }

.progress.progress-sm {
  height: 10px; }

.progress.progress-xs {
  height: 4px; }

.progress .sr-only {
  width: auto;
  height: 20px;
  margin: 0 0 0 30px;
  left: 0;
  clip: auto;
  line-height: 20px;
  font-size: 13px; }

.progress.progress-lg .sr-only {
  height: 26px;
  line-height: 26px; }

.progress.progress-sm .sr-only {
  height: 14px;
  line-height: 14px; }

.progress.progress-xs .sr-only {
  height: 8px;
  line-height: 8px; }

.progress-heading {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 2px;
  padding: 0; }

.progress-bar {
  background-color: #007aff;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0; }

.progress-text {
  margin-bottom: 0; }

.progress-text .mark {
  float: left;
  width: 50%;
  background: transparent; }

.progress-text .mark.right {
  text-align: right; }

.progress-tooltip {
  display: inline-block;
  background: #2b2b2c;
  color: #eceeef;
  padding: 4px 6px;
  line-height: 1;
  font-size: 0.75rem;
  position: relative;
  bottom: 8px;
  border-radius: 3px;
  margin-left: -15px; }

.progress-tooltip:after {
  top: 100%;
  left: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(51, 51, 51, 0);
  border-top-color: #333333;
  border-width: 5px; }

.progress-label {
  display: inline-block;
  color: #818a91;
  position: absolute;
  top: 0;
  font-size: 0.75rem; }

.progress-percentage {
  display: inline-block;
  color: #818a91;
  font-size: 0.875rem;
  position: absolute;
  right: 0;
  top: 0; }

.star-rating {
  display: inline-block; }

.star-rating i {
  display: inline-block;
  color: #ffcc00; }

.rating {
  display: inline-block; }

.rating span.star {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  float: left;
  padding: 0 1px;
  cursor: pointer; }

.rating span.star:before {
  content: "\f005";
  display: block;
  font-size: 14px;
  color: #818a91;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear; }

.rating-lg span.star:before {
  font-size: 18px; }

.rating-xl span.star:before {
  font-size: 24px; }

.rating span.voted:before {
  color: #ffcc00; }

.rating:hover span.star:before {
  color: #818a91; }

.rating:hover span.star.over:before {
  color: #ffcc00; }

.rating > .rating-count {
  font-size: 0.75rem;
  color: #818a91;
  position: relative;
  top: -3px;
  margin-left: 1rem; }

.section-title {
  margin-bottom: 1.5rem;
  position: relative; }

.section-title:after,
.section-title:before {
  content: "";
  display: table; }

.section-title:before {
  content: " ";
  display: table; }

.section-title.text-xs-center,
.section-title.text-sm-center,
.section-title.text-md-center,
.section-title.text-lg-center,
.section-title.text-xl-center {
  margin-bottom: 3rem; }

.section-title .section-title-inner {
  margin: 0 0 5px 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  font-weight: 600;
  text-transform: none;
  position: relative;
  color: #2b2b2c; }

.section-title-inverse .section-title-inner,
.section-title-inverse .heading {
  color: white; }

.section-title .section-title-delimiter {
  display: block;
  position: relative;
  margin-bottom: 20px; }

.section-title .section-title-delimiter::before {
  background: #2b2b2c; }

.section-title-inverse .section-title-delimiter::before {
  background: white; }

.section-title--style-1 .section-title-delimiter::before {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 23px;
  height: 2px; }

.section-title--style-1.text-xs-center .section-title-delimiter::before,
.section-title--style-1.text-sm-center .section-title-delimiter::before,
.section-title--style-1.text-md-center .section-title-delimiter::before,
.section-title--style-1.text-lg-center .section-title-delimiter::before,
.section-title--style-1.text-xl-center .section-title-delimiter::before {
  margin-bottom: -1rem;
  left: 50%;
  margin-left: -17.25px;
  width: 34.5px; }

@media (min-width: 768px) {
  .section-title--style-1.text-sm-left .section-title-delimiter::before {
    left: 0;
    margin-left: 0;
    width: 23px; } }
@media (min-width: 992px) {
  .section-title--style-1.text-md-left .section-title-delimiter::before {
    left: 0;
    margin-left: 0;
    width: 23px; } }
@media (min-width: 1201px) {
  .section-title--style-1.text-lg-left .section-title-delimiter::before {
    left: 0;
    margin-left: 0;
    width: 23px; } }
.section-title--style-1 .text-muted {
  display: block;
  margin: 0.5rem 0;
  text-transform: none; }

.section-title--style-2 {
  position: relative; }

.section-title--style-2 .section-title-inner {
  position: relative;
  cursor: default; }

.section-title--style-2 .section-title-inner:before {
  content: "";
  display: block;
  width: 60px;
  height: 70px;
  position: relative;
  top: 22px;
  background: #fff;
  border: 3px solid;
  margin: auto;
  z-index: 3;
  border-color: #2b2b2c; }

.section-title--style-2.section-title-inverse .section-title-inner:before {
  border-color: #eceeef; }

.section-title--style-2 .section-title-inner > span {
  display: inline-block;
  position: relative;
  top: -30px;
  height: 34px;
  line-height: 34px;
  z-index: 4;
  background: white; }

.section-title--style-2.section-title-inverse .section-title-inner > span {
  background: #2b2b2c; }

.section-title--style-2 .section-title-inner .heading {
  margin: 0; }

.social-media {
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none; }

.social-media > li {
  display: inline-block;
  font-size: 1rem; }

.social-media > li > a {
  display: block; }

[class*="social-media--style-1"] > li > a {
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  margin-right: 0.3rem;
  background: transparent; }

[class*="social-media--style-1"] > li > a:hover {
  background: #007aff;
  color: #FFF; }

.social-media-circle > li > a {
  border-radius: 100%; }

.social-media-round > li > a {
  border-radius: 0.25rem; }

.social-media.social-media--style-1-v1 > li > a {
  background: #007aff;
  color: #FFF; }

.social-media.social-media--style-1-v1 > li > a:hover {
  background: #0062cc; }

.social-media.social-media--style-1-v2 > li > a {
  background: #ddd;
  color: #333; }

.social-media.social-media--style-1-v2 > li > a:hover {
  background: #007aff;
  color: #FFF; }

.social-media.social-media--style-1-v3 > li > a {
  background: #2b2b2c;
  color: #eceeef; }

.social-media.social-media--style-1-v3 > li > a:hover {
  background: #007aff;
  color: #FFF; }

.social-media.social-media--style-1-v4 > li > a {
  font-size: 1rem;
  width: auto;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  margin-right: 1rem;
  background: transparent;
  color: #2b2b2c; }

.social-media.social-media--style-1-v4 > li > a:hover {
  background: transparent;
  color: #007aff; }

.social-media.social-media--style-1-v4 > li:last-child > a {
  margin-right: 0; }

.social-media-brand-color > li > a.facebook,
.social-media-brand-color--hover > li > a.facebook:hover {
  background: #3b5998;
  color: #fff; }

.social-media-brand-color > li > a.twitter,
.social-media-brand-color--hover > li > a.twitter:hover {
  background: #1da1f2;
  color: #fff; }

.social-media-brand-color > li > a.instagram,
.social-media-brand-color--hover > li > a.instagram:hover {
  background: #e1306c;
  color: #fff; }

.social-media-brand-color > li > a.linkedin,
.social-media-brand-color--hover > li > a.linkedin:hover {
  background: #0077b5;
  color: #fff; }

.social-media-brand-color > li > a.pinterest,
.social-media-brand-color--hover > li > a.pinterest:hover {
  background: #bd081c;
  color: #fff; }

.social-media-brand-color > li > a.dribbble,
.social-media-brand-color--hover > li > a.dribbble:hover {
  background: #444444;
  color: #fff; }

.social-media-brand-color > li > a.googleplus,
.social-media-brand-color--hover > li > a.googleplus:hover {
  background: #dd4b39;
  color: #fff; }

.social-media-brand-color > li > a.skype,
.social-media-brand-color--hover > li > a.skype:hover {
  background: #00aff0;
  color: #fff; }

.social-media-brand-color:not(.social-media-brand-color--hover) > li > a:hover {
  background: #007aff;
  color: #FFF; }

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 1px solid #f1f1f1;
  font-size: 0.875rem; }

.table-profile > tbody > tr > td:not(.td-actions) {
  font-size: 0.875rem; }

.table-profile > tbody > tr > td.td-label {
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #818a91;
  text-transform: uppercase;
  white-space: nowrap; }

.table-profile > tbody > tr > td.td-label > span {
  display: block;
  padding-right: 1rem; }

.table-profile > tbody > tr > td.td-actions > .btn {
  opacity: 0; }

.table-profile > tbody > tr:hover > td.td-actions > .btn {
  opacity: 1; }

.table-cart > thead > tr > th {
  padding: 12px 0;
  border: 0;
  font-weight: 400; }

.table-cart > tbody > tr > td,
.table-cart > tbody > tr > th,
.table-cart > tfoot > tr > td,
.table-cart > tfoot > tr > th,
.table-cart > thead > tr > td,
.table-cart > thead > tr > th {
  border-color: #f1f1f1;
  vertical-align: middle; }

.table-cart > tbody > tr > td {
  padding: 1rem 0 !important; }

.table-cart .cart-item-img {
  width: 120px; }

.table-cart .cart-item-img img {
  width: 100%; }

.table-cart .cart-item-content {
  vertical-align: middle;
  width: 70%; }

.table-cart .cart-item-content .cart-item-title {
  font-weight: 500;
  font-size: 1rem;
  color: #2b2b2c; }

.table-cart .cart-item-content .cart-item-title:hover {
  color: #007aff; }

.table-cart .cart-item-content .label-quantity {
  color: #818a91;
  font-weight: 400;
  font-size: 0.875rem; }

.table-cart .cart-item-content .label-value {
  font-weight: 600; }

.table-cart .cart-item-unit-price {
  vertical-align: middle;
  font-size: 18px; }

.table-cart .cart-item-price {
  width: 20%;
  vertical-align: middle;
  text-align: right; }

.table-cart .cart-item-price > .price {
  font-weight: 600;
  font-size: 1.25rem;
  display: block;
  color: #2b2b2c; }

.table-cart .cart-item-price > .price.discount {
  font-weight: 500;
  font-size: 0.875rem;
  text-decoration: line-through;
  color: #ff3b30;
  margin-top: 8px; }

.table-cart .cart-item-price > .price.savings {
  font-weight: 400;
  font-size: 0.75rem; }

.table-cart .cart-item-count {
  vertical-align: middle; }

.table-cart .label-subtotal {
  padding-top: 15px;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase; }

.table-cart .table-cart-footer {
  padding: 15px;
  border-top: 1px solid #f1f1f1; }

.table-cart .cart-items {
  display: block;
  padding: 15px;
  font-size: 14px;
  font-weight: 500;
  background: #eee;
  color: #ccc;
  border-bottom: 1px solid #ccc; }

.dropdown-cart-wrapper {
  min-width: 260px; }

.dropdown-cart {
  min-width: 400px;
  padding: 0 1rem; }

.dropdown-cart .table {
  margin: 0; }

.dropdown-cart-header {
  padding: 1rem;
  border-bottom: 1px solid #f1f1f1; }

.dropdown-cart-header .heading {
  margin: 0; }

.dropdown-cart-header .dropdown-cart-header-count {
  color: #818a91;
  margin-left: 5px; }

.dropdown-cart .table-cart > tbody > tr:first-child > td {
  border-top: 0; }

.dropdown-cart .cart-item-img {
  width: 60px; }

.dropdown-cart .cart-item-content .cart-item-title {
  color: #333;
  font-size: 0.875rem; }

.dropdown-cart .cart-item-content .label-quantity {
  color: #818a91; }

.dropdown-cart .cart-item-content .label-value {
  font-weight: 600; }

.dropdown-cart .cart-item-price > span {
  font-weight: 600; }

.dropdown-cart-footer {
  padding: 1rem;
  border-top: 1px solid #f1f1f1; }

.dropdown-cart-footer-subtotal {
  padding: 1rem;
  text-align: center;
  border-top: 1px solid #f1f1f1; }

.navbar-dropdown--inverse .dropdown-cart .table > tbody > tr > td,
.navbar-dropdown--inverse .dropdown-cart .table > tbody > tr > th,
.navbar-dropdown--inverse .dropdown-cart .table > tfoot > tr > td,
.navbar-dropdown--inverse .dropdown-cart .table > tfoot > tr > th,
.navbar-dropdown--inverse .dropdown-cart .table > thead > tr > td,
.navbar-dropdown--inverse .dropdown-cart .table > thead > tr > th {
  border-color: rgba(30, 30, 30, 0.7); }

.navbar-dropdown--inverse .dropdown-cart-header {
  border-bottom: 1px solid rgba(30, 30, 30, 0.7); }

.navbar-dropdown--inverse .dropdown-cart-header .heading,
.navbar-dropdown--inverse .dropdown-cart-header .dropdown-cart-header-count {
  color: #eceeef; }

.navbar-dropdown--inverse .dropdown-cart .cart-item-content .cart-item-title {
  color: rgba(255, 255, 255, 0.7); }

.navbar-dropdown--inverse .dropdown-cart .cart-item-content .label-quantity {
  color: #818a91; }

.navbar-dropdown--inverse .dropdown-cart-footer {
  border-top: 1px solid rgba(30, 30, 30, 0.7); }

.navbar-dropdown--inverse .dropdown-cart-footer-subtotal {
  border-top: 1px solid rgba(30, 30, 30, 0.7); }

.table-inner-well {
  padding: 15px; }

.table-inner-well > thead > tr > th {
  padding: 12px 0;
  border: 0;
  font-weight: 600; }

.table-inner-well > tbody > tr > td {
  padding: 1rem 0;
  border-bottom: 1px solid #f1f1f1;
  vertical-align: middle; }

.table-inner-well > tbody > tr:last-child > td {
  border: 0; }

.table-inner-well > tbody > tr.tr-sm > td {
  padding: 0.625rem 0; }

.table-inner-well .label-count {
  color: #818a91;
  font-weight: 600; }

.tab-body {
  padding: 15px; }

.tabbable-panel {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px; }

.tabs.tabs--centered > .nav {
  text-align: center; }

.tabs.tabs--centered > .nav > li {
  float: none;
  display: inline-block; }

.nav-tab-image-wrapper:hover {
  cursor: pointer; }

.nav-tab-image-wrapper .nav-tab-image {
  display: block;
  width: 96px;
  height: 96px;
  margin: auto;
  border: 2px solid #f1f1f1; }

.nav-tab-image-wrapper:hover .nav-tab-image {
  border-color: #007aff; }

.nav-tab-image-wrapper .nav-tab-image img {
  width: 100%; }

.nav-tab-image-wrapper .nav-tab-image-title {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
  font-weight: 600;
  color: #2b2b2c;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif; }

.nav-tab-image-wrapper:hover .nav-tab-image-title {
  color: #007aff; }

@media (max-width: 991px) {
  .tabs .nav-pills.nav-pills--rounded > li {
    display: block;
    margin-bottom: 5px; } }
@media (min-width: 992px) {
  .tabs .nav-pills.nav-pills--rounded {
    padding: 0 50px; }

  .tabs .nav-pills.nav-pills--rounded > li > a {
    min-width: 200px;
    background: transparent;
    border: 0;
    padding: 0;
    position: relative; }

  .tabs .nav-pills.nav-pills--rounded > li:last-child > a {
    min-width: auto; }

  .tabs .nav-pills.nav-pills--rounded > li > a:before {
    content: "";
    position: relative;
    display: block;
    top: 40px;
    height: 1px;
    margin-left: 105px;
    margin-right: 0;
    background: #f6f6f6;
    z-index: 0; }

  .tabs .nav-pills.nav-pills--rounded > li:last-child > a:before {
    background: transparent; }

  .tabs .nav-pills.nav-pills--rounded > li > a > .nav-pill {
    position: relative;
    display: block;
    width: 100px;
    height: 100px; }

  .tabs .nav-pills.nav-pills--rounded > li > a > .nav-pill > .nav-pill-circle {
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: auto;
    text-align: center;
    border-radius: 100%;
    background: #eee;
    color: #2b2b2c;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    z-index: 10; }

  .tabs .nav-pills.nav-pills--rounded > li > a > .nav-pill > .nav-pill-circle > i {
    display: block;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 40px; }

  .tabs .nav-pills.nav-pills--rounded > li.active > a > .nav-pill > .nav-pill-circle {
    background: #007aff;
    color: #FFF; }

  .tabs .nav-pills.nav-pills--rounded > li > a > .nav-pill > .nav-pill-text {
    display: block;
    margin: 10px 0 20px 0;
    color: #818a91;
    font-size: 13px; } }
.tabs--style-1 .tab-content {
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.tabs--style-1 .tab-pane {
  border: 0; }

.tabs--style-1 .nav-tabs {
  border: 0; }

.tabs--style-1 .nav-tabs > li {
  margin-right: 5px; }

.tabs--style-1 .nav-tabs > li > a {
  border-radius: 0;
  margin: 0;
  text-align: center;
  background-color: #FFF;
  border-left: 0;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
  padding: 18px 32px;
  display: table;
  width: 100%;
  border: 1px solid #f1f1f1;
  color: #000;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
  position: relative; }

.tabs--style-1 .nav-tabs > li > a:hover {
  background: #007aff;
  color: #FFF; }

.tabs--style-1 .nav-tabs > li.active > a {
  border: 1px solid #007aff;
  background: #007aff; }

.tabs--style-1 .nav-tabs > li.active:first-child > a {
  border-left: 1px solid #007aff; }

.tabs--style-1 .nav-tabs > li.active > a {
  color: #007aff; }

.tabs--style-1 .nav-tabs > li.active > a,
.tabs--style-1 .nav-tabs > li.active > a:focus,
.tabs--style-1 .nav-tabs > li.active > a:hover {
  background: #007aff;
  color: #FFF; }

.tabs--style-1 .tab-content {
  margin-top: 5px;
  border: 1px solid #f1f1f1; }

.tabs--style-2 > .nav-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0 20px;
  margin: 0; }

.tabs--style-2 > .nav-tabs > li {
  margin-right: 62px; }

.tabs--style-2 > .nav-tabs > li:last-child {
  margin-right: 0; }

.tabs--style-2 > .nav-tabs > li > a {
  border: 0;
  margin-right: 0;
  color: #2b2b2c;
  padding: 16px 0;
  font-family: "Montserrat", sans-serif; }

.tabs--style-2 > .nav-tabs > li > a > i {
  color: #a6a6a6; }

.tabs--style-2 > .nav-tabs > li.open > a,
.tabs--style-2 > .nav-tabs > li:hover > a {
  border: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
  background: none !important;
  color: #007aff; }

.tabs--style-2 > .nav-tabs > li.open > a > i,
.tabs--style-2 > .nav-tabs > li:hover > a > i {
  color: #a6a6a6; }

.tabs--style-2 > .nav-tabs > li.open .dropdown-menu,
.tabs--style-2 > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0; }

.tabs--style-2 > .nav-tabs > li.active {
  border-bottom: 2px solid #007aff;
  position: relative; }

.tabs--style-2 > .nav-tabs > li.active > a {
  border: 0;
  color: #007aff;
  background: transparent; }

.tabs--style-2 > .nav-tabs > li.active > a > i {
  color: #007aff; }

.tabs--style-2 > .tab-content {
  margin-top: 0;
  border: 0;
  border-top: 0;
  padding: 15px 0; }

.tabs--style-2.tabs--centered > .tab-content {
  border-top: 0;
  padding-top: 20px; }

.well {
  border-radius: 0.25rem;
  background: #eceeef;
  border-color: #818a91;
  -webkit-box-shadow: none;
  box-shadow: none; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section {
  margin-bottom: 20px;
  background: #fff;
  border-radius: 0.25rem;
  overflow: hidden; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section-inner {
  padding: 1.5rem 1.5rem; }

[data-template-mode='cards'] .block-card-wrapper .card-section-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  position: relative; }

[data-template-mode='cards'] .block-card-wrapper .card-section-title .heading {
  margin: 0;
  padding: 16px 25px;
  display: inline-block; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section-inner .card-section-title {
  margin-bottom: 1.5rem; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section-inner .card-section-title h3 {
  padding: 0 0 16px 0; }

[data-template-mode='cards'] .block-card-wrapper .card-section-title .btn-aux {
  display: inline-block;
  position: relative;
  float: right;
  color: #2b2b2c; }

[data-template-mode='cards'] .block-card-wrapper .card-section-title .btn-aux:hover {
  color: #007aff; }

[data-template-mode='cards'] .block-card-wrapper .card-section-inner-title-wrapper {
  padding: 1.5rem 0; }

[data-template-mode='cards'] .block-card-wrapper .card-section-inner-title {
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: #2b2b2c; }

[data-template-mode='cards'] .block-card-wrapper .card-section-inner-title > i {
  margin-right: 6px; }

[data-template-mode='cards'] .block-card-wrapper .card-section-inner-subtitle {
  margin: 4px 0 0 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 300;
  color: #818a91; }

[data-template-mode='cards'] .block-card-wrapper .card-section-inner-subtitle.subtitle-icon-aligned {
  padding-left: 20px; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section .table {
  margin-bottom: 0; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section .table > tbody > tr > td,
[data-template-mode='cards'] .block-card-wrapper .block-card-section .table > tbody > tr > th,
[data-template-mode='cards'] .block-card-wrapper .block-card-section .table > tfoot > tr > td,
[data-template-mode='cards'] .block-card-wrapper .block-card-section .table > tfoot > tr > th,
[data-template-mode='cards'] .block-card-wrapper .block-card-section .table > thead > tr > td,
[data-template-mode='cards'] .block-card-wrapper .block-card-section .table > thead > tr > th {
  vertical-align: middle; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section .table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  padding-bottom: 5px; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section .table .td-actions .btn.btn-xs {
  padding: 3px 3px 3px 6px; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section .table .td-actions {
  min-width: 90px; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section .table > tbody > tr {
  position: relative; }

[data-template-mode='cards'] .block-card-wrapper .block-card-section .table > tbody > tr:hover .td-actions .btn {
  opacity: 1;
  filter: alpha(opacity=100); }

[data-template-mode='cards'] .block-post .block-post-body {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem; }

[data-template-mode='cards'] .block-post-share {
  margin: 0;
  padding: 0;
  border: 0; }

[data-template-mode='cards'] .block-post-comment-action {
  padding: 0 0 1rem 0; }

[data-template-mode='cards'] .post-item .post-title {
  margin-top: 10px; }

[data-template-mode='cards'] .post-prev-next {
  margin: 0;
  padding: 0;
  border: 0; }

[data-template-mode='cards'] .comment-list {
  padding: 0; }

.view {
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default; }

.view .content,
.view .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0; }

.view .mask {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

.view:hover .mask {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1; }

.view img {
  max-width: 100%;
  display: block;
  position: relative; }

.view h2 {
  text-align: center;
  position: relative;
  padding: 10px;
  margin: 20px 0 0; }

.view p {
  font-style: italic;
  font-size: 12px;
  position: relative;
  color: #fff;
  padding: 10px 20px 20px;
  text-align: center; }

.view a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  background: #000;
  color: #fff;
  text-transform: uppercase;
  -webkit-box-shadow: 0 0 1px #000;
  -moz-box-shadow: 0 0 1px #000;
  box-shadow: 0 0 1px #000; }

.view a.info:hover {
  -webkit-box-shadow: 0 0 5px #000;
  -moz-box-shadow: 0 0 5px #000;
  box-shadow: 0 0 5px #000; }

.view .view-buttons {
  position: absolute;
  width: 100%;
  height: 100%;
  display: table; }

.view .view-buttons-inner {
  display: table-cell;
  vertical-align: middle;
  padding: 1rem; }

.view .view-buttons .icon {
  font-size: 32px; }

/* EFFECT 1 */
.view-first img {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear; }

.view-first .mask {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out; }

.view-first h2 {
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -o-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.view-first p {
  -webkit-transform: translateY(100px);
  -moz-transform: translateY(100px);
  -o-transform: translateY(100px);
  -ms-transform: translateY(100px);
  transform: translateY(100px);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear; }

.view-first:hover img {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1); }

.view-first a.info {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.view-first:hover a.info,
.view-first:hover h2,
.view-first:hover p {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -o-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px); }

.view-first:hover p {
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  -ms-transition-delay: 0.1s;
  transition-delay: 0.1s; }

.view-first:hover a.info {
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  transition-delay: 0.2s; }

/* EFFECT 2 */
.view-second img {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out; }

.view-second .mask {
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

.view-second h2 {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0; }

.view-second p {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear; }

.view-second a.info {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  -ms-transition: all 0.5s linear;
  transition: all 0.5s linear; }

.view-second:hover img {
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5); }

.view-second:hover a.info,
.view-second:hover h2,
.view-second:hover p {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1; }

/* EFFECT 3 */
.view-third img {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in; }

.view-third .mask {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transform: translate(460px, -100px) rotate(180deg);
  -moz-transform: translate(460px, -100px) rotate(180deg);
  -o-transform: translate(460px, -100px) rotate(180deg);
  -ms-transform: translate(460px, -100px) rotate(180deg);
  transform: translate(460px, -100px) rotate(180deg);
  -webkit-transition: all 0.2s 0.4s ease-in-out;
  -moz-transition: all 0.2s 0.4s ease-in-out;
  -o-transition: all 0.2s 0.4s ease-in-out;
  -ms-transition: all 0.2s 0.4s ease-in-out;
  transition: all 0.2s 0.4s ease-in-out; }

.view-third h2 {
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -o-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.view-third p {
  -webkit-transform: translateX(300px) rotate(90deg);
  -moz-transform: translateX(300px) rotate(90deg);
  -o-transform: translateX(300px) rotate(90deg);
  -ms-transform: translateX(300px) rotate(90deg);
  transform: translateX(300px) rotate(90deg);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.view-third a.info {
  -webkit-transform: translateY(-200px);
  -moz-transform: translateY(-200px);
  -o-transform: translateY(-200px);
  -ms-transform: translateY(-200px);
  transform: translateY(-200px);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }

.view-third:hover .mask {
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  -ms-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-transform: translate(0px, 0px);
  -moz-transform: translate(0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px); }

.view-third:hover h2 {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -o-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  transition-delay: 0.5s; }

.view-third:hover p {
  -webkit-transform: translateX(0px) rotate(0deg);
  -moz-transform: translateX(0px) rotate(0deg);
  -o-transform: translateX(0px) rotate(0deg);
  -ms-transform: translateX(0px) rotate(0deg);
  transform: translateX(0px) rotate(0deg);
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  transition-delay: 0.4s; }

.view-third:hover a.info {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -o-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  -ms-transition-delay: 0.3s;
  transition-delay: 0.3s; }

/* EFFECT 4 */
.view-fourth img {
  -webkit-transition: all 0.4s ease-in-out 0.2s;
  -moz-transition: all 0.4s ease-in-out 0.2s;
  -o-transition: all 0.4s ease-in-out 0.2s;
  -ms-transition: all 0.4s ease-in-out 0.2s;
  transition: all 0.4s ease-in-out 0.2s;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1; }

.view-fourth .mask {
  background-color: black;
  -webkit-transform: scale(0) rotate(-180deg);
  -moz-transform: scale(0) rotate(-180deg);
  -o-transform: scale(0) rotate(-180deg);
  -ms-transform: scale(0) rotate(-180deg);
  transform: scale(0) rotate(-180deg);
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.view-fourth h2 {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

.view-fourth p {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

.view-fourth a.info {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }

.view-fourth:hover .mask {
  -webkit-transform: scale(1) rotate(0deg);
  -moz-transform: scale(1) rotate(0deg);
  -o-transform: scale(1) rotate(0deg);
  -ms-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  transition-delay: 0.2s; }

.view-fourth:hover img {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  -ms-transition-delay: 0s;
  transition-delay: 0s; }

.view-fourth:hover a.info,
.view-fourth:hover h2,
.view-fourth:hover p {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  transition-delay: 0.5s; }

/* EFFECT 5 */
.view-fifth img {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.view-fifth .mask {
  background-color: black;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.view-fifth h2 {
  background: #007aff; }

.view-fifth p {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear; }

.view-fifth:hover .mask {
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -o-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px); }

.view-fifth:hover img {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%); }

.view-fifth:hover p {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1; }

/* EFFECT 6 */
.view-sixth .mask {
  top: -200px;
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0.5s;
  -moz-transition: all 0.3s ease-out 0.5s;
  -o-transition: all 0.3s ease-out 0.5s;
  -ms-transition: all 0.3s ease-out 0.5s;
  transition: all 0.3s ease-out 0.5s; }

.view-sixth h2 {
  -webkit-transform: translateY(-200px);
  -moz-transform: translateY(-200px);
  -o-transform: translateY(-200px);
  -ms-transform: translateY(-200px);
  transform: translateY(-200px);
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  -moz-transition: all 0.2s ease-in-out 0.1s;
  -o-transition: all 0.2s ease-in-out 0.1s;
  -ms-transition: all 0.2s ease-in-out 0.1s;
  transition: all 0.2s ease-in-out 0.1s; }

.view-sixth p {
  -webkit-transform: translateY(-200px);
  -moz-transform: translateY(-200px);
  -o-transform: translateY(-200px);
  -ms-transform: translateY(-200px);
  transform: translateY(-200px);
  -webkit-transition: all 0.2s ease-in-out 0.2s;
  -moz-transition: all 0.2s ease-in-out 0.2s;
  -o-transition: all 0.2s ease-in-out 0.2s;
  -ms-transition: all 0.2s ease-in-out 0.2s;
  transition: all 0.2s ease-in-out 0.2s; }

.view-sixth a.info {
  -webkit-transform: translateY(-200px);
  -moz-transform: translateY(-200px);
  -o-transform: translateY(-200px);
  -ms-transform: translateY(-200px);
  transform: translateY(-200px);
  -webkit-transition: all 0.2s ease-in-out 0.3s;
  -moz-transition: all 0.2s ease-in-out 0.3s;
  -o-transition: all 0.2s ease-in-out 0.3s;
  -ms-transition: all 0.2s ease-in-out 0.3s;
  transition: all 0.2s ease-in-out 0.3s; }

.view-sixth:hover .mask {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  top: 0;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  -ms-transition-delay: 0s;
  transition-delay: 0s;
  -webkit-animation: bounceY 0.9s linear;
  -moz-animation: bounceY 0.9s linear;
  -ms-animation: bounceY 0.9s linear;
  animation: bounceY 0.9s linear; }

.view-sixth:hover h2 {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -o-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  -ms-transition-delay: 0.4s;
  transition-delay: 0.4s; }

.view-sixth:hover p {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -o-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  transition-delay: 0.2s; }

.view-sixth:hover a.info {
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -o-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  -ms-transition-delay: 0s;
  transition-delay: 0s; }

@keyframes bounceY {
  0% {
    transform: translateY(-205px); }
  40% {
    transform: translateY(-100px); }
  65% {
    transform: translateY(-52px); }
  82% {
    transform: translateY(-25px); }
  92% {
    transform: translateY(-12px); }
  100%,
    55%,
    75%,
    87%,
    97% {
    transform: translateY(0px); } }
@-moz-keyframes bounceY {
  0% {
    -moz-transform: translateY(-205px); }
  40% {
    -moz-transform: translateY(-100px); }
  65% {
    -moz-transform: translateY(-52px); }
  82% {
    -moz-transform: translateY(-25px); }
  92% {
    -moz-transform: translateY(-12px); }
  100%,
    55%,
    75%,
    87%,
    97% {
    -moz-transform: translateY(0px); } }
@-webkit-keyframes bounceY {
  0% {
    -webkit-transform: translateY(-205px); }
  40% {
    -webkit-transform: translateY(-100px); }
  65% {
    -webkit-transform: translateY(-52px); }
  82% {
    -webkit-transform: translateY(-25px); }
  92% {
    -webkit-transform: translateY(-12px); }
  100%,
    55%,
    75%,
    87%,
    97% {
    -webkit-transform: translateY(0px); } }
/* PROFILE PAGES */
.profile .profile-picture-wrapper {
  position: relative; }

.profile .profile-picture img {
  max-width: 100%; }

.profile .profile-picture.profile-picture--style-1 {
  padding: 1rem 1rem 0; }

.profile .profile-picture.profile-picture--style-1 img {
  border-radius: 0.25rem; }

.profile .profile-picture.profile-picture--style-1 .btn-aux {
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 35px;
  right: 50px;
  background: rgba(0, 0, 0, 0.8);
  color: #ccc; }

.profile .profile-picture.profile-picture--style-1:hover .btn-aux {
  display: block; }

.profile .profile-picture.profile-picture--style-1 .btn-aux:hover {
  background: #007aff;
  color: #FFF; }

.profile .profile-picture.profile-picture--style-2 {
  width: 180px; }

.profile .profile-picture.profile-picture--style-2 img {
  border-radius: 100%;
  margin-top: 30px; }

.profile .profile-details {
  text-align: center;
  margin-top: 20px; }

.profile .profile-details .profile-name {
  margin: 0;
  padding: 0;
  font-weight: 600;
  color: #2b2b2c;
  font-size: 1.25rem; }

.profile .profile-details .profile-occupation {
  margin: 12px 0 0;
  padding: 0;
  font-weight: 400;
  color: #383839;
  font-size: 1rem; }

.profile .profile-details .profile-location {
  margin: 2px 0 0;
  padding: 0;
  font-weight: 300;
  color: #818a91;
  font-size: 1rem; }

.profile .profile-connect {
  text-align: center;
  width: 180px;
  margin: 2rem auto; }

.profile .profile-stats {
  text-align: center;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1; }

.profile .profile-stats .stats-entry {
  padding: 20px 0;
  width: 50%;
  border-right: 1px solid #f1f1f1;
  display: inline-block;
  float: left; }

.profile .profile-stats .stats-entry:last-child {
  border-right: 0; }

.profile .profile-stats .stats-entry .stats-count {
  display: block;
  font-weight: 600;
  font-size: 1.5rem;
  color: #2b2b2c; }

.profile .profile-stats .stats-entry .stats-label {
  display: block;
  font-weight: 300;
  font-size: 0.75rem;
  color: #818a91; }

.profile-useful-links .useful-links {
  padding: 2rem; }

.profile-useful-links .useful-links a {
  display: block;
  margin-bottom: 10px;
  color: #818a91;
  font-size: 0.875rem; }

.profile-useful-links .useful-links a:last-child {
  margin-bottom: 0; }

.profile-useful-links .useful-links a:hover {
  color: #007aff; }

.profile-useful-links .useful-links a > i {
  margin-right: 6px; }

.short-info {
  margin-bottom: 15px; }

.short-info:last-child {
  margin: 0; }

.short-info .short-info-label {
  padding: 0;
  margin: 0;
  display: block;
  font-size: 0.75rem;
  color: #818a91;
  font-weight: 400; }

.short-info .short-info-title {
  padding: 0;
  margin: 0;
  display: block;
  font-size: 1rem;
  color: #2b2b2c;
  font-weight: 500; }

.short-info .short-info-subtitle {
  padding: 0;
  margin: 0;
  display: block;
  font-size: 1rem;
  color: #818a91;
  font-weight: 300; }

.short-info i {
  font-size: 36px;
  display: inline-block; }

.short-info .short-info-img {
  width: 50px; }

.short-info .short-info-img img {
  max-width: 100%;
  border-radius: 100%; }

.short-info .short-info-offset {
  display: inline-block;
  padding-left: 16px;
  margin-bottom: 15px;
  position: relative; }

.short-info:last-child .short-info-offset {
  margin-bottom: 0; }

.short-info .short-info-offset .btn {
  position: relative;
  float: right; }

/* SHOP DEFAULT - General styling for shop sections */
.shop-default-wrapper #divProductList {
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out; }

.shop-default-wrapper .shop-grid-section-header {
  height: 62px;
  padding: 15px;
  border-bottom: 1px solid #f1f1f1; }

.shop-default-wrapper .shop-grid-section-header .shop-grid-section-title {
  margin: 0;
  padding: 0 1rem;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #2b2b2c; }

.shop-default-wrapper .shop-grid-section-footer {
  padding: 15px; }

.shop-default-wrapper .btn-view-mode {
  font-size: 20px;
  line-height: 1.6;
  margin-right: 0;
  color: #eceeef; }

.shop-default-wrapper .btn-view-mode.active {
  color: #2b2b2c; }

.shop-default-wrapper .aux-text {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: #2b2b2c; }

.shop-default-wrapper .btn-reset-filters {
  font-weight: 400; }

.shop-default-wrapper .btn-reset-filters > i {
  margin-right: 5px;
  font-size: 13px; }

.shop-default-wrapper .product .price-wrapper .price {
  font-size: 32px;
  color: #2b2b2c; }

.shop-default-wrapper .product .price-wrapper .price sup {
  font-size: 16px;
  top: -1em;
  font-weight: 400; }

.shop-default-wrapper .product .price-wrapper .price .price-value {
  margin: 0 3px; }

.shop-default-wrapper .product .price-wrapper .price.discount {
  text-decoration: none;
  margin-right: 8px; }

.shop-default-wrapper .product .price-wrapper .price.discount .price-value {
  margin: 0 1px;
  font-weight: 400;
  font-size: 24px;
  text-decoration: line-through; }

.shop-default-wrapper .product .price-wrapper .price.discount sup {
  font-size: 14px; }

.shop-default-wrapper .product .price-wrapper .price.discount sup.price-value-dec {
  text-decoration: line-through; }

.shop-default-wrapper .product .product-colors {
  margin-top: 12px;
  text-align: center;
  min-height: 44px; }

.shop-default-wrapper .product .product-colors .product-colors-label {
  display: block;
  margin-bottom: 4px;
  text-transform: uppercase;
  color: #2b2b2c;
  font-size: 0.75rem;
  font-weight: 500; }

.shop-default-wrapper .product .product-colors .color-switch a {
  display: inline-block;
  float: none !important;
  width: 16px;
  height: 16px;
  margin-right: 3px;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.shop-default-wrapper .product .product-actions {
  opacity: 0;
  width: 42px;
  position: absolute;
  left: 15px;
  bottom: 20px; }

.shop-default-wrapper .product .product-actions.in {
  opacity: 1; }

.shop-default-wrapper .product .product-actions .btn-product-action {
  display: block;
  width: 100%;
  height: 38px;
  line-height: 38px;
  margin-bottom: 6px;
  border: 0;
  border-radius: 38px;
  background: #eceeef;
  color: #2b2b2c;
  font-size: 18px;
  outline: 0; }

.shop-default-wrapper .product .product-actions .btn-product-action:hover {
  background: #007aff;
  color: #FFF;
  border-color: #0062cc; }

.shop-default-wrapper .product .product-actions .btn-product-action:active {
  border-color: transparent; }

.shop-default-wrapper .product .product-actions.animated {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  -ms-animation-duration: 0.5s;
  -o-animation-duration: 0.5s;
  animation-duration: 0.5s; }

.shop-default-wrapper .filter-sidebar {
  padding: 30px; }

.shop-default-wrapper .filter-sidebar .filter-checkbox .checkbox {
  margin-bottom: 15px; }

.shop-default-wrapper .filter-sidebar .filter-checkbox .checkbox label {
  padding-left: 10px; }

.shop-default-wrapper .product figure {
  border: 0; }

.shop-default-wrapper .product .switch-on-hover img {
  display: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; }

.shop-default-wrapper .product .switch-on-hover img.img-primary {
  display: block;
  opacity: 1; }

.shop-default-wrapper .product:hover .switch-on-hover img {
  display: block;
  opacity: 1; }

.shop-default-wrapper .product:hover .switch-on-hover img.img-primary {
  display: none;
  opacity: 0; }

/* SHOP CARDS */
.shop-cards-wrapper .product {
  margin: 0;
  padding: 1.5rem;
  border: 0;
  border-radius: 0.25rem;
  background: #fff; }

.shop-cards-wrapper .product.product--style-2 {
  position: relative; }

.shop-cards-wrapper .product.product--style-2 .product-image {
  display: inline-block;
  float: left;
  width: 40%; }

.shop-cards-wrapper .product.product--style-2 .product-content {
  display: inline-block;
  width: 60%; }

.shop-cards-wrapper .product.product--style-2 .product-content .product-info {
  position: absolute;
  right: 20px;
  bottom: 12px; }

.shop-cards-wrapper .product.product--style-2 .product-content .product-info .price-wrapper {
  display: block;
  margin-bottom: 10px; }

.shop-cards-wrapper .product.product--style-2 .product-content .product-icon-btn {
  display: inline-block;
  margin-right: 10px;
  font-size: 16px;
  color: #2b2b2c;
  font-weight: 500; }

.shop-cards-wrapper .product.product--style-2 .product-content .product-icon-btn.active {
  color: #007aff; }

.shop-cards-wrapper .product.product--style-2 .product-content .product-icon-btn:last-child {
  margin-right: 0; }

.shop-cards-wrapper .product.product--style-2 .product-content .product-icon-btn:hover {
  color: #007aff; }

.shop-cards-wrapper .product .product-title,
.shop-cards-wrapper .product .product-title a {
  color: #2b2b2c; }

.shop-cards-wrapper .product .product-description {
  color: #818a91;
  font-size: 0.875rem; }

.shop-cards-wrapper .product .product-long-description {
  max-width: 360px;
  margin: 0 auto; }

.shop-cards-wrapper.shop-tech-wrapper .product-buttons .btn-cart {
  font-size: 0.625rem !important;
  padding: 0.625rem 0.875rem !important; }

.shop-cards-wrapper.shop-tech-wrapper .product-buttons .btn-icon {
  text-align: center;
  font-size: 20px;
  padding: 2px;
  color: #55595c; }

.shop-cards-wrapper.shop-tech-wrapper .product-buttons .btn-icon:hover,
.shop-cards-wrapper.shop-tech-wrapper .product-buttons .btn-icon.active {
  color: #007aff; }

.shop-cards-wrapper .product .price-wrapper .price-sm {
  font-size: 24px; }

.shop-cards-wrapper .product .price-wrapper .price-sm.discount .price-value {
  font-size: 1rem;
  font-weight: 600; }

@media (min-width: 992px) {
  .shop-grid-wrapper .product-list.has-sidebar {
    border-left: 1px solid #f1f1f1; } }
.shop-grid-wrapper .product {
  margin: 0;
  padding: 1.5rem;
  border: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }

@media (max-width: 991px) {
  .shop-grid-wrapper .block.product {
    margin-bottom: 0 !important; } }
@media (max-width: 767px) {
  .shop-grid-wrapper .product {
    border-right: 0; } }
@media (min-width: 768px) and (max-width: 991px) {
  .shop-grid-wrapper .product-list .row-no-padding.row-products > .col-sm-6:nth-child(2n+2) > .product {
    border-right: 0; }

  .shop-grid-wrapper .product-list .row-no-padding.row-products > .col-sm-4:nth-child(3n+3) > .product {
    border-right: 0; }

  .shop-grid-wrapper .product-list .row-no-padding.row-products > .col-sm-3:nth-child(4n+4) > .product {
    border-right: 0; } }
@media (min-width: 992px) {
  .shop-grid-wrapper .product-list .row-no-padding.row-products > [class*="col-"]:last-child > .product {
    border-right: 0; }

  .shop-grid-wrapper .product-list .row-no-padding.row-products > .col-md-6:nth-child(2n+2) > .product {
    border-right: 0; }

  .shop-grid-wrapper .product-list .row-no-padding.row-products > .col-md-4:nth-child(3n+3) > .product {
    border-right: 0; }

  .shop-grid-wrapper .product-list .row-no-padding.row-products > .col-md-3:nth-child(4n+4) > .product {
    border-right: 0; } }
/* SHOP MINIMALIST */
.shop-minimalist-wrapper .product {
  border: 0;
  background: #eee; }

.shop-minimalist-wrapper .product .product-image {
  padding: 30px; }

.shop-minimalist-wrapper .product .product-image .mask {
  border-radius: 0.25rem; }

.shop-minimalist-wrapper .product .product-image .product-title {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  text-transform: none;
  margin: 30px 0; }

.shop-minimalist-wrapper .product .product-image .product-price-wrapper {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%; }

.shop-minimalist-wrapper .product .product-image .product-price {
  font-weight: 400;
  color: white; }

.shop-minimalist-wrapper .product .product-image .product-price .price-value {
  font-size: 24px; }

.shop-minimalist-wrapper .product .product-image .product-price.discount {
  padding: 7px 0 0;
  margin-right: 4px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #ff3b30;
  text-decoration: line-through; }

/* PRODUCT RIBBONS */
.product-ribbon {
  position: absolute;
  top: 15px; }

.product-ribbon.product-ribbon-left {
  left: 15px; }

.product-ribbon.product-ribbon-right {
  right: 15px; }

.product-ribbon.no-space {
  top: 0; }

.product-ribbon.no-space.product-ribbon-left {
  left: 0; }

.product-ribbon.no-space.product-ribbon-right {
  right: 0; }

.product-ribbon.product-ribbon--style-1 {
  width: 48px;
  height: 48px;
  border-radius: 100%;
  text-align: center;
  line-height: 48px;
  font-size: 11px;
  z-index: 200; }

/* SHOP - SWIPER CONTAINER */
.product-swiper-container {
  position: relative;
  padding-top: 20px; }

.product-swiper-container .swiper-slide img {
  max-width: 100%; }

.product-swiper-container .swiper-pagination {
  top: 0;
  bottom: auto;
  text-align: left; }

.gallery-container {
  position: relative; }

.gallery-top {
  width: 100%;
  padding: 1rem;
  background: #eceeef;
  border-radius: 0.25rem; }

.gallery-top .swiper-button-next,
.gallery-top .swiper-button-prev {
  opacity: 0; }

.gallery-top:hover .swiper-button-next,
.gallery-top:hover .swiper-button-prev {
  opacity: 1; }

.gallery-top .swiper-slide img {
  max-width: 100%; }

.gallery-thumbs {
  box-sizing: border-box;
  padding: 1rem 0; }

.gallery-thumbs .swiper-slide {
  width: 25%;
  opacity: 0.4;
  background: transparent; }

.gallery-thumbs .swiper-slide-active {
  opacity: 1; }

.gallery-thumbs .swiper-slide img {
  max-width: 100%; }

.gallery-container--style-2 .gallery-top {
  background: transparent; }

/* PRODUCT DESCRIPTION */
.product-description-wrapper .product-title {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  color: #2b2b2c; }

.product-description-wrapper .product-category-label {
  margin: 0 0 20px;
  padding: 0;
  font-weight: 400;
  color: #818a91; }

.product-description-wrapper .product-short-text {
  margin: 16px 0; }

.product-description-wrapper .product-price {
  margin: 16px 0;
  font-size: 36px;
  font-weight: 400;
  color: #2b2b2c; }

.product-description-wrapper .product-price.discount {
  padding: 7px 0 0;
  margin-right: 4px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #ff3b30;
  text-decoration: line-through; }

.product-description-wrapper .product-short-info {
  margin: 30px 0; }

.product-description-wrapper .product-short-info span {
  display: block;
  padding: 4px; }

.product-description-wrapper .product-short-info strong {
  font-weight: 500;
  color: #818a91;
  margin-right: 5px; }

.product-description-wrapper .product-quantity .btn-cart,
.product-description-wrapper .product-quantity .btn-wishlist {
  height: 60px;
  margin-left: 15px; }

/* PLUS - MINUS CONTROL */
.spinner {
  width: 100px; }

.spinner input {
  text-align: right;
  padding: 16px 20px;
  height: 61px;
  border: 0;
  text-align: center;
  background: #f3f3f3; }

.input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell; }

.input-group-btn-vertical > .btn {
  outline: 0 !important;
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  height: 31px;
  width: 30px;
  border: 2px solid #f3f3f3;
  text-align: center;
  margin-left: -2px;
  position: relative;
  border-radius: 0; }

.input-group-btn-vertical > .btn:active,
.input-group-btn-vertical > .btn:focus {
  outline: 0 !important; }

.input-group-btn-vertical > .btn:hover {
  background-color: #007aff;
  color: #FFF; }

.input-group-btn-vertical > .btn:first-child {
  border-top-right-radius: 2px; }

.input-group-btn-vertical > .btn:last-child {
  margin-top: -2px;
  border-bottom-right-radius: 2px; }

.input-group-btn-vertical i {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -4.5px;
  margin-left: -4.5px;
  font-size: 9px; }

.input-group-btn-vertical i.icon {
  font-size: 14px;
  margin-top: -9px;
  margin-left: -3px; }

.c-base-1 {
  color: #007aff; }

.c-base-2 {
  color: #007aff; }

.c-base-3 {
  color: #818a91; }

.c-base-4 {
  color: #1d2125; }

.c-base-5 {
  color: #FFF; }

.c-base-text-1 {
  color: #FFF; }

.c-base-text-2 {
  color: #FFF; }

.c-base-text-3 {
  color: rgba(255, 255, 255, 0.9); }

.c-base-text-4 {
  color: #FFF; }

.c-base-text-5 {
  color: #0A0814; }

.c-gray-dark {
  color: #2b2b2c !important; }

.c-gray {
  color: #55595c !important; }

.c-gray-light {
  color: #818a91 !important; }

.c-gray-lighter {
  color: #eceeef !important; }

.c-gray-lightest {
  color: #f7f7f9 !important; }

.c-black {
  color: #000000 !important; }

.c-white {
  color: white !important; }

.c-blue {
  color: #007aff !important; }

.c-teal-blue {
  color: #5ac8fa !important; }

.c-green {
  color: #4cd964 !important; }

.c-red {
  color: #ff3b30 !important; }

.c-pink {
  color: #ff2d55 !important; }

.c-orange {
  color: #ff9500 !important; }

.c-yellow {
  color: #ffcc00 !important; }

.c-purple {
  color: #5856d6 !important; }

.c-twitter {
  color: #1da1f2; }

.c-facebook {
  color: #3b5998; }

.c-instagram {
  color: #e1306c; }

.c-skype {
  color: #00aff0; }

.bg-base-1 {
  background-color: #007aff;
  color: #FFF; }

.bg-base-1 .heading,
.bg-base-1 a:not(.btn) {
  color: #FFF !important; }

.bg-base-2 {
  background-color: #007aff;
  color: #FFF; }

.bg-base-2 .heading,
.bg-base-2 a:not(.btn) {
  color: #FFF !important; }

.bg-base-3 {
  background-color: #818a91;
  color: rgba(255, 255, 255, 0.9); }

.bg-base-3 .heading,
.bg-base-3 a:not(.btn) {
  color: rgba(255, 255, 255, 0.9) !important; }

.bg-base-4 {
  background-color: #1d2125;
  color: #FFF; }

.bg-base-4 .heading,
.bg-base-4 a:not(.btn) {
  color: #FFF !important; }

.bg-base-5 {
  background-color: #FFF;
  color: #0A0814; }

.bg-base-5 .heading,
.bg-base-5 a:not(.btn) {
  color: #0A0814 !important; }

.bg-white {
  background-color: white;
  color: #333; }

.bg-gray-dark {
  background-color: #2b2b2c;
  color: #eceeef; }

.bg-gray-dark *:not(.btn) {
  color: #eceeef !important; }

.bg-gray-light {
  background-color: #818a91;
  color: #2b2b2c; }

.bg-gray-light *:not(.btn) {
  color: #2b2b2c !important; }

.bg-gray-lighter {
  background-color: #eceeef;
  color: #2b2b2c; }

.bg-gray-lighter *:not(.btn) {
  color: #2b2b2c !important; }

.bg-black {
  background-color: #000000;
  color: #FFF; }

.block-rainbow.bg-black *:not(.btn),
.bg-black .sct-inner *:not(.btn):not(.alert):not(.form-control):not(code) {
  color: #FFF; }

.bg-space-gray {
  background-color: #282d33;
  color: #FFF; }

.bg-space-gray *:not(.btn) {
  color: #FFF !important; }

.bg-blue {
  background-color: #007aff !important;
  color: #FFF; }

.bg-blue *:not(.btn) {
  color: #FFF !important; }

.bg-teal-blue {
  background-color: #5ac8fa !important;
  color: #FFF; }

.bg-teal-blue *:not(.btn) {
  color: #FFF !important; }

.bg-green {
  background-color: #4cd964 !important;
  color: #FFF; }

.bg-green *:not(.btn) {
  color: #FFF !important; }

.bg-red {
  background-color: #ff3b30 !important;
  color: #FFF; }

.bg-red *:not(.btn) {
  color: #FFF !important; }

.bg-pink {
  background-color: #ff2d55 !important;
  color: #FFF; }

.bg-pink *:not(.btn) {
  color: #FFF !important; }

.bg-orange {
  background-color: #ff9500 !important;
  color: #FFF; }

.bg-orange *:not(.btn) {
  color: #FFF !important; }

.bg-yellow {
  background-color: #ffcc00 !important;
  color: #FFF; }

.bg-yellow *:not(.btn) {
  color: #FFF !important; }

.bg-purple {
  background-color: #5856d6 !important;
  color: #FFF; }

.bg-purple *:not(.btn) {
  color: #FFF !important; }

.sct-color-1 {
  background-color: #FFF; }

.sct-color-2 {
  background-color: #fafafa; }

.sct-color-3 {
  background-color: #282d33;
  color: #d7d2cc; }

.sct-color-3 .sct-inner *:not(.btn):not(.alert):not(.form-control):not(code) {
  color: #d7d2cc; }

.sct-color-4 {
  background-color: #121416; }

.bg-gradient-1, .mask-gradient-1--style-1, .mask-gradient-1--style-2 {
  background: linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -o-linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -ms-linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -moz-linear-gradient(left, #e9168c 2%, #f75254 99%);
  background: -webkit-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -o-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -ms-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -moz-linear-gradient(left, #e9168c 2%, #f75254 99%);
  border-image: -webkit-linear-gradient(left, #e9168c 2%, #f75254 99%); }

.bg-gradient-blue-pink {
  background-color: #E55D87;
  background-color: -webkit-linear-gradient(to left, #E55D87, #5FC3E4);
  background-color: linear-gradient(to left, #E55D87, #5FC3E4); }

.bg-gradient-electric-red {
  background-color: #D31027;
  background-color: -webkit-linear-gradient(to left, #D31027, #EA384D);
  background-color: linear-gradient(to left, #D31027, #EA384D); }

[class^="space-"] {
  display: block; }

.space-xs-sm {
  margin-bottom: 1.5rem; }

.space-xs-md {
  margin-bottom: 2.5rem; }

.space-xs-lg {
  margin-bottom: 3.5rem; }

.space-xs-xl {
  margin-bottom: 4.5rem; }

@media (min-width: 768px) {
  .space-sm-sm {
    margin-bottom: 1.5rem; }

  .space-sm-md {
    margin-bottom: 2.5rem; }

  .space-sm-lg {
    margin-bottom: 3.5rem; }

  .space-sm-xl {
    margin-bottom: 4.5rem; } }
@media (min-width: 992px) {
  .space-md-sm {
    margin-bottom: 1.5rem; }

  .space-md-md {
    margin-bottom: 2.5rem; }

  .space-md-lg {
    margin-bottom: 3.5rem; }

  .space-md-xl {
    margin-bottom: 4.5rem; } }
@media (min-width: 1201px) {
  .space-lg-sm {
    margin-bottom: 1.5rem; }

  .space-lg-md {
    margin-bottom: 2.5rem; }

  .space-lg-lg {
    margin-bottom: 3.5rem; }

  .space-lg-xl {
    margin-bottom: 4.5rem; } }
@media (max-width: 767px) {
  .space-xs-only-1 {
    clear: both;
    margin-bottom: 1rem; }

  .space-xs-only-2 {
    clear: both;
    margin-bottom: 2rem; }

  .space-xs-only-3 {
    clear: both;
    margin-bottom: 3rem; } }
@media (min-width: 768px) and (max-width: 991px) {
  .space-sm-only-1 {
    clear: both;
    margin-bottom: 1rem; }

  .space-sm-only-2 {
    clear: both;
    margin-bottom: 2rem; }

  .space-sm-only-3 {
    clear: both;
    margin-bottom: 3rem; } }
@media (min-width: 992px) and (max-width: 1200px) {
  .space-md-only-1 {
    clear: both;
    margin-bottom: 1rem; }

  .space-md-only-2 {
    clear: both;
    margin-bottom: 2rem; }

  .space-md-only-3 {
    clear: both;
    margin-bottom: 3rem; } }
@media (min-width: 1201px) {
  .space-lg-only-1 {
    clear: both;
    margin-bottom: 1rem; }

  .space-lg-only-2 {
    clear: both;
    margin-bottom: 2rem; }

  .space-lg-only-3 {
    clear: both;
    margin-bottom: 3rem; } }
.no-margin {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 1rem !important; }

.mt-2 {
  margin-top: 2rem !important; }

.mt-3 {
  margin-top: 3rem !important; }

.mr-1 {
  margin-right: 1rem !important; }

.mr-2 {
  margin-right: 2rem !important; }

.mr-3 {
  margin-right: 3rem !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 1rem !important; }

.mb-2 {
  margin-bottom: 2rem !important; }

.mb-3 {
  margin-bottom: 3rem !important; }

.ml-1 {
  margin-left: 1rem !important; }

.ml-2 {
  margin-left: 2rem !important; }

.ml-3 {
  margin-left: 3rem !important; }

.no-padding {
  padding: 0 !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 1rem !important; }

.pt-2 {
  padding-top: 2rem !important; }

.pt-3 {
  padding-top: 3rem !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-1 {
  padding-right: 1rem !important; }

.pr-2 {
  padding-right: 2rem !important; }

.pr-3 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 1rem !important; }

.pb-2 {
  padding-bottom: 2rem !important; }

.pb-3 {
  padding-bottom: 3rem !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 1rem !important; }

.pl-2 {
  padding-left: 2rem !important; }

.pl-3 {
  padding-left: 3rem !important; }

.px-1 {
  padding-left: 1rem;
  padding-right: 1rem; }

.px-2 {
  padding-left: 2rem;
  padding-right: 2rem; }

.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important; }

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

@media (min-width: 992px) {
  .px-inline-only-1 {
    padding-left: 1rem;
    padding-right: 1rem; }

  .px-inline-only-2 {
    padding-left: 2rem;
    padding-right: 2rem; }

  .px-inline-only-3 {
    padding-left: 3rem;
    padding-right: 3rem; } }
.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-normal {
  text-transform: none !important; }

.text-line-through {
  text-decoration: line-through; }

.text-underline {
  text-decoration: underline; }

.text-xs-left {
  text-align: left !important; }

.text-xs-right {
  text-align: right !important; }

.text-xs-center {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important; }

  .text-sm-right {
    text-align: right !important; }

  .text-sm-center {
    text-align: center !important; } }
@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important; }

  .text-md-right {
    text-align: right !important; }

  .text-md-center {
    text-align: center !important; } }
@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important; }

  .text-lg-right {
    text-align: right !important; }

  .text-lg-center {
    text-align: center !important; } }
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }

  .text-xl-right {
    text-align: right !important; }

  .text-xl-center {
    text-align: center !important; } }
.font-blzee {
  font-family: "Blzee", sans-serif !important; }

.font-custom-1 {
  font-family: "Sue Ellen Francisco", sans-serif !important; }

.font-custom-2 {
  font-family: "Pacifico", sans-serif !important; }

.font-custom-3 {
  font-family: "YellowTail", sans-serif !important; }

.font-custom-4 {
  font-family: "Slabo 27px", sans-serif !important; }

.no-border {
  border: 0 !important; }

.b-xs-all {
  border: 1px solid #f1f1f1; }

.b-xs-top {
  border-top: 1px solid #f1f1f1; }

.b-xs-right {
  border-right: 1px solid #f1f1f1; }

.b-xs-bottom {
  border-bottom: 1px solid #f1f1f1; }

.b-xs-left {
  border-left: 1px solid #f1f1f1; }

@media (min-width: 768px) {
  .b-md-all {
    border: 1px solid #f1f1f1; }

  .b-md-top {
    border-top: 1px solid #f1f1f1; }

  .b-md-right {
    border-right: 1px solid #f1f1f1; }

  .b-md-bottom {
    border-bottom: 1px solid #f1f1f1; }

  .b-md-left {
    border-left: 1px solid #f1f1f1; } }
@media (min-width: 992px) {
  .b-lg-all {
    border: 1px solid #f1f1f1; }

  .b-lg-top {
    border-top: 1px solid #f1f1f1; }

  .b-lg-right {
    border-right: 1px solid #f1f1f1; }

  .b-lg-bottom {
    border-bottom: 1px solid #f1f1f1; }

  .b-lg-left {
    border-left: 1px solid #f1f1f1; } }
.no-radius {
  border-radius: 0 !important; }

.section-shadow-top-1 {
  background-image: url("../images/shadows/shadow-1.png");
  background-repeat: no-repeat;
  background-position: top center; }

.section-shadow-bottom-1 {
  background-image: url("../images/shadows/shadow-1.png");
  background-repeat: no-repeat;
  background-position: bottom center; }

.strong {
  font-weight: bold !important; }

.strong-100 {
  font-weight: 100 !important; }

.strong-200 {
  font-weight: 200 !important; }

.strong-300 {
  font-weight: 300 !important; }

.strong-400 {
  font-weight: 400 !important; }

.strong-500 {
  font-weight: 500 !important; }

.strong-600 {
  font-weight: 600 !important; }

.strong-700 {
  font-weight: 700 !important; }

/* LINE SPACING */
.letter-spacing-1 {
  letter-spacing: 1px !important; }

.letter-spacing-2 {
  letter-spacing: 2px !important; }

.letter-spacing-3 {
  letter-spacing: 3px !important; }

.letter-spacing-4 {
  letter-spacing: 4px !important; }

.letter-spacing-5 {
  letter-spacing: 5px !important; }

.text-italic {
  font-style: italic !important; }

.z-depth-1:not(.btn),
.z-depth-1--hover:not(.btn):hover,
.z-depth-1-top:not(.btn),
.z-depth-1-top--hover:not(.btn):hover,
.z-depth-1-bottom:not(.btn),
.swiper-slide > .block--style-4-v1:not(.btn),
.z-depth-1-bottom--hover:not(.btn):hover,
.z-depth-2:not(.btn),
.dropdown-menu.dropdown-custom:not(.btn),
.z-depth-2--hover:not(.btn):hover,
.z-depth-2-top:not(.btn),
.z-depth-2-top--hover:not(.btn):hover,
.z-depth-2-bottom:not(.btn),
.z-depth-2-bottom--hover:not(.btn):hover,
.z-depth-3:not(.btn),
.z-depth-3--hover:not(.btn):hover,
.z-depth-3-top:not(.btn),
.z-depth-3-top--hover:not(.btn):hover,
.z-depth-3-bottom:not(.btn),
.z-depth-3-bottom--hover:not(.btn):hover,
.z-depth-4:not(.btn),
.z-depth-4--hover:not(.btn):hover,
.z-depth-4-top:not(.btn),
.z-depth-4-top--hover:not(.btn):hover,
.z-depth-4-bottom:not(.btn),
.z-depth-4-bottom--hover:not(.btn):hover,
.z-depth-5:not(.btn),
.z-depth-5--hover:not(.btn):hover,
.z-depth-5-top:not(.btn),
.z-depth-5-top--hover:not(.btn):hover,
.z-depth-5-bottom:not(.btn),
.z-depth-5-bottom--hover:not(.btn):hover {
  border: 0 !important; }

.z-depth-0,
.z-depth-0--hover:hover {
  box-shadow: none !important; }

.z-depth-1,
.z-depth-1--hover:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12); }

.z-depth-1-top,
.z-depth-1-top--hover:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12); }

.z-depth-1-bottom, .swiper-slide > .block--style-4-v1,
.z-depth-1-bottom--hover:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16); }

.z-depth-2, .dropdown-menu.dropdown-custom,
.z-depth-2--hover:hover {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); }

.z-depth-2-top,
.z-depth-2-top--hover:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.19); }

.z-depth-2-bottom,
.z-depth-2-bottom--hover:hover {
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2); }

.z-depth-3,
.z-depth-3--hover:hover {
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.24), 0 17px 50px rgba(0, 0, 0, 0.19); }

.z-depth-3-top,
.z-depth-3-top--hover:hover {
  box-shadow: 0 17px 50px rgba(0, 0, 0, 0.19); }

.z-depth-3-bottom,
.z-depth-3-bottom--hover:hover {
  box-shadow: 0 12px 15px rgba(0, 0, 0, 0.24); }

.z-depth-4,
.z-depth-4--hover:hover {
  box-shadow: 0 16px 28px rgba(0, 0, 0, 0.22), 0 25px 55px rgba(0, 0, 0, 0.21); }

.z-depth-4-top,
.z-depth-4-top--hover:hover {
  box-shadow: 0 25px 55px rgba(0, 0, 0, 0.21); }

.z-depth-4-bottom,
.z-depth-4-bottom--hover:hover {
  box-shadow: 0 16px 28px rgba(0, 0, 0, 0.22); }

.z-depth-5,
.z-depth-5--hover:hover {
  box-shadow: 0 27px 24px rgba(0, 0, 0, 0.2), 0 40px 77px rgba(0, 0, 0, 0.22); }

.z-depth-5-top,
.z-depth-5-top--hover:hover {
  box-shadow: 0 40px 77px rgba(0, 0, 0, 0.22); }

.z-depth-5-bottom,
.z-depth-5-bottom--hover:hover {
  box-shadow: 0 27px 24px rgba(0, 0, 0, 0.2); }

.img-center, .product-swiper-container .swiper-slide img, .gallery-top .swiper-slide img, .gallery-thumbs .swiper-slide img {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.relative {
  position: relative; }

body {
  background: #fff; }

.body-wrap {
  position: relative;
  z-index: 0; }

.body-wrap.body-boxed {
  margin: 20px auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }

.body-wrap.body-boxed--no-margin {
  margin: 0 auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15); }

.body-wrap:after,
.body-wrap:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 260px; }

.body-wrap:after {
  top: auto;
  bottom: 0; }

@media (min-width: 1200px) {
  .body-wrap.body-boxed {
    width: 1230px; } }
@media (min-width: 992px) and (max-width: 1199px) {
  .body-wrap.body-boxed {
    width: 1000px; } }
.body-bg-1 {
  background: url("../images/patterns/pattern-1.png");
  background-repeat: repeat; }

.body-bg-2 {
  background: url("../images/patterns/pattern-2.png");
  background-repeat: repeat; }

.body-bg-3 {
  background: url("../images/patterns/pattern-3.png");
  background-repeat: repeat; }

.same-height {
  min-height: 400px; }

.page-title {
  position: relative; }

.page-title .breadcrumb {
  display: inline-block;
  background: transparent;
  float: none;
  padding: 0;
  margin: 0; }

.page-title .breadcrumb li,
.page-title .breadcrumb li > a {
  font-weight: 400;
  font-size: 0.75rem;
  text-transform: uppercase; }

.page-title .breadcrumb--style-1 li,
.page-title .breadcrumb--style-1 li a,
.page-title .breadcrumb--style-1 li a:hover,
.page-title .breadcrumb--style-1 li.active a {
  color: #eceeef; }

.page-title .breadcrumb--style-2 li,
.page-title .breadcrumb--style-2 li a,
.page-title .breadcrumb--style-2 li a:hover,
.page-title .breadcrumb--style-2 li.active a {
  color: #2b2b2c; }

.page-title-wrapper {
  position: relative; }

.page-title-wrapper .page-title-scroll-down {
  position: absolute;
  bottom: -18px;
  left: 50%;
  margin-left: -18px;
  display: block;
  width: 36px;
  height: 36px;
  line-height: 28px;
  text-align: center;
  background-color: inherit;
  border-radius: 100%;
  color: #818a91;
  font-size: 12px;
  z-index: 100; }

.page-title.page-title--style-1 {
  padding: 30px 0;
  background: #202020;
  color: #FFF; }

.page-title.page-title--style-1 h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0;
  color: #FFF; }

.page-title.page-title--style-1 .breadcrumb {
  margin-bottom: 5px; }

.page-title.page-title--style-2 {
  padding: 120px 0;
  color: #FFF;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover; }

.page-title.page-title--style-2 h2 {
  display: block;
  padding: 10px 0;
  font-family: "Montserrat", sans-serif; }

.page-title.page-title--style-2 .breadcrumb {
  display: inline-block;
  float: none;
  padding: 4px 8px; }

.page-title.page-title--style-2 .breadcrumb li,
.page-title.page-title--style-2 .breadcrumb li > a {
  font-size: 0.75rem;
  font-weight: 500; }

.page-title.page-title--style-2 .nav {
  position: relative;
  top: 30px;
  margin-top: 0;
  left: 0; }

.page-title.page-title--style-3 {
  padding: 30px 0;
  text-align: center; }

.page-title.page-title--style-3 h2 {
  display: block;
  text-align: center;
  padding: 10px 0;
  font-size: 1.75rem;
  color: white; }

.page-title.page-title--style-3 .breadcrumb {
  float: none;
  padding: 10px 0; }

.page-title.page-title--style-4 {
  position: relative;
  padding: 4rem 0;
  background: transparent;
  color: #FFF;
  border-color: #f1f1f1;
  background-position: 50 0; }

.page-title.page-title--style-4 .breadcrumb {
  padding: 8px 0; }

.page-title.page-title--style-4 .heading-md {
  padding: 8px 0; }

@media only screen and (max-width: 767px) {
  .page-title {
    text-align: center; }

  .page-title h2 {
    padding: 18px 0 0; }

  .page-title .breadcrumb {
    float: none;
    padding: 18px 0; }

  .page-title.page-title--style-4 .sorting-options {
    text-align: center;
    margin-top: 20px; } }
@media (min-width: 992px) {
  .page-title.page-title--style-1 .row {
    display: -ms-flexbox;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    display: -moz-box;
    -moz-box-pack: center;
    -moz-box-align: center;
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    display: box;
    box-pack: center;
    box-align: center; } }
.cta-wrapper {
  padding: 2rem 0;
  border-radius: 0.25rem; }

.cta-wrapper p {
  margin: 0; }

.cta-wrapper--over {
  position: absolute;
  top: 0;
  left: 50%;
  right: auto;
  width: 910px;
  height: 140px;
  margin-top: -70px;
  margin-left: -455px;
  z-index: 1000; }

.has-cta-over {
  position: relative;
  margin-top: 100px; }

@media (max-width: 991px) {
  .cta-wrapper--over {
    position: static;
    width: 100%;
    height: auto;
    left: 0;
    margin: 0;
    border-radius: 0; }

  .has-cta-over {
    margin-top: 0; } }
.has-bg-cover {
  position: relative; }

.bg-size-cover {
  background-size: cover;
  background-repeat: no-repeat; }

.bg-size-contain {
  background-size: cover;
  background-repeat: no-repeat; }

.cover-caption {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.cover-caption-inner {
  position: relative;
  z-index: 600;
  padding: 2rem; }

.parallax-section {
  position: relative;
  overflow-x: hidden;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; }

.parallax-section {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.parallax-section-lg {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.parallax-section-xl {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.text-cover-wrapper {
  padding: 80px 0; }

.text-cover-title {
  margin: 0;
  padding: 0;
  font-size: 10rem;
  font-weight: 700;
  line-height: 1; }

.text-cover-subtitle {
  margin: 0;
  padding: 0;
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1; }

.text-cover-p {
  width: 480px;
  font-size: 1rem; }

@media (max-width: 991px) {
  .text-cover-title {
    font-size: 8rem; }

  .text-cover-subtitle {
    font-size: 2rem; }

  .text-cover-p {
    width: 100%; } }
.quote-info .quote-info-img {
  border-radius: 100%;
  display: inline-block;
  max-width: 100px;
  padding: 5px;
  border: 1px solid transparent;
  background: transparent; }

.quote-info .quote-info-img > img {
  max-width: 100%;
  border-radius: 100%; }

.quote-info-light .quote-info-img {
  border-color: #eceeef; }

.quote-info-dark .quote-info-img {
  border-color: #2b2b2c; }

.client-logo {
  padding: 1rem; }

.client-logo img {
  max-width: 100%; }

.client-logo--style-1 {
  background: #FFF;
  border-radius: 0.25rem; }

.client-logo--style-2 {
  background: #818a91;
  border-radius: 0.25rem; }

.client-logo--style-3 {
  background: transparent; }

.client-logo--style-4 {
  background: transparent;
  border-radius: 0.25rem;
  border: 1px solid #f1f1f1; }

@media (max-width: 991px) {
  .client-logo {
    margin-bottom: 20px; } }
.floating-content {
  position: relative;
  z-index: 100;
  padding: 2rem; }

.floating-content-fixed {
  height: 300px; }

@media (max-width: 767px) {
  .floating-content {
    box-shadow: none !important; } }
@media (min-width: 992px) {
  .floating-content.float-top {
    top: -100px; }

  .floating-content.float-right {
    right: -100px; }

  .floating-content.float-bottom {
    bottom: -100px; }

  .floating-content.float-left {
    left: -100px; } }
.caption-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.caption-wrapper > .caption-aligner {
  width: 100%; }

.caption-wrapper > .caption-aligner > .caption {
  width: 70%;
  margin: auto; }

.caption-wrapper > .caption-aligner > .caption.caption--center {
  flex: 1;
  word-wrap: break-word; }

.caption-wrapper > .caption-aligner > .caption.caption--fluid {
  width: 100%; }

.caption-wrapper > .caption-aligner > .caption.caption--style-1 {
  background: #FFF;
  padding: 46px; }

.overlayed-form--style-1 {
  padding: 2rem; }

@media (min-width: 992px) {
  .overlayed-form--style-1 {
    position: absolute;
    top: 0;
    left: 120px;
    width: 380px;
    height: 100%;
    background: rgba(255, 255, 255, 0.9); }

  .overlayed-form--style-2 {
    position: relative; } }
@media (max-width: 991px) {
  .overlayed-form--style-2 {
    margin: 0 !important; } }
.search-page-wrapper {
  width: 760px;
  margin: 50px auto;
  position: relative;
  z-index: 500; }

.search-widget {
  position: relative;
  margin-top: 40px; }

.search-widget .form-control:hover {
  border-color: #f1f1f1; }

.search-widget .form-control:focus {
  border-color: #007aff; }

.search-widget button.btn-inner {
  position: absolute;
  top: 0;
  right: 20px;
  background: transparent;
  border: medium none;
  width: 43px;
  height: 52px;
  line-height: 35px;
  text-align: center; }

.search-widget--style-1 .form-control:not(.bootstrap-select) {
  border-radius: 0.25rem;
  height: 62px; }

.search-widget--style-1 .form-control > .dropdown-toggle {
  border-radius: 0.25rem; }

.search-widget--style-1 button.btn {
  height: 62px;
  line-height: 35px; }

.search-widget--style-2 .form-control {
  border-radius: 0.25rem;
  padding: 30px;
  -webkit-box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.2); }

.search-widget--style-2 button.btn-inner {
  width: 43px;
  height: 62px;
  line-height: 35px; }

.search-widget--style-2 button.btn-inner:hover {
  color: #007aff; }

.search-widget--style-3 .form-control {
  height: 54px;
  border: 1px solid transparent;
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px; }

.search-widget--style-3 button.btn-inner {
  width: 43px;
  height: 54px;
  line-height: 35px; }

.search-widget--style-4 {
  margin: 0;
  border-bottom: 1px solid #f1f1f1; }

.search-widget--style-4 form {
  position: relative; }

.search-widget--style-4 .form-control {
  height: 94px;
  border: 0;
  border-radius: 0;
  padding: 0 90px 0 0;
  font-size: 1.5rem; }

.search-widget--style-4 button.btn-inner {
  right: 0;
  height: 94px;
  line-height: 94px;
  color: #818a91; }

.search-widget--style-4 .bar:after,
.search-widget--style-4 .bar:before {
  height: 1px; }

.search-widget--style-4 .form-control::-webkit-input-placeholder {
  color: #818a91; }

.search-widget--style-4 .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #818a91; }

.search-widget--style-4 .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #818a91; }

.form-default .form-control:-ms-input-placeholder {
  color: #818a91; }

@media (max-width: 991px) {
  .search-page-wrapper {
    width: 100%;
    padding: 0 20px; } }
.listing-wrapper--style-1 {
  padding: 2rem 0;
  border-top: 1px solid #f1f1f1; }

@media (max-width: 1200px) {
  .mobile-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
    z-index: 500; } }
.sidebar--style-1 {
  background: #eceeef;
  border-radius: 0.25rem;
  padding: 2rem; }

.sidebar--style-2 {
  background: #eceeef;
  border-radius: 0.25rem;
  padding: 2rem; }

.sidebar-object {
  margin-bottom: 3rem; }

.sidebar--style-1 .sidebar-object {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #d0d5d8; }

.sidebar--style-1 .sidebar-object:last-child {
  border: 0;
  padding: 0;
  margin: 0; }

.sidebar-object .section-title {
  margin-bottom: 1rem; }

.sidebar-object-title {
  font-size: 0.8rem;
  font-weight: 600;
  color: #2b2b2c;
  margin-bottom: 1rem;
  margin-top: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif; }

.sidebar .sidebar-search-widget {
  position: relative; }

.sidebar .sidebar-search-widget .form-control {
  padding-right: 46px; }

.sidebar .sidebar-search-widget button {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: medium none;
  width: 46px;
  height: 100%;
  line-height: 35px;
  text-align: center; }

.sidebar .sidebar-search-widget button:hover {
  color: #007aff; }

.tagcloud a {
  float: left;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  padding: 4px 8px;
  margin: 0 4px 4px 0;
  background: #007aff;
  color: #FFF;
  border-radius: 0.2rem;
  -webkit-transition: background 0.2s linear;
  -moz-transition: background 0.2s linear;
  -ms-transition: background 0.2s linear;
  transition: background 0.2s linear; }

.tagcloud a:hover {
  background: #3395ff; }

.tagcloud--style-1,
.tagcloud--style-2 {
  background: transparent; }

.tagcloud--style-1 a {
  background: #eceeef;
  color: #818a91;
  padding: 8px 10px; }

.tagcloud--style-1 a:hover {
  background: #007aff;
  color: #FFF; }

.tagcloud--style-2 a {
  float: none;
  display: inline-block;
  margin: 0 20px 20px 0;
  background: transparent;
  border: 1px solid #2b2b2c;
  color: #2b2b2c;
  padding: 8px 12px; }

.tagcloud--style-2 a:hover {
  background: #2b2b2c;
  color: #eceeef; }

.tagcloud--style-3 a {
  float: none;
  display: inline-block;
  margin: 0 20px 20px 0;
  background: transparent;
  border: 1px solid #eceeef;
  color: #eceeef;
  padding: 8px 12px; }

.tagcloud--style-3 a:hover {
  background: #eceeef;
  color: #2b2b2c; }

.photostream:after,
.photostream:before {
  content: "";
  display: table; }

.photostream:after {
  clear: both; }

.photostream .photo-wrapper > a {
  display: block;
  width: calc(100% * 0.25 - 3px + 1px);
  float: left;
  margin-right: 3px;
  margin-bottom: 3px; }

.photostream .photo-wrapper:nth-child(4n) > a {
  width: calc(100% * 0.25 - 3px);
  margin-right: 0; }

.photostream .photo-wrapper > a > img {
  width: 100%; }

.photostream .photo-wrapper img:hover {
  box-shadow: 0 0 0 3px #007aff; }

.contacts:not(.c-profile) {
  padding: 0 8px; }

.contacts > [class*="col-"] {
  padding: 0 10px; }

.contacts .contact-entry {
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin-bottom: 24px;
  background: #fff; }

.contacts .contact-entry .contact-entry-image {
  display: block; }

.contacts .contact-entry .contact-entry-image img {
  width: 100%;
  border-radius: 2px 2px 0 0; }

.contacts .contact-entry-image {
  margin: -1px -1px 0; }

.contacts .contact-info {
  text-align: center;
  margin-top: 15px;
  padding: 0 5px; }

.contacts .contact-info strong {
  color: #2b2b2c;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: 500; }

.contacts .contact-info small {
  color: #818a91;
  font-size: 0.75rem;
  margin-top: 3px; }

.contacts .contact-info small,
.contacts .contact-info strong {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; }

.contacts .contact-footer {
  border-top: 1px solid #f1f1f1;
  margin-top: 18px; }

.contacts .contact-footer > button {
  padding: 4px 10px 3px;
  display: block;
  width: 100%;
  text-align: center;
  color: #818a91;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  outline: 0; }

.contacts .contact-footer > button:hover {
  background: #007aff;
  color: #FFF; }

.contacts .contact-footer > button:active {
  background: #006ee6;
  color: #FFF; }

.contacts .contact-footer > button > i {
  font-size: 0.75rem;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 3px; }

.listview {
  position: relative; }

.listview:not(.listview-lg):not(.listview-message) .listview-item {
  padding: 1rem 0; }

.listview.listview-lg .listview-item:hover {
  background-color: #FFFFDB; }

.listview .listview-item {
  position: relative;
  display: block;
  -webkit-transition: background-color;
  -o-transition: background-color;
  transition: background-color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms; }

.listview .listview-item .listview-small {
  font-size: 12px;
  color: #A9A9A9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%; }

.listview .listview-item .checkbox,
.listview .listview-item.media {
  margin: 0; }

.listview .listview-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block; }

.listview a.listview-item:hover {
  background: #ECF9FF; }

.listview [class*="listview-img"] {
  border-radius: 50%; }

.listview .listview-img {
  width: 48px;
  height: 48px; }

.listview .listview-img-sm {
  width: 35px;
  height: 35px; }

.listview.listview-bordered .listview-item:not(:last-child) {
  border-bottom: 1px solid #f0f0f0; }

.listview .listview-attrs {
  list-style: none;
  padding: 0;
  margin: 5px 0 0; }

.listview .listview-attrs > li {
  display: inline-block;
  padding: 2px 10px 3px;
  font-size: 12px;
  margin-top: 5px;
  margin-right: 2px; }

.listview .listview-attrs > li:not(.info):not(.primary):not(.warning):not(.danger) {
  border: 1px solid #f1f1f1;
  background: transparent;
  color: #818a91; }

.listview .listview-attrs > li > a {
  display: block; }

.listview:not(.listview-message) .listview-title {
  color: #2b2b2c; }

.feature-badge > .feature-badge-value {
  display: block;
  font-size: 3rem;
  font-weight: 600;
  color: #2b2b2c; }

.feature-badge > .feature-badge-value > .feature-badge-subtext {
  font-size: 1.5rem;
  font-weight: 400;
  color: #2b2b2c; }

.feature-badge > .feature-badge-text {
  display: block;
  font-size: 0.875rem; }

.map-canvas {
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 0.25rem; }

.map-canvas.style-1 {
  border-radius: 0.25rem; }

.map-canvas .info-window-content {
  min-width: 250px; }

.map-canvas .info-window-content h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px; }

.map-canvas .info-window-content h3 {
  font-size: 14px;
  font-weight: 500; }

.map-canvas .info-window-content p {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  color: #999;
  text-shadow: none; }

.map-canvas-square {
  height: 200px; }

@media only screen and (min-width: 768px) {
  .map-canvas {
    height: 300px; } }
@media only screen and (min-width: 1170px) {
  .map-canvas {
    height: 400px; } }
.map-container {
  position: relative; }

.map-container address {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 20px;
  background-color: fade(#007aff, 80%);
  color: #FFF; }

@media only screen and (min-width: 768px) {
  .map-container address {
    text-align: center; } }
#map-zoom-in,
#map-zoom-out {
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-left: 10px;
  background-color: rgba(0, 122, 255, 0.5);
  background-repeat: no-repeat;
  background-size: 32px 64px;
  background-image: url("../images/icons/google-maps/icon-controller.svg");
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }

#map-zoom-in:hover,
#map-zoom-out:hover {
  background-color: #007aff; }

@media only screen and (min-width: 768px) {
  #map-zoom-in,
  #map-zoom-out {
    margin-left: 30px; } }
#map-zoom-in {
  background-position: 50% 0;
  margin-top: 10px;
  margin-bottom: 1px; }

@media only screen and (min-width: 768px) {
  #map-zoom-in {
    margin-top: 30px; } }
#map-zoom-out {
  background-position: 50% -32px; }

pre {
  padding: 1rem;
  margin: 0 0 10px;
  font-size: 1rem;
  line-height: 1.42857143;
  color: #979da9;
  background-color: #282c34;
  border: 1px solid #282c34;
  border-radius: 0.25rem; }

.color-code-preview {
  border: 1px solid 0.25rem;
  padding: 6px; }

.color-code-preview .color-preview {
  height: 150px;
  position: relative; }

.color-code-preview .color-code-rgb {
  display: block;
  padding: 0 0 5px;
  text-align: center;
  font-weight: 500;
  font-size: 13px; }

.color-code-preview .color-code-hex {
  display: block;
  padding: 10px 0 5px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase; }

.color-code-preview .color-text {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -10px;
  display: block;
  text-align: center; }

.color-code-preview .color-class {
  position: absolute;
  top: 10px;
  left: 10px; }

/*# sourceMappingURL=global-style.css.map */
