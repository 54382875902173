.selectize-input {
	height: 46px;
	border-radius: 1px;
	font-size: .875rem;
	font-weight: 400;
	color: #555;
	padding: 6px 12px;
	line-height: 30px;
}
.selectize-dropdown {
	font-size: .875rem;
}